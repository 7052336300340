const VIEW_TYPES = {
  PLAN_VIEW: 'PlanView',
  VIEW: 'View',
  FIXED: 'Fixed',
  OTHER: 'other',
  ORTHO: 'Ortho',
  ORTHO_FIXED: 'OrthoFixed'
};

export const ORTHO_CAMERAS = {
  [VIEW_TYPES.ORTHO]: true,
  [VIEW_TYPES.ORTHO_FIXED]: true
};

export default VIEW_TYPES;
