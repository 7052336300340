const YOUTUBE_REGEXP = /(youtu\.be\/|youtube\.com\/(watch\?(.*&)?v=|(embed|v)\/))([^&"'>]+)/;
const VIMEO_REGEXP =
  /(http|https)?:\/\/(www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;

const youTubeFormatter = id =>
  `https://www.youtube.com/embed/${id}?autoplay=1&controls=0&fs=0&modestbranding=1&loop=1&mute=1&vq=hd720&playlist=${id}`;
const vimeoFormatter = id =>
  `https://player.vimeo.com/video/${id}?autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1`;

export const getYouTubeVideoId = url => {
  const result = url.match(YOUTUBE_REGEXP);

  return result ? result[5].trim() : null;
};

export const getVimeoVideoId = url => {
  const result = url.match(VIMEO_REGEXP);

  return result ? result[4].trim() : null;
};

function parser(url) {
  if (!url) {
    return url;
  }

  let id = getYouTubeVideoId(url);

  if (id) {
    return youTubeFormatter(id);
  }

  id = getVimeoVideoId(url);

  if (id) {
    return vimeoFormatter(id);
  }

  return url;
}

export default parser;
