import { createSelector } from 'reselect';
import * as THREE from 'three';
import textureStore, { TEXTURE_TYPES, TEXTURE_TYPE } from '../textures/textureStore';
import SHADERS from '../../utility/shaders';
import { BUMP_MODIFIER_METRIC, BUMP_MODIFIER_IMPERIAL, MODEL_UNITS } from '../../utility/viewerSettings';
import * as settingsSelectors from '../settings/settingsSelectors';
import * as texturesSelectors from '../textures/texturesSelectors';
import * as partsSelectors from '../parts/partsSelectors';
import * as modelSettingsSelectors from '../settings/modelSettingsSelectors';
import { cameraSelectors } from '../camera';
import { LIGHTS, getLightsForSun } from '../../utility/lightDefinitions';
import { getPartNodesToRender, selectMainPartNodesToRender } from './nodesSelectors';

// lights selectors

export const selectLights = createSelector([getPartNodesToRender], parts => {
  const lights = {
    customLights: [],
    skylights: [],
    sunlights: []
  };

  parts.forEach(part => {
    if (part.Lights) {
      const mapPartLightId = light => ({ ...light, partName: part.key });

      // merge lights list
      if (Array.isArray(part.Lights.customLights))
        lights.customLights.push(...part.Lights.customLights.map(mapPartLightId));

      // merge skylights list
      if (Array.isArray(part.Lights.skylights)) lights.skylights.push(...part.Lights.skylights.map(mapPartLightId));

      // merge sunlights list
      if (Array.isArray(part.Lights.sunlights)) lights.sunlights.push(...part.Lights.sunlights.map(mapPartLightId));
    }
  });

  return lights;
});

export const selectCurrentSkylight = createSelector(
  [selectLights, cameraSelectors.selectCameraView],
  ({ skylights }, view = {}) => {
    const { lights } = view;

    if (lights && lights.skylight) {
      return skylights.find(light => light.name === lights.skylight.name);
    }

    return undefined;
  }
);

export const selectCurrentSunlight = createSelector(
  [selectLights, cameraSelectors.selectCameraView],
  ({ sunlights }, view = {}) => {
    const { lights } = view;

    if (lights && lights.sunlight) {
      return sunlights.find(light => light.name === lights.sunlight.name);
    }

    return undefined;
  }
);

export const selectCurrentCustomLights = createSelector(
  [selectLights, cameraSelectors.selectCameraView],
  ({ customLights }, view = {}) => {
    const { lights } = view;

    if (lights && Array.isArray(lights.list)) {
      return customLights.filter(
        ({ disabled, name }) => !disabled && lights.list.find(lightName => lightName === name)
      );
    }

    return [];
  }
);

export const selectSkylightDisabled = createSelector(
  [selectCurrentSkylight, cameraSelectors.selectCameraView],
  (skylight, view = {}) =>
    (skylight && skylight.disabled) || (view.lights && view.lights.skylight && view.lights.skylight.disabled)
);
export const selectSunlightDisabled = createSelector(
  [selectCurrentSunlight, cameraSelectors.selectCameraView],
  (sunlight, view = {}) =>
    (sunlight && sunlight.disabled) || (view.lights && view.lights.sunlight && view.lights.sunlight.disabled)
);

const defaultSunlight = {
  name: '_defaultSunlight',
  altitude: 35,
  azimuth: 150,
  color: 0xffffff,
  intensity: 0.4,
  castShadow: true,
  shadowResolution: 4096
};

const defaultSkylight = {
  name: '_defaultSkylight',
  color: 0xaaaaaa,
  intensity: 1
};

export const selectLightsToRender = createSelector(
  [
    selectCurrentSunlight,
    selectSunlightDisabled,
    selectCurrentSkylight,
    selectSkylightDisabled,
    selectCurrentCustomLights,
    modelSettingsSelectors.selectModelSettings,
    cameraSelectors.selectCameraView
  ],
  (
    sunlight = defaultSunlight,
    sunlightDisabled,
    skylight = defaultSkylight,
    skylightDisabled,
    customLights,
    { modelUnits },
    cameraView
  ) => {
    const lightsToRender = [...customLights];

    if (!sunlightDisabled) lightsToRender.push(...getLightsForSun(sunlight, modelUnits, cameraView));

    if (!skylightDisabled && skylight) lightsToRender.push({ ...skylight, type: LIGHTS.AMBIENT });

    return lightsToRender;
  }
);

/*
  Select all textures from materials.
  List of textures which to include are in TEXTURE_MAP_TYPES_LIST
*/

const selectTexturesFromParts = createSelector([partsSelectors.selectMaterialsMap], (materialsMap = {}) => {
  const materials = Object.values(materialsMap);
  // setup texture map type objects

  // these need to be objects so that duplicates get removed
  const textures = {};

  TEXTURE_TYPES.forEach(type => {
    textures[type] = {};
  });

  materials.forEach(material => {
    const { Name, Textures } = material;

    // add all new texture types
    TEXTURE_TYPES.forEach(textureType => {
      if (Textures && Textures[textureType] && Textures[textureType].Texture) {
        // put to correct object according to material name.
        const { Transform, Texture, DisableFlipY = false } = material.Textures[textureType];

        if (!textures[textureType][Name]) {
          textures[textureType][Name] = {
            materialName: Name,
            textureName: Texture.fileName,
            textureType,
            transform: new THREE.Matrix3(),
            flipY: !DisableFlipY
          };

          // apply matrix if exists
          if (Transform && Transform.Matrix && Array.isArray(Transform.Matrix.Values)) {
            textures[textureType][Name].transform.set(...Transform.Matrix.Values);
          }
        }
      }
    });
  });

  return textures;
});

const SKYBOX_MATERIAL_NAME = '_SKYBOX';

const selectEnvTexture = createSelector([settingsSelectors.selectSkyboxTextures], textureName =>
  textureName ? { materialName: SKYBOX_MATERIAL_NAME, textureName, textureType: TEXTURE_TYPE.ENVIRONMENT } : undefined
);

export const selectEnvMap = createSelector([texturesSelectors.getIsTexturesReceived], areTexturesReceived => {
  /* areTexturesReceived is just for pushing updates */
  return textureStore.getTexture(TEXTURE_TYPE.ENVIRONMENT, SKYBOX_MATERIAL_NAME);
});

export const selectModelTexturesList = createSelector(
  [selectTexturesFromParts, selectEnvTexture],
  (textures, envTexture) =>
    TEXTURE_TYPES.reduce(
      (result, type) => {
        result.push(...Object.values(textures[type]));

        return result;
      },
      envTexture ? [envTexture] : []
    )
);

export const selectPartAnimationDisabled = createSelector(
  [cameraSelectors.selectCameraView, settingsSelectors.selectSettings],
  (view = {}, settings) =>
    Boolean(!(settings.partAnimation && settings.partAnimation.enabled) || view.disablePartAnimation)
);

// Materials

const defaultMaterial = {
  Name: 'default',
  ColorDiffuse: 0xffffff
};

const getBumpModifier = (modelUnits = MODEL_UNITS.METRIC) =>
  modelUnits === MODEL_UNITS.IMPERIAL ? BUMP_MODIFIER_IMPERIAL : BUMP_MODIFIER_METRIC;

const injectBackfaceShader = (material, sectionCapColor) => {
  // replace fragment color with custom one if showing back face

  const color = new THREE.Color(sectionCapColor);

  color.convertSRGBToLinear();

  // define onBeforeCompile function which replaces a small part from Phong shader
  const onBeforeCompile = shader => {
    shader.uniforms.sectionCapColor = { value: color }; // eslint-disable-line no-param-reassign

    // Add uniform definition at the front of fragment shader
    const fragmentShader = `uniform vec3 sectionCapColor;
  ${shader.fragmentShader}`;

    // replace backface render program
    const replacedFragmentShader = fragmentShader.replace(SHADERS.FRAGMENT_CAPS, SHADERS.FRAGMENT_CAPS_REPLACE);

    shader.fragmentShader = replacedFragmentShader; // eslint-disable-line no-param-reassign
  };

  // eslint-disable-next-line no-param-reassign
  material.onBeforeCompile = onBeforeCompile;

  return material;
};

const getMaterialOptions = (
  {
    Name = '',
    ColorDiffuse = 0xffffff,
    ColorEmission = 0x000000,
    BumpScale = 0,
    Gloss = 0,
    Opacity = 1,
    Metalness,
    LightMapIntensity = 1,
    AmbientOcclusionMapIntensity = 1,
    NormalScale = {},
    Reflectivity = 0,
    Clearcoat = 0,
    ClearcoatRoughness = 0,
    EmissiveIntensity = 1,
    DisplacementScale = 1,
    DisplacementBias = 0,
    IndexOfRefraction = 1,
    Wireframe = false,
    Transparent = false
  },
  modelUnits
) => {
  const name = Name;
  const map = textureStore.getTexture(TEXTURE_TYPE.DIFFUSE, Name);
  const bumpMap = textureStore.getTexture(TEXTURE_TYPE.BUMP, Name);
  const alphaMap = textureStore.getTexture(TEXTURE_TYPE.TRANSPARENCY, Name);
  const metalnessMap = textureStore.getTexture(TEXTURE_TYPE.METALNESS, Name);
  const aoMap = textureStore.getTexture(TEXTURE_TYPE.AMBIENT_OCCLUSION, Name);
  const lightMap = textureStore.getTexture(TEXTURE_TYPE.LIGHT, Name);
  const normalMap = textureStore.getTexture(TEXTURE_TYPE.NORMAL, Name);
  const roughnessMap = textureStore.getTexture(TEXTURE_TYPE.ROUGHNESS, Name);
  const emissiveMap = textureStore.getTexture(TEXTURE_TYPE.EMISSIVE, Name);
  const displacementMap = textureStore.getTexture(TEXTURE_TYPE.DISPLACEMENT, Name);

  let metalness;

  if (Metalness !== undefined) {
    metalness = Metalness;
  } else if (metalnessMap) {
    metalness = 1;
  } else if (!metalnessMap) {
    metalness = 0;
  }

  const { x: normalX = 1, y: normalY = 1 } = NormalScale;

  return {
    name,
    /*
      This needs to be changed to straight ColorDiffuse but for legacy reasons we have materials
      where ColorDiffuse is something not white while map is defined. We need to update all materials to
      have white color if map is defined and then we can remove this here.
      In that case color will modulate the diffuse map.
    */
    color: map ? 0xffffff : ColorDiffuse,
    emissiveMap,
    emissive: ColorEmission,
    emissiveIntensity: EmissiveIntensity,
    map,
    bumpMap,
    bumpScale: bumpMap ? BumpScale * getBumpModifier(modelUnits) : 0,
    alphaMap,
    roughness: 1 - Gloss,
    roughnessMap,
    metalness,
    metalnessMap,
    aoMap,
    aoMapIntensity: AmbientOcclusionMapIntensity,
    lightMap,
    lightMapIntensity: LightMapIntensity,
    normalMap,
    normalScale: new THREE.Vector2(normalX, normalY),
    clearcoat: Clearcoat,
    clearcoatRoughness: ClearcoatRoughness,
    reflectivity: Reflectivity,
    displacementMap,
    displacementScale: DisplacementScale,
    displacementBias: DisplacementBias,
    refractionRatio: 1 / IndexOfRefraction,
    wireframe: Wireframe,
    opacity: Opacity,
    transparent: Transparent
  };
};

const getMaterialTransparencyOptions = ({ Transparent, Opacity, EnvironmentMapIntensity = 1, Name, AlphaTest = 0 }) => {
  /* This envMap will overwrite scene's environment if any is set */
  const envMap = textureStore.getTexture(TEXTURE_TYPE.ENVIRONMENT, Name) || null;

  let glassyValues = {};

  if (Transparent) {
    const glassy = Opacity < 1;

    glassyValues = {
      alphaTest: AlphaTest || (glassy ? 0 : 0.6),
      depthWrite: !glassy,
      clipShadows: false
    };
  }

  return {
    ...glassyValues,
    envMap,
    envMapIntensity: EnvironmentMapIntensity
  };
};

const createPhysicalMaterial = (materialDef = {}, sectionCapEnabled, sectionCapColor, modelUnits) => {
  const material = {
    side: THREE.DoubleSide,
    shadowSide: THREE.DoubleSide
  };

  const materialOptions = getMaterialOptions(materialDef, modelUnits);
  const transparencyOptions = getMaterialTransparencyOptions(materialDef);

  const coloredMaterial = { ...material, ...materialOptions, ...transparencyOptions };

  const threeMaterial = new THREE.MeshPhysicalMaterial(coloredMaterial);

  threeMaterial.userData.castShadow = !(
    threeMaterial.opacity < 1 ||
    threeMaterial.alphaMap ||
    threeMaterial.transparent
  );

  threeMaterial.color.convertSRGBToLinear();

  if (sectionCapEnabled && !materialDef.Transparent) {
    injectBackfaceShader(threeMaterial, sectionCapColor);
  }

  return threeMaterial;
};

const createSpriteMaterial = (materialDef = {}) => {
  const { Name, ColorDiffuse = 0xffffff, SizeAttenuation = true, rotation = 0 } = materialDef;
  const map = textureStore.getTexture(TEXTURE_TYPE.DIFFUSE, Name);
  const alphaMap = textureStore.getTexture(TEXTURE_TYPE.TRANSPARENCY, Name);
  const material = {
    name: Name,
    map,
    alphaMap,
    color: ColorDiffuse,
    sizeAttenuation: SizeAttenuation,
    rotation
  };

  const threeMaterial = new THREE.SpriteMaterial(material);

  threeMaterial.color.convertSRGBToLinear();

  return threeMaterial;
};

const selectSpriteMaterialNamesMap = createSelector([partsSelectors.selectSpritesList], sprites => {
  return sprites.reduce((result, sprite) => {
    const { material, interaction } = sprite;

    if (material) {
      result[material] = material; // eslint-disable-line no-param-reassign
    }

    if (interaction && interaction.enabled && interaction.hoverMaterial) {
      result[interaction.hoverMaterial] = interaction.hoverMaterial; // eslint-disable-line no-param-reassign
    }

    return result;
  }, {});
});

export const selectMaterials = createSelector(
  [
    texturesSelectors.getIsTexturesReceived,
    partsSelectors.selectMaterialsMap,
    settingsSelectors.selectSectionCapEnabled,
    settingsSelectors.selectSectionCapColor,
    modelSettingsSelectors.selectModelSettings,
    selectSpriteMaterialNamesMap
  ],
  (texturesReceived, materialsMap, sectionCapEnabled, sectionCapColor, { modelUnits }, spriteMaterialNamesMap) => {
    // create default material:
    const initialMaterials = {
      default: createPhysicalMaterial(defaultMaterial, sectionCapEnabled, sectionCapColor, modelUnits)
    };

    if (texturesReceived) {
      return Object.values(materialsMap).reduce((result, materialDef) => {
        // eslint-disable-next-line no-param-reassign
        result[materialDef.Name] = createPhysicalMaterial(materialDef, sectionCapEnabled, sectionCapColor, modelUnits);

        return result;
      }, initialMaterials);
    }

    return initialMaterials;
  }
);

const initialSpritesMaterials = {
  default: createSpriteMaterial(defaultMaterial)
};

export const selectSpriteMaterials = createSelector(
  [texturesSelectors.getIsTexturesReceived, partsSelectors.selectMaterialsMap, selectSpriteMaterialNamesMap],
  (texturesReceived, materialsMap, spriteMaterialNames) => {
    if (texturesReceived) {
      return Object.values(spriteMaterialNames).reduce(
        (result, materialName) => {
          const materialDef = materialsMap[materialName];

          if (materialDef) {
            const spriteMaterial = createSpriteMaterial(materialDef);

            result[materialName] = spriteMaterial; // eslint-disable-line no-param-reassign
          }

          return result;
        },
        { ...initialSpritesMaterials }
      );
    }

    return initialSpritesMaterials;
  }
);

export const selectIsSceneInteractive = createSelector([selectMainPartNodesToRender], partNodes => {
  return partNodes.some(({ Config = {}, Sprites = {} }) => {
    if (Config.interaction && Config.interaction.enabled) {
      return true;
    }
    const { list = [] } = Sprites;

    return list.some(sprite => sprite.interaction && sprite.interaction.enabled);
  });
});
