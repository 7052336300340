import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TYPE_PART_CONTROL } from '../../../utility/propTypes';
import { authSelectors } from '../../../modules/auth';
import { useScrollIntoView } from '../../../utility/hooks';
import ControlFooter from './ControlFooter';
import { controlStyle } from './style';
import BrokenControl from './BrokenControl';
import ControlHeader from './ControlHeader';
import ControlChildTabButton from './ControlChildTabButton';
import ControlWrapper from './ControlWrapper';
import ControlInstanceLoaderButton from './ControlInstanceLoaderButton';
import ControlOptions from './ControlOptions/ControlOptions';

const useExtraInfoHandler = (
  control,
  currentExtraInfoCollection,
  selectedOption,
  isControlGroupSelected,
  onOpenExtraInfo,
  selectControlGroup
) => {
  const [isControlLocalExtraInfoShowing, setControlLocalExtraInfoShowing] = useState(false);

  useEffect(() => {
    /*
    Update local extra info status for options if external is closed
    Duplicate behavior for both useControl and useControlOption
  */
    if (currentExtraInfoCollection !== selectedOption?.extraInfoCollection) {
      setControlLocalExtraInfoShowing(false);
    }
  }, [currentExtraInfoCollection, selectedOption]);

  const isControlExtraInfoShowing = isControlLocalExtraInfoShowing || (control.bindExtraInfo && isControlGroupSelected);

  return useCallback(
    e => {
      e.stopPropagation();

      setControlLocalExtraInfoShowing(prev => !prev);

      if (isControlExtraInfoShowing) {
        onOpenExtraInfo('');
      } else {
        selectControlGroup(control);
        onOpenExtraInfo(selectedOption.extraInfoCollection, control, selectedOption, true);
      }
    },
    [control, isControlExtraInfoShowing, onOpenExtraInfo, selectControlGroup, selectedOption]
  );
};

const useValidateControl = (control, selectedOption, onOptionSelect) => {
  const [broken, setBroken] = useState(true);
  const [checked, setChecked] = useState(false);

  const availableOptions = control.list.filter(option => !option.locked && !option.disabled);

  const handleSetBroken = useCallback(value => {
    setBroken(value);
    setChecked(true);
  }, []);

  const handleFixControlValue = useCallback(() => {
    const validOption = availableOptions.find(option => option.name === control.default) || availableOptions[0];

    if (validOption) {
      onOptionSelect(control, validOption);
    }
  }, [availableOptions, control, onOptionSelect]);

  useEffect(() => {
    if (selectedOption && (selectedOption.locked || selectedOption.disabled)) {
      handleSetBroken(true);

      return;
    }

    if (!selectedOption && control.list.length) {
      handleSetBroken(true);

      return;
    }

    handleSetBroken(false);
  }, [availableOptions.length, control.list.length, handleSetBroken, selectedOption]);

  return { checked, broken, handleFixControlValue, hasValidOptions: Boolean(availableOptions.length) };
};

const useControlEffects = (
  isOpen,
  setOpen,
  control,
  selectedOption,
  isCollapsable,
  isSelected,
  onShowExtraInfo,
  onCloseExtraInfo,
  isMobileNavigation
) => {
  // effect to close collapsible on unselecting
  useEffect(() => {
    if (isOpen && isCollapsable && !isSelected) {
      setOpen(false);
    }
  }, [isCollapsable, isOpen, isSelected, setOpen]);

  // effect to hide EI on close or unselecting
  useEffect(() => {
    if (control && (!isOpen || !isSelected)) {
      // control is passed so the callback would compare if current extra info was created by this control
      onCloseExtraInfo(control);
    }
  }, [control, isOpen, isSelected, onCloseExtraInfo]);

  // effect show EI on option select & collapsible open
  useEffect(() => {
    if (isSelected && isOpen && control.bindExtraInfo && selectedOption && selectedOption.extraInfoCollection) {
      onShowExtraInfo(selectedOption.extraInfoCollection, control, selectedOption);
    }
  }, [control, isCollapsable, isOpen, isSelected, onShowExtraInfo, selectedOption]);

  useEffect(() => {
    if (isMobileNavigation) {
      setOpen(true);
    }
  }, [isMobileNavigation, setOpen]);
};

const Control = ({
  control,
  onSelect,
  isSelected,
  onOptionSelect,
  onShowExtraInfo,
  onCloseExtraInfo,
  currentExtraInfoCollection,
  isMobileNavigation
}) => {
  const { displayName, description, value, list } = control;

  const isLoggedIn = useSelector(authSelectors.getLoggedIn);

  const isCollapsable = control.enableCollapse && !isMobileNavigation;
  const [isOpen, setOpen] = useState(isCollapsable ? isSelected : true);

  const selectedOption = useMemo(() => list.find(({ name }) => name === value), [list, value]);

  const activeRef = useRef();

  const [scroll] = useScrollIntoView();

  useEffect(() => {
    setTimeout(() => {
      if (isSelected && activeRef.current) {
        scroll(activeRef.current);
      }
    }, 0);
  }, [isSelected, scroll, activeRef]);

  const {
    checked: isChecked,
    broken: isBroken,
    handleFixControlValue,
    hasValidOptions
  } = useValidateControl(control, selectedOption, onOptionSelect);

  useControlEffects(
    isOpen,
    setOpen,
    control,
    selectedOption,
    isCollapsable,
    isSelected,
    onShowExtraInfo,
    onCloseExtraInfo,
    isMobileNavigation
  );

  const extraInfoHandler = useExtraInfoHandler(
    control,
    currentExtraInfoCollection,
    selectedOption,
    isSelected,
    onShowExtraInfo,
    onSelect
  );

  if (!isChecked) {
    return null;
  }

  if (isBroken) {
    return (
      <BrokenControl
        onClick={handleFixControlValue}
        hasValidOptions={hasValidOptions}
        description={description}
        displayName={displayName}
      />
    );
  }

  return (
    <ControlWrapper className={controlStyle({ collapsable: isCollapsable })} controlName={control.name}>
      <div
        ref={activeRef}
        className={controlStyle('body', {
          selected: isSelected,
          collapsable: isCollapsable,
          mobile: isMobileNavigation
        })}
      >
        <ControlHeader
          selectedOption={selectedOption}
          isCollapsable={isCollapsable}
          isOpen={isOpen}
          setOpen={setOpen}
          control={control}
          onOpenExtraInfo={extraInfoHandler}
          currentExtraInfoCollection={currentExtraInfoCollection}
          isSelected={isSelected}
        />
        {isOpen ? (
          <ControlOptions control={control} onOptionSelect={onOptionSelect} onShowExtraInfo={onShowExtraInfo} />
        ) : null}
        <ControlFooter description={control.description} />
        {control.childControls && control.childControls.length !== 0 ? (
          <ControlChildTabButton control={control} />
        ) : null}
        {control.instanceLoader?.enabled && control.instanceLoader.enableNavigation && isLoggedIn ? (
          <ControlInstanceLoaderButton control={control} />
        ) : null}
      </div>
    </ControlWrapper>
  );
};

Control.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  onOptionSelect: PropTypes.func.isRequired,
  onShowExtraInfo: PropTypes.func.isRequired,
  onCloseExtraInfo: PropTypes.func.isRequired,
  currentExtraInfoCollection: PropTypes.string,
  isMobileNavigation: PropTypes.bool
};

Control.defaultProps = {
  isSelected: false,
  currentExtraInfoCollection: '',
  isMobileNavigation: false
};

export default Control;
