import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { MenuSection } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Button } from '../../Atoms';
import useMenuClose from '../../Menu/useMenuClose';
import Progress from '../../Progress';

import './PDFMenu.scss';

const pdfMenuStyle = b.with('pdf-menu');

const PDFMenu = ({ isLoading, objectURL, filename }) => {
  const translate = useTranslate();

  const handleClose = useMenuClose();

  return (
    <MenuContent>
      <MenuSection type="progress" heading={translate('PDF download')}>
        {isLoading ? (
          <Progress />
        ) : (
          <>
            <div className={pdfMenuStyle('content')}>
              <p>{translate('The PDF file should start downloading in a few seconds.')}</p>
              <p>
                {translate(' If it does not happen, ')}
                <a download={filename} href={objectURL} className={pdfMenuStyle('link')}>
                  <strong>{translate('click here')}</strong>
                </a>
                .
              </p>
            </div>
            <Button onClick={handleClose}>{translate('Close')}</Button>
          </>
        )}
      </MenuSection>
    </MenuContent>
  );
};

PDFMenu.defaultProps = {
  isLoading: false,
  objectURL: '',
  filename: ''
};

PDFMenu.propTypes = {
  isLoading: PropTypes.bool,
  objectURL: PropTypes.string,
  filename: PropTypes.string
};

export default PDFMenu;
