import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AppBody from './AppBody';
import { projectSelectors } from './modules/project';
import { inIframe } from './utility/location';
import { IframeDeniedPage } from './components/ServicePage';
import { ADMIN_URL } from './config';
import ErrorBoundary from './ErrorBoundary';

const isProduction = process.env.NODE_ENV === 'production';

// This hook works only for production
const useCheckIframeWhitelist = () => {
  const [checked, setChecked] = useState(!isProduction);
  const [allowed, setAllowed] = useState(!isProduction);
  const allowedDomains = useSelector(projectSelectors.selectAllowedDomains);

  useEffect(() => {
    if (checked) return;

    if (inIframe()) {
      const parent = document.referrer.replace(/\/$/, '');
      const isAdmin = ADMIN_URL.includes(parent);

      setAllowed(isAdmin || allowedDomains.some(domain => new RegExp(`^${domain}`).test(parent)));
    } else {
      setAllowed(true);
    }

    setChecked(true);
  }, [allowedDomains, checked]);

  return { checked, allowed };
};

const App = () => {
  const { checked, allowed } = useCheckIframeWhitelist();

  if (!checked) return null;

  return (
    <div className="app">
      {allowed ? (
        <ErrorBoundary>
          <AppBody />
        </ErrorBoundary>
      ) : (
        <IframeDeniedPage />
      )}
    </div>
  );
};

export default App;
