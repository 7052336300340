import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import useTranslate from '../../../utility/hooks/useTranslate';

import './ContentTitle.scss';

const contentTitleStyle = b.with('content-title');

const ContentTitle = ({ title }) => {
  const translate = useTranslate();

  return <h2 className={contentTitleStyle()}>{translate(title)}</h2>;
};

ContentTitle.propTypes = {
  title: PropTypes.string.isRequired
};
export default ContentTitle;
