import { Vector3 } from 'three';
import { radians } from './radians';

const lookVector = new Vector3();
const targetVector = new Vector3();

/** Returns left, right, top and bottom from camera position, target and fov */
const getFrustum = (position, target, fov, aspect = 1) => {
  lookVector.copy(position);
  targetVector.copy(target).negate();
  lookVector.add(targetVector);

  const height = Math.tan(radians(fov) / 2) * lookVector.length();
  const width = height * aspect;

  return { left: -width, right: width, top: height, bottom: -height };
};

export default getFrustum;
