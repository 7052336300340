import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import b from 'b_';
import Button from '../Button';

import './Notification.scss';

const notificationStyle = b.with('notification');

const Notification = ({
  type,
  header,
  message,
  children,
  mainButtonTitle,
  secondaryButtonTitle,
  onMainButtonClick,
  onSecondaryButtonClick
}) => {
  return ReactDOM.createPortal(
    <div className={notificationStyle()}>
      <div className={notificationStyle('container', { type })}>
        <div className={notificationStyle('body')}>
          <h3 className={notificationStyle('header')}>{header}</h3>
          <div className={notificationStyle('content')}>{message || children}</div>
          <div className={notificationStyle('actions')}>
            {secondaryButtonTitle ? (
              <Button
                onClick={onSecondaryButtonClick}
                label={secondaryButtonTitle}
                mix={notificationStyle('button')}
                rounded
                color="main-outline"
              />
            ) : null}
            {mainButtonTitle ? (
              <Button onClick={onMainButtonClick} label={mainButtonTitle} mix={notificationStyle('button')} rounded />
            ) : null}
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('root')
  );
};

Notification.defaultProps = {
  type: 'success',
  header: '',
  message: '',
  children: null,
  mainButtonTitle: '',
  secondaryButtonTitle: '',
  onMainButtonClick: () => {},
  onSecondaryButtonClick: () => {}
};

Notification.propTypes = {
  type: PropTypes.oneOf(['success', 'warning']),
  header: PropTypes.string,
  message: PropTypes.string,
  children: PropTypes.node,
  mainButtonTitle: PropTypes.string,
  secondaryButtonTitle: PropTypes.string,
  onMainButtonClick: PropTypes.func,
  onSecondaryButtonClick: PropTypes.func
};

export default Notification;
