import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { dialogActions } from '../../modules/dialog';
import { DIALOGS } from '../dialogs';
import detectIE from '../detectIE';

const meta = {
  heading: 'Your Browser is not supported',
  content: [
    'This site does not support older versions of Internet Explorer.',
    'We strongly suggest upgrading your browsing software to fully experience our house configurators.'
  ]
};

const useIEBrowserWarning = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const browserIEStatus = detectIE();

    if (browserIEStatus && browserIEStatus <= 11) {
      dispatch(dialogActions.showDialog(DIALOGS.MESSAGE, meta));
    }
  }, [dispatch]);
};

export default useIEBrowserWarning;
