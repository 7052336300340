import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TYPE_PART_CONTROL } from '../../../utility/propTypes';
import useControl from '../../../utility/hooks/useControl';
import { Dropdown as DropdownAtom } from '../../Atoms';
import ControlOptionCaption from '../ControlOptionCaption';
import { uiSelectors } from '../../../modules/ui';

const compareFunction = (current, item) => item.isSelected;
const disabledItemFunc = item => item?.option?.locked || item?.option?.fixed;
const inactiveItemFunc = item => item?.option?.inactive;

const Dropdown = props => {
  const isMobileNavigation = useSelector(uiSelectors.selectIsMobileNavigation);
  const { onOptionSelect, control } = props;
  const { options, selectedIndex } = useControl(props);
  const handleDropdown = useCallback(
    item => {
      const { option } = item;

      if (!option.locked) {
        onOptionSelect(control, option);
      }
    },
    [control, onOptionSelect]
  );

  const itemRender = useCallback(
    (item, active, selected = false) => {
      const { option } = item;

      if (!option) return null;

      return <ControlOptionCaption type={control.type} option={option} active={active} ignoreImage={selected} />;
    },
    [control.type]
  );

  return (
    <DropdownAtom
      onChange={handleDropdown}
      options={options}
      itemRender={itemRender}
      value={options[selectedIndex]}
      compareFunction={compareFunction}
      disabledItemFunc={disabledItemFunc}
      inactiveItemFunc={inactiveItemFunc}
      useFixedPosition={isMobileNavigation}
      maxVisibleItems={7}
      unmountOnExit={false}
      useBackdrop
    />
  );
};

Dropdown.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  onOptionSelect: PropTypes.func.isRequired
};

export default Dropdown;
