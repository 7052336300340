import { DoubleSide, Matrix4, MeshPhysicalMaterial } from 'three';
import { BufferGeometryUtils } from 'three/examples/jsm/utils/BufferGeometryUtils';
import FlippableInstancedMesh from '../../utility/MirrorableInstancedMesh';

/** Class to take care of mirrored and alt material meshes for parts. */

export const DEFAULT_MATERIAL = '$defaultMaterial';

/** Contains methods to manage Instances for one part
 * Supports mirrored and multiple materials.
 * Stores InstancedMeshes for all meshes of given partId
 */
class MeshContainer {
  static transferMatrix = new Matrix4();

  static identity = new Matrix4();

  static zeroes = new Matrix4().set(0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0);

  static interactionMaterial = new MeshPhysicalMaterial({ side: DoubleSide });

  constructor(partId, meshes, model) {
    this.partId = partId;
    this.materials = {};
    this.hoverMaterials = {}; // by key
    this.model = model;
    // mirrored or not
    this.meshes = {
      default: meshes.map(mesh => ({ [DEFAULT_MATERIAL]: mesh }))
    };

    this.map = {
      // mirrored or not
      default: {},
      mirrored: {}
    };

    // holds references to key {isMirrored, material, index} pairs
    this.dictionary = {};

    this.count = 0;
    this.interactionMesh = null;
    this.hovering = null;
    this.memoryMatrix = new Matrix4();
  }

  resetHovering(key) {
    const { isMirrored, list } = this.dictionary[key];
    const meshes = isMirrored ? this.meshes.mirrored : this.meshes.default;

    if (Array.isArray(meshes)) {
      meshes.forEach((meshObject, meshIndex) => {
        const { materialName, index } = list[meshIndex];
        const mesh = meshObject[materialName];

        mesh.setMatrixAt(index, this.memoryMatrix);
        mesh.instanceMatrix.needsUpdate = true;
      });
    }

    if (this.interactionMesh) {
      this.interactionMesh.visible = false;
    }
  }

  setHovering(key) {
    // set current hover
    const { isMirrored, list } = this.dictionary[key];
    const meshes = isMirrored ? this.meshes.mirrored : this.meshes.default;

    meshes.forEach((meshObject, meshIndex) => {
      const { materialName, index } = list[meshIndex];
      const mesh = meshObject[materialName];

      // save current matrix only once since its the same
      if (meshIndex === 0) {
        mesh.getMatrixAt(index, this.memoryMatrix);
      }

      mesh.setMatrixAt(index, MeshContainer.zeroes);
      mesh.instanceMatrix.needsUpdate = true;
    });

    if (!this.interactionMesh) {
      this.setupInteractionMesh();
    }
    this.interactionMesh.visible = true;
    this.interactionMesh.setMatrixAt(0, this.memoryMatrix);
    this.interactionMesh.instanceMatrix.needsUpdate = true;
    this.interactionMesh.userData.key = key;

    if (this.materials[this.hoverMaterials[key]]) {
      this.interactionMesh.material = this.materials[this.hoverMaterials[key]];
      this.interactionMesh.castShadow = Boolean(this.interactionMesh.material.userData.castShadow);
    }
  }

  setHoverMaterial(key, materialName) {
    this.hoverMaterials[key] = materialName;
  }

  getKey(meshIndex, instanceIndex, materialName, isMirrored) {
    const map = isMirrored ? this.map.mirrored : this.map.default;

    return map?.[meshIndex]?.[materialName]?.[instanceIndex];
  }

  setupInteractionMesh = () => {
    const mergedGeometry = BufferGeometryUtils.mergeBufferGeometries(
      this.meshes.default.map(({ [DEFAULT_MATERIAL]: mesh }) => mesh.geometry)
    );

    this.interactionMesh = new FlippableInstancedMesh(mergedGeometry, MeshContainer.interactionMaterial, 1);
    this.interactionMesh.userData.isInteractionMesh = true;
    this.interactionMesh.visible = false;
    this.interactionMesh.name = this.meshes.default[0][DEFAULT_MATERIAL].name;
    this.interactionMesh.castShadow = false;
    this.model.add(this.interactionMesh);
  };

  updateMaterials(materials) {
    this.materials = materials;
    // for each mirrored/regular materialName in all meshes, update materials by name
    const meshes = [];

    const { default: defaultMeshes = [], mirrored: mirrorMeshes = [] } = this.meshes;
    const mergedMeshes = [...defaultMeshes, ...mirrorMeshes];

    mergedMeshes.forEach(meshObject => {
      meshes.push(...Object.values(meshObject));
    });

    // assign materials by materialName in userData
    meshes.forEach(mesh => {
      const { materialName } = mesh.userData;

      if (materials[materialName]) {
        // eslint-disable-next-line no-param-reassign
        mesh.material = materials[materialName];
        // eslint-disable-next-line no-param-reassign
        mesh.castShadow = Boolean(mesh.material.userData.castShadow);
      }
    });
  }

  /** Clones meshes and flips their indices. */
  setupMirror() {
    this.meshes.mirrored = this.meshes.default.map(({ [DEFAULT_MATERIAL]: mesh }) => {
      const clonedMesh = mesh.clone();

      // reset counter of cloned mesh
      clonedMesh.count = 0;

      // clone geometry
      clonedMesh.geometry = clonedMesh.geometry.clone();

      clonedMesh.flipIndices();
      clonedMesh.userData.isMirrored = true;
      this.model.add(clonedMesh);

      return { [DEFAULT_MATERIAL]: clonedMesh };
    });

    return this.meshes.mirrored;
  }

  /** Mutates meshObject */
  setupAlternateMaterial(materialName, meshObject) {
    if (!meshObject[materialName]) {
      const clonedMesh = meshObject[DEFAULT_MATERIAL].clone();

      clonedMesh.count = 0;
      // eslint-disable-next-line no-param-reassign
      meshObject[materialName] = clonedMesh;

      // set material of this mesh
      if (this.materials[materialName]) {
        clonedMesh.material = this.materials[materialName];
        clonedMesh.castShadow = clonedMesh.material.userData.castShadow;
      }
      clonedMesh.userData.materialName = materialName;
      clonedMesh.userData.isClonedMaterial = true;
      this.model.add(clonedMesh);

      return clonedMesh;
    }

    return meshObject[materialName];
  }

  setMatrix(key, matrix = MeshContainer.identity, materialReferences = {}) {
    // materialReferences is an object {origMatName: replaceMatName}
    const isMirrored = matrix.determinant() < 0;

    // for each mesh we keep track its index and location in mirror-material hierarchy
    // default is this.map.default.$defaultMaterial
    // for mirrored version its this.map.mirrored.$defaultMaterial

    const map = isMirrored ? this.map.mirrored : this.map.default;
    let meshes = isMirrored ? this.meshes.mirrored : this.meshes.default;

    // create mirrored meshes if they don't exist yet
    if (isMirrored && !meshes) {
      meshes = this.setupMirror();
    }

    // loop through meshes
    this.dictionary[key] = { isMirrored, list: {} };
    meshes.forEach((meshObject, meshIndex) => {
      const { [DEFAULT_MATERIAL]: defaultMesh } = meshObject;
      const { materialName: defaultMaterialName } = defaultMesh.userData;
      const alternateMaterial = materialReferences[defaultMaterialName];

      const materialName = alternateMaterial || DEFAULT_MATERIAL;

      const mesh = alternateMaterial ? this.setupAlternateMaterial(alternateMaterial, meshObject) : defaultMesh;

      const index = mesh.count;

      // eslint-disable-next-line no-param-reassign
      mesh.setMatrixAt(index, matrix);
      mesh.count += 1;

      mesh.instanceMatrix.needsUpdate = true;

      // record it so for removing
      this.dictionary[key].list[meshIndex] = { materialName, index };
      map[meshIndex] = map[meshIndex] || {};
      map[meshIndex][materialName] = map[meshIndex][materialName] || {};
      map[meshIndex][materialName][index] = key;
    });
  }

  resetMatrix(key) {
    const { isMirrored, list = [] } = this.dictionary[key];

    const meshes = isMirrored ? this.meshes.mirrored : this.meshes.default;
    const map = isMirrored ? this.map.mirrored : this.map.default;

    meshes.forEach((meshObject, meshIndex) => {
      const { materialName, index } = list[meshIndex];

      const mesh = meshObject[materialName];

      // eslint-disable-next-line no-param-reassign
      mesh.count -= 1;

      // put last element to current position
      mesh.getMatrixAt(mesh.count, MeshContainer.transferMatrix);

      mesh.setMatrixAt(index, MeshContainer.transferMatrix);
      // which key was at that position for this mesh.
      const oldKey = map[meshIndex]?.[materialName]?.[mesh.count];

      if (oldKey !== undefined) {
        this.dictionary[oldKey].list[meshIndex].index = index;
        map[meshIndex][materialName][index] = oldKey;
      }
    });
  }
}

export default MeshContainer;
