import { userInstancesActions } from '../userInstances';
import { initialStateActions } from '../initialState';
import * as uiActions from './uiActions';

const initialState = {
  selectedTabName: '',
  selectedControlGroupName: '',
  selectedParentControls: [],
  previousParentControl: '', // is updated with selectedParentControls on each selectedControlGroup change to keep track if parent controls changed
  isControlsPanelVisible: true,
  viewerInteracted: false,
  language: '',
  isMobileNavigation: false,
  isMobileViewport: false,
  isMenuVisible: false,
  isNotFoundPage: false,
  newInstanceId: '',
  summaryGallery: [],
  renderingSummaryGallery: true,
  utmParameters: {},
  uiMode: '',
  fullscreen: false,
  partInteractionEnabled: true,
  deprecationNotification: false,
  errorPage: {
    displayed: false,
    message: null
  }
};

const uiReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case uiActions.SET_TAB_AND_CONTROL_GROUP: {
      const { tabName, controlGroupName } = payload;
      const result = { ...state };

      if (tabName !== undefined) {
        result.selectedTabName = tabName;
      }

      if (controlGroupName !== undefined) {
        result.selectedControlGroupName = controlGroupName;
        [result.previousParentControl] = state.selectedParentControls.slice(-1);
      }

      return result;
    }
    case uiActions.SHOW_CHILD_CONTROLS: {
      const { parentControlTreeNames = [], replace } = payload;

      return {
        ...state,
        selectedParentControls: replace
          ? parentControlTreeNames
          : [...state.selectedParentControls, ...parentControlTreeNames]
      };
    }
    case uiActions.HIDE_CHILD_CONTROLS: {
      let selectedParentControls = [];
      const { parentControlTreeNames = [] } = payload;

      if (parentControlTreeNames.length !== 0) {
        const set = new Set(parentControlTreeNames);

        selectedParentControls = state.selectedParentControls.filter(controlTreeName => !set.has(controlTreeName));
      }

      return { ...state, selectedParentControls };
    }
    case uiActions.SHOW_CONTROLS_PANEL: {
      return { ...state, isControlsPanelVisible: true };
    }
    case uiActions.HIDE_CONTROLS_PANEL: {
      return { ...state, isControlsPanelVisible: false };
    }
    case uiActions.SHOW_MENU: {
      return { ...state, isMenuVisible: true };
    }
    case uiActions.HIDE_MENU: {
      return { ...state, isMenuVisible: false };
    }
    case uiActions.VIEWER_INTERACTION: {
      return { ...state, viewerInteracted: true };
    }
    case uiActions.SELECT_LANGUAGE: {
      return { ...state, language: payload.language };
    }
    case initialStateActions.FETCH_INITIAL_STATE: {
      return { ...state, isNotFoundPage: false, errorPage: { displayed: false, message: null } };
    }
    case initialStateActions.FETCH_INITIAL_STATE__FAILURE:
    case uiActions.SHOW_NOT_FOUND_PAGE: {
      return { ...state, isNotFoundPage: true };
    }
    case userInstancesActions.CREATE_INSTANCE__SUCCESS: {
      return { ...state, newInstanceId: payload.instance._id };
    }
    case uiActions.RESET_NEW_INSTANCE_ID: {
      return { ...state, newInstanceId: '' };
    }
    case uiActions.SET_MOBILE_NAVIGATION: {
      return { ...state, isMobileNavigation: payload.isMobileNavigation };
    }
    case uiActions.SET_MOBILE_VIEWPORT: {
      return { ...state, isMobileViewport: payload.isMobileViewport };
    }
    case uiActions.SET_SUMMARY_GALLERY: {
      return { ...state, summaryGallery: payload.urls, renderingSummaryGallery: false };
    }
    case uiActions.SET_RENDERING_SUMMARY_GALLERY: {
      return { ...state, renderingSummaryGallery: payload.active };
    }
    case uiActions.SET_UTM_PARAMETERS: {
      return { ...state, utmParameters: payload.utmParameters };
    }
    case uiActions.SET_UI_MODE: {
      return { ...state, uiMode: payload.uiMode };
    }
    case uiActions.SET_FULLSCREEN: {
      return { ...state, fullscreen: payload.fullscreen };
    }
    case uiActions.SET_PART_INTERACTION_ENABLED: {
      return { ...state, partInteractionEnabled: payload.enabled };
    }
    case uiActions.TOGGLE_DEPRECATION_NOTIFICATION: {
      return { ...state, deprecationNotification: payload.visible };
    }
    case uiActions.SHOW_ERROR_PAGE: {
      return { ...state, errorPage: { displayed: true, message: payload.message } };
    }
    default:
      return state;
  }
};

export default uiReducer;
