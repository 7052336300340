export const selectSelectedLanguage = state => state.ui.language;
export const selectControlsPanelVisible = state => state.ui.isControlsPanelVisible;
export const selectSelectedTabName = state => state.ui.selectedTabName;
export const selectSelectedControlGroupName = state => state.ui.selectedControlGroupName;
export const selectViewerInteracted = state => state.ui.viewerInteracted;
export const selectIsMobileNavigation = state => state.ui.isMobileNavigation;
export const selectIsMobileViewport = state => state.ui.isMobileViewport;
export const selectMenuVisible = state => state.ui.isMenuVisible;
export const selectNotFoundPageVisible = state => state.ui.isNotFoundPage;
export const selectNewInstanceId = state => state.ui.newInstanceId;
export const selectLocalSummaryGallery = state => state.ui.summaryGallery;
export const selectRenderingSummaryGallery = state => state.ui.renderingSummaryGallery;
export const selectSelectedParentControls = state => state.ui.selectedParentControls;
export const selectUtmParameters = state => state.ui.utmParameters;
export const selectUiMode = state => state.ui.uiMode;
export const selectIsFullscreen = state => state.ui.fullscreen;
export const selectIsPartInteractionEnabled = state => state.ui.partInteractionEnabled;
export const selectIsDeprecationNotificationVisible = state => state.ui.deprecationNotification;
export const selectPreviousParentControl = state => state.ui.previousParentControl;
export const selectErrorPageState = state => state.ui.errorPage;
