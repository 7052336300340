import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { controlSelectors, priceSelectors } from '../../modules/model';
import { uiActions } from '../../modules/ui';
import { TYPE_PART_CONTROL } from '../../utility/propTypes';
import { selectedOptionsActions } from '../../modules/selectedOptions';
import ExtraInfoDialog from '../ExtraInfoDialog';
import Control from './Control';
import ControlsValidator from './ControlsValidator';

class ControlsPanel extends React.PureComponent {
  state = { currentExtraInfoCollection: '', extraInfoControl: {}, extraInfoOption: {} };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.currentExtraInfoCollection &&
      this.props.selectedControlGroup?.treeName !== this.state.extraInfoControl.treeName
    ) {
      this.onCloseExtraInfo();
    }
  }

  componentWillUnmount() {
    this.props.hideChildControls();
  }

  onShowExtraInfo = (collection, control, option, isManualTrigger) => {
    this.setState({ currentExtraInfoCollection: collection, extraInfoControl: control, extraInfoOption: option });

    if (collection && isManualTrigger && this.props.isMobileViewport) {
      this.props.hideControlsPanel();
    }
  };

  onCloseExtraInfo = origin => {
    const { showControlsPanel, isControlsPanelVisible } = this.props;

    // if origin is defined, compare origin to current extraInfoControl
    // only close if origin is same
    // must be functional setState since it can be triggered multiple times in one phase
    this.setState(currentState => {
      if (origin === undefined || origin === currentState.extraInfoControl) {
        if (!isControlsPanelVisible) {
          showControlsPanel();
        }

        return { currentExtraInfoCollection: '', extraInfoControl: {}, extraInfoOption: {} };
      }

      return currentState;
    });
  };

  optionSelectHandler = (control, option) => {
    this.props.selectOption(control, option);

    if (!control.bindExtraInfo && this.state.extraInfoControl?.name === control.name && option.extraInfoCollection) {
      this.setState({ currentExtraInfoCollection: option.extraInfoCollection });
    }
  };

  selectControlGroup = control => {
    this.props.selectTabAndControlGroup(undefined, control.name);
  };

  selectOptionFromExtraInfoHandler = () => {
    const { selectOption } = this.props;
    const { extraInfoControl, extraInfoOption } = this.state;

    // if extraInfoControl.bindExtraInfo is true then the option will be selected already before opening dialog
    if (extraInfoOption && extraInfoControl && !extraInfoOption.locked && !extraInfoControl.bindExtraInfo) {
      selectOption(extraInfoControl, extraInfoOption);
    }
    this.onCloseExtraInfo();
  };

  renderExtraInfoDialog = () => {
    // extra info dialog renders if it's opened manually or if its bound to control AND that control is open (which means it's also selected)
    const { currentExtraInfoCollection } = this.state;

    return (
      <ExtraInfoDialog
        collectionName={currentExtraInfoCollection}
        onClose={this.onCloseExtraInfo}
        onSubmit={this.selectOptionFromExtraInfoHandler}
        canvasContainerRef={this.props.canvasContainerRef}
      />
    );
  };

  renderControls = () => {
    const { selectedControlGroup = {}, isMobileNavigation, visibleControls, childControls } = this.props;
    const { currentExtraInfoCollection } = this.state;

    let controls = childControls.length > 0 ? childControls : visibleControls;

    if (isMobileNavigation) {
      controls = controls.filter(({ treeName }) => selectedControlGroup.treeName === treeName);
    }

    return controls.map(control => {
      const { treeName } = control;
      const isSelected = selectedControlGroup.treeName === treeName;

      return (
        <Control
          control={control}
          key={treeName}
          isSelected={isSelected}
          onOptionSelect={this.optionSelectHandler}
          onShowExtraInfo={this.onShowExtraInfo}
          onCloseExtraInfo={this.onCloseExtraInfo}
          onSelect={this.selectControlGroup}
          currentExtraInfoCollection={currentExtraInfoCollection}
          isMobileNavigation={isMobileNavigation}
        />
      );
    });
  };

  render() {
    return (
      <>
        <ControlsValidator />
        {this.renderControls()}
        {this.renderExtraInfoDialog()}
      </>
    );
  }
}

ControlsPanel.propTypes = {
  selectTabAndControlGroup: PropTypes.func.isRequired,
  selectOption: PropTypes.func.isRequired,
  selectedControlGroup: TYPE_PART_CONTROL,
  visibleControls: PropTypes.arrayOf(TYPE_PART_CONTROL),
  childControls: PropTypes.arrayOf(TYPE_PART_CONTROL),
  isMobileNavigation: PropTypes.bool,
  isMobileViewport: PropTypes.bool,
  isControlsPanelVisible: PropTypes.bool,
  canvasContainerRef: PropTypes.shape({}).isRequired,
  showControlsPanel: PropTypes.func.isRequired,
  hideControlsPanel: PropTypes.func.isRequired,
  hideChildControls: PropTypes.func.isRequired
};

ControlsPanel.defaultProps = {
  visibleControls: [],
  childControls: [],
  selectedControlGroup: undefined,
  isMobileNavigation: false,
  isMobileViewport: false,
  isControlsPanelVisible: false
};

const mapStateToProps = state => ({
  visibleControls: priceSelectors.selectVisibleControls(state),
  childControls: controlSelectors.selectChildTabControls(state),
  selectedControlGroup: controlSelectors.getSelectedControlGroup(state)
});

const mapDispatchToProps = {
  selectOption: selectedOptionsActions.selectOption,
  selectTabAndControlGroup: uiActions.selectTabAndControlGroup,
  showControlsPanel: uiActions.showControlsPanel,
  hideControlsPanel: uiActions.hideControlsPanel,
  hideChildControls: uiActions.hideChildControls
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlsPanel);
