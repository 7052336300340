import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../Icon';
import { Button } from '../../Atoms';
import { uiSelectors } from '../../../modules/ui';
import { cameraActions, cameraSelectors } from '../../../modules/camera';
import VIEW_TYPES from '../../../utility/viewTypes';
import { useTranslate, useUiModes } from '../../../utility/hooks';

import './ZoomButtons.scss';

const zoomButtonsStyle = b.with('zoom-buttons');

const getStyleMode = (isControlPanelVisible, isReviewTab, isMinimalUi) => {
  if (isReviewTab || isMinimalUi) {
    return 'review';
  }

  return isControlPanelVisible ? 'controls-visible' : 'controls-hidden';
};

const ZoomButtons = ({ onZoomIn, onZoomOut, onFocus }) => {
  const dispatch = useDispatch();
  const translate = useTranslate();
  const { isMinimalUi } = useUiModes();
  const { type } = useSelector(cameraSelectors.selectCameraView);
  const isControlPanelVisible = useSelector(uiSelectors.selectControlsPanelVisible);
  const isReviewTab = useSelector(uiSelectors.getIsReviewTab);
  const isCameraMoved = useSelector(cameraSelectors.selectIsCameraMoved);
  const isViewChangedManually = useSelector(cameraSelectors.selectIsViewChangedManually);
  const isZoomControlsHidden = type === VIEW_TYPES.FIXED || type === VIEW_TYPES.ORTHO_FIXED;
  const handleReset = useCallback(() => {
    if (isViewChangedManually) {
      dispatch(cameraActions.cameraResetView());
    } else {
      onFocus(false, true);
    }
  }, [dispatch, isViewChangedManually, onFocus]);

  if (isZoomControlsHidden) {
    return null;
  }

  return (
    <div className={zoomButtonsStyle({ mode: getStyleMode(isControlPanelVisible, isReviewTab, isMinimalUi) })}>
      <div className={zoomButtonsStyle('focus', { hidden: !isCameraMoved && !isViewChangedManually })}>
        <Button
          onClick={handleReset}
          circular
          color="accent-outline"
          aria-label={translate('Focus')}
          data-balloon-pos="left"
        >
          <Icon type="target" />
        </Button>
      </div>
      <div className={zoomButtonsStyle('zoom', { controls: isControlPanelVisible && !isReviewTab && !isMinimalUi })}>
        <button
          onClick={onZoomIn}
          className={zoomButtonsStyle('button')}
          type="button"
          aria-label={translate('Zoom in')}
          data-balloon-pos="left"
        >
          <Icon type="plus-circle" scale={1.4} />
        </button>
        <button
          onClick={onZoomOut}
          className={zoomButtonsStyle('button')}
          type="button"
          aria-label={translate('Zoom out')}
          data-balloon-pos="left"
        >
          <Icon type="minus-circle" scale={1.4} />
        </button>
      </div>
    </div>
  );
};

ZoomButtons.propTypes = {
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired
};

export default ZoomButtons;
