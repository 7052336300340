export const DIALOGS = {
  AUTH: 'auth',
  SAVE: 'save',
  SHARE: 'share',
  PROFILE: 'profile',
  CONTACT: 'contact',
  MESSAGE: 'message',
  LOADING: 'loading',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  MENU: 'main-menu',
  REGISTER: 'register',
  LOGIN: 'login',
  CONFIRM_LOGOUT: 'confirmLogout',
  CONFIRM_DELETE_INSTANCE: 'confirmDeleteInstance',
  BROKEN_CONTROLS: 'brokenControls',
  SEND_TO_EMAIL: 'sendToEmail',
  PDF: 'pdf',
  OPEN_INSTANCE: 'openInstance',
  CLOSE_CHILD_INSTANCE: 'closeChildInstance',
  EXPORT_MODEL: 'exportModel'
};

export const DIALOG_TYPES = Object.values(DIALOGS);
