import React from 'react';
import PropTypes from 'prop-types';
import { useControl, useTranslate } from '../../../utility/hooks';
import { TYPE_PART_CONTROL } from '../../../utility/propTypes';
import DefaultOption from './DefaultOption';

const DefaultControl = props => {
  const { control, OptionComponent } = props;
  const { options, controlStyle } = useControl(props);
  const translate = useTranslate();

  return (
    <ul className={controlStyle('options', { type: control.type })}>
      {options.length === 0 ? (
        <span className={controlStyle('empty-list-message')}>{translate('Nothing selected')}</span>
      ) : (
        options.map(optionProps => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <OptionComponent {...optionProps} />
        ))
      )}
    </ul>
  );
};

DefaultControl.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  OptionComponent: PropTypes.func
};

DefaultControl.defaultProps = {
  OptionComponent: DefaultOption
};

export default DefaultControl;
