import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../Dropdown';
import { TYPE_SELECT_OPTIONS } from '../../../utility/propTypes';
import inputStyle from './inputStyle';

const Select = ({ onChange, value, name, options, className }) => {
  const changeHandler = useCallback(item => onChange(name, item.value), [name, onChange]);

  const itemRender = useCallback(
    (item, active) => <span className={inputStyle('select-option', { active })}>{item.label}</span>,
    []
  );
  const compareFunction = useCallback((currentValue, item) => {
    return currentValue.value === item.value;
  }, []);

  const listValue = useMemo(() => options.find(option => option.value === value), [options, value]);

  return (
    <Dropdown
      onChange={changeHandler}
      value={listValue}
      options={options}
      itemRender={itemRender}
      compareFunction={compareFunction}
      mix={className}
    />
  );
};

Select.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: TYPE_SELECT_OPTIONS
};

Select.defaultProps = {
  options: [],
  value: ''
};

export default Select;
