import { Vector3 } from 'three';
import { radians } from './radians';

const calculateSunPosition = (azimuth, altitude, distance) => {
  const altitudeRad = radians(altitude);
  const azimuthRad = radians(azimuth);

  const radius = Math.cos(altitudeRad) * distance;
  const x = Math.sin(azimuthRad) * radius;
  const y = Math.cos(azimuthRad) * radius;
  const z = Math.sin(altitudeRad) * distance;

  return new Vector3(x, y, z);
};

export default calculateSunPosition;
