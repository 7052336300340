import { all, call, put, takeLatest, select, take, race, delay } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import api from '../../api';
import { authSelectors, authActions } from '../auth';
import { getSummary } from '../ui/summarySelectors';
import { seedSelectors } from '../seed';
import { selectedOptionsSelectors } from '../selectedOptions';
import { selectModelUnits } from '../settings/modelSettingsSelectors';
import { cookiesActions } from '../cookies';
import cookieNames from '../cookies/cookieNames';
import * as dialogActions from '../dialog/dialogActions';
import { notificationsActions } from '../notifications';
import { DIALOGS } from '../../utility/dialogs';
import { SAVE_DIALOG_TITLE } from '../dialog/constants';
import * as userInstancesActions from '../userInstances/userInstancesActions';
import { projectSelectors } from '../project';
import Heap from '../../utility/heap';
import { cameraSelectors } from '../camera';
import renderSnapshot from '../../components/SceneGenerator/renderSnapshot';
import { commonSelectors } from '../model';
import * as contactFormSelectors from '../settings/contactFormSelectors';
import NOTIFICATION_MESSAGES from '../../utility/notificationMessages';
import { initialStateActions } from '../initialState';
import * as uiSelectors from '../ui/uiSelectors';
import {
  SAVE_SNAPSHOT,
  saveSnapshotSuccess,
  saveSnapshotFailure,
  CREATE_LEAD,
  createLeadSuccess,
  createLeadFailure,
  UPDATE_LEAD,
  CREATE_ORPHAN_LEAD,
  createOrphanLeadSuccess,
  createOrphanLeadFailure,
  SUBMIT_QUESTION,
  createLead,
  updateLead,
  createOrphanLead,
  CREATE_LEAD__SUCCESS,
  CREATE_ORPHAN_LEAD__SUCCESS,
  REQUEST_DOWNLOAD_PDF,
  SEND_INSTANCE_TO_EMAIL,
  sendInstanceToEmailRequest,
  sendInstanceToEmailFailure,
  sendInstanceToEmailSuccess,
  savePdfGallery,
  SAVE_PDF_GALLERY__SUCCESS,
  SAVE_PDF_GALLERY,
  savePdfGallerySuccess,
  savePdfGalleryFailure,
  fetchChildInstancesFailure,
  fetchChildInstancesSuccess,
  FETCH_CHILD_INSTANCES
} from './instanceActions';
import * as instanceSelectors from './instanceSelectors';

function* doSaveSnapshotSaga() {
  const instanceId = yield select(instanceSelectors.selectInstanceId);
  const shareView = yield select(cameraSelectors.getShareView);
  const modelUnits = yield select(selectModelUnits);
  const snapshot = yield renderSnapshot(shareView, modelUnits);

  if (!snapshot.blob) return;

  const { data, error } = yield call(api.uploadSnapshot, instanceId, snapshot.blob);

  if (error) {
    yield put(saveSnapshotFailure(error));
  } else {
    yield put(saveSnapshotSuccess(data));
  }
}

function* watchSaveSnapshotSaga() {
  yield takeLatest(SAVE_SNAPSHOT, doSaveSnapshotSaga);
}

function* doCreateOrUpdateLeadSaga({ type, payload }) {
  const instanceId = yield select(instanceSelectors.selectInstanceId);
  const { lead } = payload;

  lead.consent = yield select(authSelectors.selectLocalConsent);

  const { data, error } = yield call(type === CREATE_LEAD ? api.createLead : api.updateLead, instanceId, lead);

  if (error) {
    yield put(createLeadFailure(error));
  } else {
    yield put(createLeadSuccess(data));

    Heap.track('CREATE_OR_UPDATE_LEAD', { instanceId });
  }
}

function* watchCreateOrUpdateLeadSaga() {
  yield takeLatest([CREATE_LEAD, UPDATE_LEAD], doCreateOrUpdateLeadSaga);
}

function* doCreateOrphanLeadSaga(action) {
  const seedId = yield select(seedSelectors.selectSeedId);
  const pdfGallery = yield select(instanceSelectors.selectInstancePdfGallery);
  const savedState = yield select(selectedOptionsSelectors.selectSelectedOptions);
  const lang = yield select(uiSelectors.selectCurrentLanguage);
  const { lead } = action.payload;

  const summary = yield select(getSummary);

  const { data, error } = yield call(api.createOrphanLead, { lead, seedId, pdfGallery, summary, savedState, lang });

  if (error) {
    yield put(createOrphanLeadFailure(error));
  } else {
    yield put(createOrphanLeadSuccess(data));

    Heap.track('CREATE_ORPHAN_LEAD', { instanceId: data._id });
  }
}

function* watchCreateOrphanLeadSaga() {
  yield takeLatest(CREATE_ORPHAN_LEAD, doCreateOrphanLeadSaga);
}

function* doSubmitQuestionSaga(action) {
  const { formState, consent: formConsent } = action.payload;

  const instance = yield select(instanceSelectors.selectInstance);
  const isLoggedIn = yield select(authSelectors.getLoggedIn);
  const sendPDFToClient = yield select(contactFormSelectors.selectContactFormSendPdf);

  let consent = false;

  if (formConsent.consentGiven) {
    consent = {
      type: 'marketingConsent',
      label: formConsent.label
    };
  }

  const leadData = { ...formState, consent, downloadedPDF: sendPDFToClient };

  if (sendPDFToClient) {
    yield put(savePdfGallery());

    yield take([SAVE_PDF_GALLERY__SUCCESS]);
  }

  if (isLoggedIn) {
    // user logged in, make normal lead

    // update consent

    yield put(authActions.updateConsent(consent));

    if (instance.lead) {
      yield put(updateLead(leadData));
    } else {
      yield put(createLead(leadData));
    }
  } else {
    // user not logged in, create orphan lead
    yield put(createOrphanLead(leadData));
  }

  const hasSentRequestCookie = {
    name: cookieNames.HAS_SENT_REQUEST,
    value: cookieNames.HAS_SENT_REQUEST_VALUE
  };

  yield put(cookiesActions.setCookie(hasSentRequestCookie));

  yield take([CREATE_LEAD__SUCCESS, CREATE_ORPHAN_LEAD__SUCCESS]);
  yield put(dialogActions.hideDialog());
}

function* watchSubmitQuestionSaga() {
  yield takeLatest(SUBMIT_QUESTION, doSubmitQuestionSaga);
}

function* raceRequestDownloadLoginSaveSaga(action) {
  const { requireLogin } = action.payload;
  let isLoggedIn = yield select(authSelectors.getLoggedIn);

  if (!isLoggedIn && requireLogin) {
    yield put(dialogActions.showDialog(DIALOGS.LOGIN));

    yield take([authActions.LOGIN__SUCCESS, authActions.REGISTRATION__SUCCESS]);

    isLoggedIn = yield select(authSelectors.getLoggedIn);
  }

  const isOwner = yield select(instanceSelectors.getIsOwner);

  if (isLoggedIn && !isOwner) {
    yield put(dialogActions.showDialog(DIALOGS.SAVE, { title: SAVE_DIALOG_TITLE }));

    yield take(userInstancesActions.CREATE_INSTANCE__SUCCESS);
  }

  if (!isLoggedIn) {
    const instanceName = yield select(commonSelectors.selectDefaultInstanceName);

    yield put(userInstancesActions.createInstance(instanceName));

    yield take(userInstancesActions.CREATE_INSTANCE__SUCCESS);
  }

  return true;
}

function* doRequestDownloadPdfSaga(action) {
  const raceResult = yield race({
    task: call(raceRequestDownloadLoginSaveSaga, action),
    cancel: take(dialogActions.DIALOG__HIDE)
  });

  if (raceResult.task) {
    const instanceId = yield select(instanceSelectors.selectInstanceId);
    const projectName = yield select(projectSelectors.selectProjectName);

    if (!instanceId) {
      yield put(notificationsActions.showErrorNotification(NOTIFICATION_MESSAGES.PDF_FAILED));

      return;
    }

    yield put(dialogActions.showDialog(DIALOGS.PDF, { isLoading: true }));

    yield put(savePdfGallery());

    yield take([SAVE_PDF_GALLERY__SUCCESS]);

    const result = yield call(api.getPDF, instanceId);

    if (result.error) {
      yield put(notificationsActions.showErrorNotification(result.error.message || NOTIFICATION_MESSAGES.PDF_FAILED));
      yield put(dialogActions.hideDialog());
    } else if (result.data) {
      const filename = `${projectName} - order ${instanceId}.pdf`;
      const objectURL = URL.createObjectURL(result.data);

      yield put(
        dialogActions.showDialog(DIALOGS.PDF, {
          isLoading: false,
          objectURL,
          filename
        })
      );

      setTimeout(() => {
        FileSaver.saveAs(result.data, filename);

        Heap.track('DOWNLOAD_PDF');
      }, 1000);
    }
  }
}

function* watchRequestDownloadPdfSaga() {
  yield takeLatest(REQUEST_DOWNLOAD_PDF, doRequestDownloadPdfSaga);
}

function* doSendInstanceToEmailSaga(action) {
  const { email } = action.payload;
  const instanceId = yield select(instanceSelectors.selectInstanceId);

  yield put(sendInstanceToEmailRequest());

  const { error } = yield call(api.sendOrphanInstanceToEmail, instanceId, email);

  if (error) {
    yield put(sendInstanceToEmailFailure(error));
  } else {
    yield put(sendInstanceToEmailSuccess());
    yield put(dialogActions.hideDialog());
  }
}

function* watchSendInstanceToEmailSaga() {
  yield takeLatest(SEND_INSTANCE_TO_EMAIL, doSendInstanceToEmailSaga);
}

function* doSavePdfGallerySaga() {
  const instanceId = yield select(instanceSelectors.selectInstanceId);
  const views = yield select(cameraSelectors.getPdfGalleryViews);

  if (views.length === 0) {
    yield put(savePdfGallerySuccess());

    return;
  }

  const modelUnits = yield select(selectModelUnits);

  const result = yield all([...views.map(view => call(renderSnapshot, view, modelUnits, true))]);

  const { data, error } = yield call(api.uploadPdfGallery, instanceId, result);

  if (error) {
    yield put(savePdfGalleryFailure(error));
  } else {
    yield put(savePdfGallerySuccess(data));
  }
}

function* watchSavePdfGallerySaga() {
  yield takeLatest(SAVE_PDF_GALLERY, doSavePdfGallerySaga);
}

function* doAutoGeneratePdfGallerySaga() {
  yield delay(5000);

  const isOwner = yield select(instanceSelectors.getIsOwner);

  if (isOwner) {
    yield put(savePdfGallery());
  }
}

function* watchAutoGeneratePdfGallery() {
  yield takeLatest(
    [userInstancesActions.SAVE_INSTANCE__SUCCESS, userInstancesActions.CREATE_INSTANCE__SUCCESS],
    doAutoGeneratePdfGallerySaga
  );
}

function* fetchChildInstancesSaga({ type, payload }) {
  const ids = type === FETCH_CHILD_INSTANCES ? payload.ids : payload.childInstancesIds;

  const { data, error } = yield call(api.fetchChildInstances, ids);

  if (error) {
    yield put(fetchChildInstancesFailure(error));

    return;
  }

  yield put(fetchChildInstancesSuccess(data));
}

function* watchFetchChildInstancesSaga() {
  yield takeLatest([initialStateActions.FETCH_INITIAL_STATE__SUCCESS, FETCH_CHILD_INSTANCES], fetchChildInstancesSaga);
}

export default function* moduleSaga() {
  yield all([
    watchSaveSnapshotSaga(),
    watchCreateOrUpdateLeadSaga(),
    watchCreateOrphanLeadSaga(),
    watchSubmitQuestionSaga(),
    watchRequestDownloadPdfSaga(),
    watchSendInstanceToEmailSaga(),
    watchSavePdfGallerySaga(),
    watchAutoGeneratePdfGallery(),
    watchFetchChildInstancesSaga()
  ]);
}
