import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Atoms';
import Icon from '../Icon';
import { useTranslate } from '../../utility/hooks';
import menuStyle from './style';
import useMenuClose from './useMenuClose';

const MenuHeader = ({ children }) => {
  const onMenuClose = useMenuClose();
  const translate = useTranslate();

  return (
    <div className={menuStyle('header')}>
      <Button
        onClick={onMenuClose}
        mix={menuStyle('close')}
        circular
        color="text"
        size="lg"
        aria-label={translate('Close')}
        data-balloon-pos="right"
      >
        <Icon type="close" scale={1.4} />
      </Button>
      {children}
    </div>
  );
};

MenuHeader.propTypes = {
  children: PropTypes.node
};

MenuHeader.defaultProps = {
  children: null
};

export default MenuHeader;
