import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { useActions, useLinkGenerators, useTranslate } from '../../../utility/hooks';
import { useTabLink } from '../../TabLink';
import { uiSelectors } from '../../../modules/ui';
import { Button } from '../../Atoms';
import Icon from '../../Icon';

import './SummaryPageInfo.scss';

const summaryPageInfoStyle = b.with('summary-page-info');

const SummaryPageInfo = () => {
  const translate = useTranslate();
  const makeTabLink = useTabLink();
  const { title, text } = useSelector(uiSelectors.selectSummaryPageSettings);
  const { actions, handleClickOnAction } = useActions('summaryPanel');
  const { getCustomLink } = useLinkGenerators();

  return (
    <div className={summaryPageInfoStyle()}>
      <h4 className={summaryPageInfoStyle('title')}>{translate(title || 'Design is ready!')}</h4>
      <div className={summaryPageInfoStyle('actions')}>
        <div className={summaryPageInfoStyle('actions-body')}>
          {actions.map(action => (
            <span className={summaryPageInfoStyle('action')} key={action.name}>
              <Button
                type={action.buttonType}
                onClick={handleClickOnAction}
                to={makeTabLink(action.tab)}
                href={getCustomLink(action.link)}
                color={action.color}
                data-action={action.name}
                circular
                size="lg"
              >
                <Icon type={action.icon} scale={1.1} />
              </Button>
              <span className={summaryPageInfoStyle('action-name')}>{translate(action.displayName)}</span>
            </span>
          ))}
        </div>
      </div>
      <p className={summaryPageInfoStyle('description')}>
        {translate(
          text ||
            'Well done, now it’s time to save and share your design. Explore the summary and make any changes if needed.'
        )}
      </p>
    </div>
  );
};

export default SummaryPageInfo;
