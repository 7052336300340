import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { TYPE_METRIC } from '../../../utility/propTypes';
import overviewPanelStyle from '../style';
import { useTranslate } from '../../../utility/hooks';
import Button from '../../Atoms/Button';
import OverviewMetricCustomIcon from './OverviewMetricCustomIcon';
import useOverviewMetric from './useOverviewMetric';

const CUSTOM_ICON = '_custom';

function OverviewMetric({ metric, onToggleMoreInfo, isMoreInfoActive }) {
  const { displayName, icon, image, caveat } = metric;
  const translate = useTranslate();

  const displayValue = useOverviewMetric(metric);

  return (
    <div className={overviewPanelStyle('metric')}>
      <div className={overviewPanelStyle('metric-image', { active: isMoreInfoActive })}>
        {icon !== CUSTOM_ICON ? (
          <Icon type={icon} mix={overviewPanelStyle('metric-icon')} />
        ) : (
          <OverviewMetricCustomIcon image={image} alt={translate(displayName)} />
        )}
      </div>
      <div className={overviewPanelStyle('metric-value')}>{displayValue}</div>
      <div className={overviewPanelStyle('metric-label')}>
        {caveat ? (
          <Button
            size="xs"
            color="link"
            mix={overviewPanelStyle('metric-button')}
            onClick={onToggleMoreInfo}
            data-name={metric.name}
          >
            {translate(displayName)} <Icon type="info-circle" mix={overviewPanelStyle('metric-button-icon')} />
          </Button>
        ) : (
          translate(displayName)
        )}
      </div>
      {isMoreInfoActive ? <Icon type="arrow-drop-down" /> : null}
    </div>
  );
}

OverviewMetric.propTypes = {
  metric: TYPE_METRIC,
  isMoreInfoActive: PropTypes.bool,
  onToggleMoreInfo: PropTypes.func.isRequired
};
OverviewMetric.defaultProps = {
  metric: {},
  isMoreInfoActive: false
};
export default memo(OverviewMetric);
