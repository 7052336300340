import React, { useCallback, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { controlSelectors } from '../../../modules/model';
import { useTabLink } from '../../TabLink';
import { uiSelectors } from '../../../modules/ui';
import Icon from '../../Icon';
import { useOnClickOutside, useSelectTabWithControl, useTranslate } from '../../../utility/hooks';
import { REVIEW_TAB } from '../../../modules/ui/tabNames';

const getControlName = (control, controlIndex, tab, translate) => {
  if (tab.name === REVIEW_TAB) {
    return translate(control.displayName);
  }

  return (control.isEmpty ? '' : `${tab.index}.${controlIndex + 1}. `) + translate(control.displayName);
};

const TabNavigationContent = ({ style, onClose, isOpen }) => {
  const contentRef = useRef();
  const selectTabWithControl = useSelectTabWithControl();
  const controlNavigationTree = useSelector(controlSelectors.getControlNavigationTree);
  const filteredControlNavigationTree = useMemo(
    () => controlNavigationTree.filter(tab => tab.isVisibleInTabNavigationList),
    [controlNavigationTree]
  );
  const isMobileNavigation = useSelector(uiSelectors.selectIsMobileNavigation);
  const makeTabLink = useTabLink();
  const translate = useTranslate();

  const handleSelectControl = useCallback(
    e => {
      const { tab, control } = e.currentTarget.dataset;

      onClose();

      if (!control || !tab) {
        return;
      }

      selectTabWithControl(tab, control);
    },
    [onClose, selectTabWithControl]
  );

  useOnClickOutside(contentRef, onClose, isOpen);

  const renderNavigation = useCallback(() => {
    if (isMobileNavigation) {
      return filteredControlNavigationTree.map(tab => (
        <React.Fragment key={tab.name}>
          <div className={style('group')}>
            {tab.name === REVIEW_TAB ? null : (
              <span className={style('group-name')}>
                {tab.index}. {translate(tab.displayName)}
              </span>
            )}
          </div>
          {tab.controls.map((control, controlIndex) => (
            <button
              key={control.name}
              className={style('item', { active: control.active, empty: control.isEmpty })}
              type="button"
              onClick={handleSelectControl}
              data-tab={tab.name}
              data-control={control.name}
            >
              {control.active ? <Icon type="chevron-right" /> : null}
              {getControlName(control, controlIndex, tab, translate)}
            </button>
          ))}
        </React.Fragment>
      ));
    }

    return filteredControlNavigationTree.map(tab => (
      <Link
        to={makeTabLink(tab.name)}
        key={tab.name}
        className={style('item', { active: tab.active })}
        onClick={handleSelectControl}
      >
        {tab.active ? <Icon type="chevron-right" /> : null}
        {tab.isControlTab ? `${tab.index}.` : ''} {translate(tab.displayName)}
      </Link>
    ));
  }, [filteredControlNavigationTree, handleSelectControl, isMobileNavigation, makeTabLink, style, translate]);

  return ReactDOM.createPortal(
    <>
      <div className={style('backdrop', { active: isOpen })} />
      <CSSTransition in={isOpen} timeout={200} classNames={style('content')} unmountOnExit>
        <div className={style('content', { mobile: isMobileNavigation })} ref={contentRef}>
          <span className={style('header')}>{translate('Select category')}</span>
          <div className={style('list')}>{renderNavigation()}</div>
        </div>
      </CSSTransition>
    </>,
    document.getElementById('root')
  );
};

TabNavigationContent.propTypes = {
  style: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};
export default TabNavigationContent;
