import { createSelector } from 'reselect';
import * as seedSelectors from '../seed/seedSelectors';

export const selectPartsMap = state => state.parts;
export const selectParts = createSelector([selectPartsMap], partsMap => Object.values(partsMap));

export const selectRootPart = createSelector(
  [selectParts, seedSelectors.selectRootPartId],
  (parts = [], rootPartId) => parts.find(({ _id }) => _id === rootPartId) || {}
);

export const selectPartIdsForGeometry = createSelector([selectParts], parts =>
  parts.reduce((result, part) => {
    if (part?.populated?.geometryCount !== 0 && !part.geometryDownloaded) {
      result.push({ id: part._id, updatedAt: part.updatedAt });
    }

    return result;
  }, [])
);

export const selectPartsConfigs = createSelector([selectParts], parts => {
  return parts.reduce((result, part) => {
    // eslint-disable-next-line no-param-reassign
    result[part._id] = part?.Config;

    return result;
  }, {});
});

/*
  Function to map parts to object for fast finding by ReferenceName and Option.
  This is a shape of parts but not part nodes, which have a slightly different structure
*/
export const selectPartsReferenceMap = createSelector([selectParts], partsList => {
  const partsMap = {};

  partsList.forEach(part => {
    const { ReferenceName, Option = 'Default' } = part;

    partsMap[ReferenceName] = partsMap[ReferenceName] || { list: {}, default: part, count: 0 };

    partsMap[ReferenceName].list[Option] = part;
    partsMap[ReferenceName].count += 1;
  });

  return partsMap;
});

export const selectMaterialsMap = createSelector(
  [selectRootPart, selectParts, seedSelectors.selectSeedsMaterials],
  (rootPart, parts, seedMaterials) => {
    // start with  (root) and then the rest - don't overwrite any material.
    const materialsMap = {};
    // force root part to be the first
    const list = [{ Materials: seedMaterials }, rootPart, ...parts.filter(part => part._id !== rootPart._id)];

    list.forEach(part => {
      if (part.Materials) {
        part.Materials.forEach(material => {
          if (!materialsMap[material.Name]) {
            const newMaterial = { Textures: {}, ...material };

            // move old DiffuseTexture to new place
            if (newMaterial.DiffuseTexture && !newMaterial.Textures.Diffuse) {
              newMaterial.Textures.Diffuse = {
                Texture: { fileName: newMaterial.DiffuseTexture },
                legacy: true
              };
              delete newMaterial.DiffuseTexture; // and remove old location
            }

            const transparencyTexture = Boolean(newMaterial.Textures.Transparency);

            newMaterial.Transparent = Boolean(
              newMaterial.Opacity < 1 || newMaterial.Transparent || transparencyTexture
            );

            materialsMap[material.Name] = newMaterial;
          }
        });
      }
    });

    return materialsMap;
  }
);

export const selectSpritesList = createSelector([selectParts], parts => {
  return parts.reduce((result, part) => {
    const { Sprites = {} } = part;
    const { list = [] } = Sprites;

    result.push(...list);

    return result;
  }, []);
});

export const selectSiblingPartReferences = createSelector([selectParts], parts => {
  const partReferences = {};

  // a part is a sibling if it is a child of a parent with more than one child

  parts.forEach(({ Position: children = [] }) => {
    if (children.length > 1) {
      children.forEach(({ Reference: reference }) => {
        partReferences[reference] = true;
      });
    }
  });

  return partReferences;
});
