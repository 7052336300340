import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { controlStyle } from '../style';
import Icon from '../../../Icon';
import { useSelectTabWithControl, useTranslate } from '../../../../utility/hooks';
import { TYPE_CONTROL_OPTION, TYPE_PART_CONTROL } from '../../../../utility/propTypes';
import MoreInfoButton from '../../MoreInfoButton';
import { Button } from '../../../Atoms';

const ControlHeader = ({
  selectedOption,
  control,
  isCollapsable,
  isOpen,
  setOpen,
  onOpenExtraInfo,
  currentExtraInfoCollection,
  isSelected
}) => {
  const selectTabWithControl = useSelectTabWithControl();
  const translate = useTranslate();
  const isOptionValueShown = selectedOption && control.enableCollapse && !isOpen;

  const selectHeaderHandler = useCallback(
    e => {
      e.stopPropagation();

      const { name: controlName } = e.currentTarget.dataset;

      selectTabWithControl(undefined, controlName);

      setOpen(current => (isCollapsable ? !current : true));
    },
    [isCollapsable, setOpen, selectTabWithControl]
  );

  const isMoreInfoButtonVisible = selectedOption?.extraInfoCollection && !control.bindExtraInfo;
  const isMoreInfoSelected = useMemo(
    () => isSelected && selectedOption?.extraInfoCollection === currentExtraInfoCollection,
    [currentExtraInfoCollection, isSelected, selectedOption]
  );

  return (
    <div className={controlStyle('header-wrapper')}>
      <h4 className={controlStyle('header')}>
        <span className={controlStyle('title', { fixed: control.fixed })}>{translate(control.displayName)}</span>
        {isCollapsable ? (
          <Button
            mix={controlStyle('collapse-icon')}
            onClick={selectHeaderHandler}
            data-tree-name={control.treeName}
            data-name={control.name}
            circular
            color="main-outline"
          >
            <Icon type={`chevron-${isOpen ? 'down-up' : 'up-down'}`} />
          </Button>
        ) : null}
        {isMoreInfoButtonVisible && !isCollapsable ? (
          <MoreInfoButton onClick={onOpenExtraInfo} selected={isMoreInfoSelected} />
        ) : null}
      </h4>
      {isMoreInfoButtonVisible && isCollapsable && isOpen ? (
        <MoreInfoButton onClick={onOpenExtraInfo} selected={isMoreInfoSelected} />
      ) : null}
      {isOptionValueShown ? <strong>{selectedOption.displayName}</strong> : null}
    </div>
  );
};

ControlHeader.defaultProps = {
  selectedOption: null,
  isCollapsable: false,
  currentExtraInfoCollection: '',
  isSelected: false
};

ControlHeader.propTypes = {
  selectedOption: TYPE_CONTROL_OPTION,
  setOpen: PropTypes.func.isRequired,
  onOpenExtraInfo: PropTypes.func.isRequired,
  control: TYPE_PART_CONTROL.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isCollapsable: PropTypes.bool,
  currentExtraInfoCollection: PropTypes.string,
  isSelected: PropTypes.bool
};

export default ControlHeader;
