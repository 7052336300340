import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { dialogActions } from '../../../modules/dialog';
import { instanceSelectors } from '../../../modules/instance';
import { seedActions } from '../../../modules/seed';
import { useTranslate } from '../../../utility/hooks';
import { Button, Field } from '../../Atoms';
import MenuContent from '../../Menu/MenuContent';
import MenuHeading from '../../Menu/MenuHeading';
import MenuSection from '../../Menu/MenuSection';
import { useTabLink } from '../../TabLink';
import { commonSelectors } from '../../../modules/model';

const CloseChildInstanceMenu = () => {
  const [copyMode, setCopyMode] = useState(false);
  const translate = useTranslate();

  const isOwnInstance = useSelector(instanceSelectors.getIsOwner);
  const defaultInstanceName = useSelector(commonSelectors.selectDefaultInstanceName);

  const [instanceName, setInstanceName] = useState(defaultInstanceName);
  const handleNameChange = useCallback(e => {
    setInstanceName(e.currentTarget.value);
  }, []);
  const handleSetCopyMode = useCallback(e => {
    setCopyMode(true);
  }, []);
  const dispatch = useDispatch();

  const makeTabLink = useTabLink();
  const history = useHistory();

  const handleCancel = useCallback(() => {
    dispatch(seedActions.discardAndCloseCurrentSeed(makeTabLink, history.push));
    dispatch(dialogActions.hideDialog());
  }, [dispatch, history.push, makeTabLink]);

  const clone = !isOwnInstance || copyMode;

  const handleSave = useCallback(() => {
    dispatch(seedActions.saveAndCloseCurrentSeed(instanceName, clone, makeTabLink, history.push));
    dispatch(dialogActions.hideDialog());
  }, [dispatch, history.push, instanceName, makeTabLink, clone]);

  return (
    <MenuContent>
      <MenuSection type="child-instance">
        <MenuHeading>{translate(copyMode ? 'Save as a copy' : 'You have unsaved changes')}</MenuHeading>
        {!isOwnInstance || copyMode ? (
          <Field
            required
            onChange={handleNameChange}
            value={instanceName}
            label={translate('Design name')}
            name="name"
          />
        ) : null}
        <Button onClick={handleSave} bold disabled={!instanceName && (copyMode || !isOwnInstance)}>
          {translate('Save')}
        </Button>
        {isOwnInstance && !copyMode ? (
          <Button onClick={handleSetCopyMode} color="main-outline" bold>
            {translate('Save as a copy...')}
          </Button>
        ) : null}
        <Button onClick={handleCancel} color="text-borderless" bold>
          {translate('Discard')}
        </Button>
      </MenuSection>
    </MenuContent>
  );
};

export default CloseChildInstanceMenu;
