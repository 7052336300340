import { createSelector } from 'reselect';
import { authSelectors } from '../auth';
import { instanceSelectors } from '../instance';
import { configuratorTypeSelectors } from '../configuratorType';
import { uiSelectors } from '../ui';
import { getFirstControlTab, getLastControlTab } from '../model/controlSelectors';
import * as settingsSelectors from './settingsSelectors';
import * as interfaceSelectors from './interfaceSelectors';

export const ACTION_TYPES = {
  OPEN_TAB: 'open-tab',
  OPEN_DIALOG: 'open-dialog',
  OPEN_LINK: 'open-link',
  OPEN_FIRST_CONTROL_TAB: 'open-first-control-tab',
  OPEN_LAST_CONTROL_TAB: 'open-last-control-tab'
};

const BUTTON_TYPES = {
  [ACTION_TYPES.OPEN_DIALOG]: 'button',
  [ACTION_TYPES.OPEN_TAB]: 'link',
  [ACTION_TYPES.OPEN_LINK]: 'external-link'
};

const MENU_GROUP = 'menu';
const SINGLE_GROUPS = ['summarySaveButton', 'homeButton', 'finishButton'];

const parseAction = (item, map, firstControlTabName, lastControlTabName) => {
  const action = map[item.name];

  if (!action) return undefined;

  let { type, tab } = action;

  if (type === ACTION_TYPES.OPEN_FIRST_CONTROL_TAB) {
    type = ACTION_TYPES.OPEN_TAB;
    tab = firstControlTabName;
  }

  if (type === ACTION_TYPES.OPEN_LAST_CONTROL_TAB) {
    type = ACTION_TYPES.OPEN_TAB;
    tab = lastControlTabName;
  }

  return { ...item, ...action, type, tab, buttonType: BUTTON_TYPES[type] };
};

const parseActions = (list = [], map, firstControlTabName, lastControlTabName) =>
  list.map(action => parseAction(action, map, firstControlTabName, lastControlTabName)).filter(Boolean);

const filterActions = (visibilityFlags, hiddenTabs, currentTab) => list =>
  list.filter(action => {
    if (visibilityFlags.some(([key, value]) => action[key] === value)) {
      return false;
    }

    return !(action.type === ACTION_TYPES.OPEN_TAB && (action.tab === currentTab || hiddenTabs[action.tab]));
  });

const selectStateActions = createSelector([settingsSelectors.selectSettings], ({ actions = {} }) => actions);

export const selectMapActions = createSelector([selectStateActions], actions => {
  const { list = [] } = actions;

  return list.reduce((result, action) => {
    result[action.name] = action; // eslint-disable-line no-param-reassign

    return result;
  }, {});
});

const selectParsedActions = createSelector(
  [selectStateActions, selectMapActions, getFirstControlTab, getLastControlTab],
  (actions, map, firstControlTabName, lastControlTabName) => {
    const { groups = {} } = actions;

    return Object.keys(groups).reduce((result, group) => {
      /* eslint-disable no-param-reassign */
      if (group === MENU_GROUP) {
        result[group] = groups[group].map(section => ({
          ...section,
          actions: parseActions(section.actions, map, firstControlTabName, lastControlTabName)
        }));

        return result;
      }

      if (SINGLE_GROUPS.includes(group) && groups[group]) {
        result[group] = parseActions([{ name: groups[group] }], map, firstControlTabName, lastControlTabName);

        return result;
      }

      result[group] = parseActions(groups[group], map, firstControlTabName, lastControlTabName);

      return result;

      /* eslint-enable no-param-reassign */
    }, {});
  }
);

const selectActionsVisibilityFlags = createSelector(
  [
    authSelectors.getLoggedIn,
    instanceSelectors.getIsOwnLead,
    instanceSelectors.getIsOwner,
    configuratorTypeSelectors.getIsInstanceConfiguratorType
  ],
  (isLoggedIn, isOwnLead, isOwner, isInstanceMode) =>
    Object.entries({
      hidingByAuth: isLoggedIn ? 'authorized' : 'unauthorized',
      hidingBySaving: isOwner ? 'saved' : 'unsaved',
      hidingByRequest: isOwnLead ? 'withRequest' : 'withoutRequest',
      hidingByConfiguratorMode: isInstanceMode ? 'instance' : 'noInstance'
    })
);

export const getActions = createSelector(
  [
    selectParsedActions,
    selectActionsVisibilityFlags,
    interfaceSelectors.selectHiddenTabs,
    uiSelectors.selectCurrentTabName
  ],
  (actions, visibilityFlags, hiddenTabs, currentTab) => {
    const filter = filterActions(visibilityFlags, hiddenTabs, currentTab);

    return Object.keys(actions).reduce((result, group) => {
      // eslint-disable-next-line no-param-reassign
      result[group] =
        group === MENU_GROUP
          ? actions[group]
              .map(section => ({ ...section, actions: filter(section.actions) }))
              .filter(section => section.actions.length !== 0)
          : filter(actions[group]);

      return result;
    }, {});
  }
);
