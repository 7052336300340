import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Button } from '../../Atoms';
import { useTranslate, useFormState } from '../../../utility/hooks';
import { authActions } from '../../../modules/auth';
import { formStyle } from '../../Menu/style';
import { loaderSelectors } from '../../../modules/loader';

const initialFormState = {
  email: '',
  password: ''
};

const EmailLogin = () => {
  const dispatch = useDispatch();

  const { handleFormFieldChange, formState } = useFormState(initialFormState);
  const isLoggingIn = useSelector(loaderSelectors.selectIsRequestingAuthorization);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault(); // do not reload whole page

      dispatch(authActions.login(formState.email, formState.password));
    },
    [dispatch, formState.email, formState.password]
  );

  const disabled = useMemo(() => {
    return !(formState.email && formState.password) || isLoggingIn;
  }, [formState.email, formState.password, isLoggingIn]);

  const translate = useTranslate();

  return (
    <form className={formStyle({ type: 'login' })} onSubmit={handleSubmit}>
      <Field
        id="login-email"
        type="email"
        value={formState.email}
        name="email"
        label={translate('Your email')}
        onChange={handleFormFieldChange}
        autoComplete="username"
      />
      <Field
        id="login-password"
        type="password"
        name="password"
        value={formState.password}
        label={translate('Password')}
        onChange={handleFormFieldChange}
        autoComplete="current-password"
      />
      <Button block type="submit" disabled={disabled}>
        {isLoggingIn ? 'Logging in...' : translate('Log in')}
      </Button>
    </form>
  );
};

export default EmailLogin;
