import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './modules';
import getInitialState from './modules/state';
import sagas from './modules/sagas';

const sagaMiddleware = createSagaMiddleware();

const middleware = [sagaMiddleware];

export default function configureStore() {
  const store = createStore(
    rootReducer,
    getInitialState(window.__INITIAL_STATE__),
    composeWithDevTools(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(sagas);

  return store;
}
