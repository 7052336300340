import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import b from 'b_';
import ResizeObserver from 'react-resize-observer';
import { cameraSelectors } from '../../../modules/camera';
import useRenderer from './useRenderer';
import useCanvasCallbacks from './useCanvasCallbacks';

const renderTarget = b.with('render-target');

const Renderer = forwardRef((props, canvasContainerRef) => {
  const { embeddedPreview, showExtraInfoCollection } = props;

  const cameraView = useSelector(cameraSelectors.selectCameraView);

  const { canvasMouseMoveHandler, canvasMouseDownHandler, canvasMouseUpHandler, objectHovering, handleResize } =
    useCanvasCallbacks(showExtraInfoCollection);

  useRenderer(canvasContainerRef, canvasMouseDownHandler, canvasMouseUpHandler, canvasMouseMoveHandler, cameraView);

  return (
    <div ref={canvasContainerRef} className={renderTarget({ embedded: embeddedPreview, pointer: objectHovering })}>
      <ResizeObserver onResize={handleResize} />
    </div>
  );
});

Renderer.propTypes = {
  embeddedPreview: PropTypes.bool,
  showExtraInfoCollection: PropTypes.func.isRequired
};

Renderer.defaultProps = {
  embeddedPreview: false
};

export default Renderer;
