import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import GoogleLogin from 'react-google-login';
import b from 'b_';
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from '../../config';
import { authActions } from '../../modules/auth';
import useTranslate from '../../utility/hooks/useTranslate';
import { Button } from '../Atoms';
import './SocialAuth.scss';

const auth = b.with('social-auth');

const SocialAuth = ({ register }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();
  const onFacebookLogin = useCallback(
    response => {
      dispatch(authActions.loginFacebook(response));
    },
    [dispatch]
  );
  const onGoogleLogin = useCallback(
    response => {
      dispatch(authActions.loginGoogle(response));
    },
    [dispatch]
  );
  const onGoogleLoginFailure = useCallback(() => {}, []);

  const fbLabel = register ? 'Sign up with Facebook' : 'Log in with Facebook';
  const googleLabel = register ? 'Sign up with Google' : 'Log in with Google';

  return (
    <>
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        autoLoad={false}
        fields="name,email,picture"
        render={renderProps => (
          <Button onClick={renderProps.onClick} mix={auth({ type: 'facebook' })} block>
            {translate(fbLabel)}
          </Button>
        )}
        callback={onFacebookLogin}
      />
      <GoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        uxMode="popup"
        autoLoad={false}
        render={renderProps => (
          <Button onClick={renderProps.onClick} mix={auth({ type: 'google' })} block>
            {translate(googleLabel)}
          </Button>
        )}
        onSuccess={onGoogleLogin}
        onFailure={onGoogleLoginFailure}
      />
    </>
  );
};

SocialAuth.propTypes = {
  register: PropTypes.bool
};
SocialAuth.defaultProps = {
  register: false
};
export default SocialAuth;
