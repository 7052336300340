import { createSelector } from 'reselect';
import numberformat from 'number-format.js';
import { getPartAreas } from '../geometries/geometryStore';
import { convertArea } from '../../utility';
import * as geometriesSelectors from '../geometries/geometriesSelectors';
import * as settingsSelectors from '../settings/settingsSelectors';
import * as interfaceSelectors from '../settings/interfaceSelectors';
import * as modelSettingsSelectors from '../settings/modelSettingsSelectors';
import { getPartNodesToRender } from '../model/nodesSelectors';
import {
  getSelectedOptionsFull,
  selectPricedControlsKeyMap,
  selectPricedControlsMap,
  selectPricedControlsTreeMap
} from '../model/priceSelectors';
import flattenControlKeys from '../../utility/flattenControlKeys';
import convertToArray from '../../utility/convertToArray';
import * as uiSelectors from './uiSelectors';

export const getSelectedOptionsPrices = createSelector([getSelectedOptionsFull], selectedOptions => {
  if (Array.isArray(selectedOptions)) {
    return selectedOptions.map(option => {
      return {
        option,
        priceTotal: option.totalPrice
      };
    });
  }

  return [];
});

function getTabControls(
  controls = [],
  selectedOptions,
  pricedControlsMap,
  pricedControlsTreeMap,
  key,
  keyMap,
  parents = []
) {
  const choices = [];

  // if any control is defined as local, flatten the control keys only once not per every control
  const keys = controls.some(control => control.local) ? flattenControlKeys(keyMap, key) : {};

  controls.forEach(control => {
    const { name, local } = control;

    let selectedOption;
    let pricedControl;

    if (local) {
      // this is not else-if because if its local it must be found from childControlsTree or not found at all
      // rather than found as a regular control

      if (keys[name]) {
        const treeName = keys[name];

        selectedOption = selectedOptions.find(option => option.control.treeName === treeName);
        pricedControl = pricedControlsTreeMap[treeName];
      }
    } else {
      // not local
      selectedOption = selectedOptions.find(option => option.control.name === name);
      pricedControl = pricedControlsMap[name];
    }

    const isEmptyControl = pricedControl?.type === 'empty';

    if (pricedControl && !pricedControl.disabled) {
      choices.push({
        control: pricedControl,
        option: isEmptyControl ? {} : selectedOption,
        parents,
        empty: isEmptyControl
      });
    }

    if (
      pricedControl &&
      !pricedControl.disabled &&
      pricedControl.childControls &&
      pricedControl.childControls.length !== 0 &&
      parents.length < 6
    ) {
      choices.push(
        ...getTabControls(
          pricedControl.childControls,
          selectedOptions,
          pricedControlsMap,
          pricedControlsTreeMap,
          pricedControl.key, // control specific key not tab key
          keyMap,
          [...parents, pricedControl.treeName]
        )
      );
    }
  });

  return choices;
}

export const getSections = createSelector(
  [
    uiSelectors.selectVisibleTabs,
    selectPricedControlsMap,
    selectPricedControlsTreeMap,
    getSelectedOptionsFull,
    selectPricedControlsKeyMap
  ],
  (visibleTabs, pricedControlsMap, pricedControlsTreeMap, selectedOptions, keyMap) => {
    const sections = [];

    visibleTabs.forEach(tab => {
      const { controls = [], key } = tab;

      const choices = getTabControls(controls, selectedOptions, pricedControlsMap, pricedControlsTreeMap, key, keyMap);

      sections.push({ tab, choices: choices.filter(({ control }) => !control.hideFromPreferences) });
    });

    return sections.filter(({ choices }) => Array.isArray(choices) && choices.length > 0);
  }
);
export const getTotalPrice = createSelector([getSelectedOptionsPrices], (options = []) => {
  return options.reduce((result, option) => {
    if (option && option.priceTotal && typeof option.priceTotal === 'number') {
      return result + option.priceTotal;
    }

    return result;
  }, null);
});

export const getNetArea = createSelector(
  [
    getPartNodesToRender, // to get which parts should be included in area calculation
    modelSettingsSelectors.selectModelSettings,
    geometriesSelectors.selectGeometriesStatus // to update once geometries have loaded
  ],
  (renderedParts, { areaUnit, netAreaLayers, netAreaCaveat, modelUnits }) => {
    const netArea = {
      enabled: false,
      unit: '',
      area: null,
      roundedArea: null,
      caveat: ''
    };

    if (areaUnit && Array.isArray(netAreaLayers)) {
      const area = netAreaLayers.reduce((totalArea, layer) => {
        const partsArea = renderedParts.reduce((partsTotalArea, part) => {
          const areas = getPartAreas(part._id);
          const partArea = areas.byLayers[layer.layer] || null;

          // avoid null + null = 0
          return typeof partArea === 'number' ? partsTotalArea + partArea : partsTotalArea;
        }, null);

        // avoid null + null = 0

        return typeof partsArea === 'number' ? totalArea + partsArea : totalArea;
      }, null);

      if (typeof area === 'number') {
        const convertedArea = convertArea({
          area,
          modelUnits
        });

        netArea.unit = areaUnit;
        netArea.area = convertedArea.area;
        netArea.roundedArea = convertedArea.roundedArea;
        netArea.caveat = netAreaCaveat;
        netArea.enabled = true;
      }
    }

    return netArea;
  }
);

const makeNumberFormat = format => {
  if (!format) {
    return number => `${number}`;
  }

  return number => {
    // zeroes we do not run though numberformat function
    if (!number || number === 0) {
      return '0';
    }

    return numberformat(format, number) || '0';
  };
};

export const selectMetrics = createSelector(
  [
    settingsSelectors.selectMetricsList,
    getNetArea,
    getTotalPrice,
    getPartNodesToRender,
    uiSelectors.selectPriceSettingsByPriceScheme,
    interfaceSelectors.selectInterfaceSettings
  ],
  (
    metricsList,
    netArea,
    totalPrice,
    partNodes,
    { currencyFormat, caveat: priceCaveat, currency, scheme: priceScheme },
    { isSummaryPriceHidden }
  ) => {
    // make metrics map
    const metricsMap = metricsList.reduce((result, metric) => {
      const { format = '#', name, caveat } = metric;

      // eslint-disable-next-line no-param-reassign
      result[name] = {
        ...metric,
        value: 0,
        caveat: convertToArray(caveat),
        formatFunc: makeNumberFormat(format)
      };

      return result;
    }, {});

    // calculate each metric
    partNodes.forEach(partNode => {
      if (partNode?.Info?.metrics) {
        Object.entries(partNode.Info.metrics).forEach(([metric, value]) => {
          if (metricsMap[metric] !== undefined) {
            metricsMap[metric].value += value;
          }
        });
      }
    });

    const metrics = Object.values(metricsMap);

    // hardcoded netArea calculation by geometry
    if (netArea?.enabled)
      metrics.push({
        name: 'netArea',
        displayName: 'Net area',
        unit: netArea.unit,
        value: netArea.roundedArea,
        caveat: convertToArray(netArea.caveat),
        format: '#.#',
        formatFunc: makeNumberFormat('#.#'),
        icon: 'floor-plan',
        caveatTitle: 'Area info'
      });

    // hardcoded price calculation
    if (!isSummaryPriceHidden)
      metrics.push({
        name: 'price',
        value: totalPrice,
        displayName: priceScheme.displayName || 'Total price',
        format: currencyFormat,
        formatFunc: makeNumberFormat(currencyFormat),
        caveat: convertToArray(priceCaveat),
        unit: currency,
        icon: 'cash-multiple',
        unitBeforeValue: true,
        caveatTitle: 'Price info'
      });

    return metrics;
  }
);

export const getFormattedSections = createSelector([getSections], sections => {
  return sections.map(({ tab, choices }) => {
    return {
      phaseName: tab.name,
      heading: tab.displayName,
      choices: choices.map(({ control, option }) => ({
        priceTotal: option.totalPrice,
        option: { ...option, control }
      }))
    };
  });
});

export const getSummary = createSelector(
  [getFormattedSections, getTotalPrice, uiSelectors.selectPriceSettingsByPriceScheme, selectMetrics],
  (sections, total, { currency, priceRounding, schemename: priceScheme }, metrics = []) => {
    return {
      sections,
      currency,
      rounding: priceRounding,
      total,
      priceScheme,
      metrics: metrics.map(({ name, displayName, value, unit }) => ({ name, displayName, value, unit })) // strip of unnecessary data
    };
  }
);

export const selectControlsParentsMap = createSelector(
  [
    uiSelectors.selectVisibleTabs,
    selectPricedControlsMap,
    selectPricedControlsTreeMap,
    getSelectedOptionsFull,
    selectPricedControlsKeyMap
  ],
  (visibleTabs, pricedControlsMap, pricedControlsTreeMap, selectedOptions, keyMap) => {
    const map = {};

    visibleTabs.forEach(tab => {
      const tabControls = getTabControls(
        tab.controls,
        selectedOptions,
        pricedControlsMap,
        pricedControlsTreeMap,
        tab.key,
        keyMap
      );

      tabControls.forEach(({ control, parents }) => {
        map[control.treeName] = { parents, tabName: tab.name, controlName: control.name };
      });
    });

    return map;
  }
);
