import { useState, useCallback, useEffect } from 'react';

const useFormState = (initialState = {}, validationFields) => {
  const [formState, setFormState] = useState(initialState);
  const [formValidation, setFormValidation] = useState({});
  // two callbacks - for checked and for value
  const handleFormFieldChange = useCallback(e => {
    const { name, value } = e.currentTarget;

    setFormState(current => ({ ...current, [name]: value }));
  }, []);

  const handleFormSwitchChange = useCallback((name, value) => {
    setFormState(current => ({ ...current, [name]: value }));
  }, []);

  useEffect(() => {
    if (!Array.isArray(validationFields)) {
      return;
    }

    const newFormValidation = {};

    validationFields.forEach(({ name, validators }) => {
      const result = validators.map(func => func(formState[name])).filter(Boolean);

      if (result.length !== 0) {
        newFormValidation[name] = result[result.length - 1];
      }
    });

    setFormValidation(newFormValidation);
  }, [formState, validationFields]);

  return {
    handleFormFieldChange,
    handleFormSwitchChange,
    handleFormSelectChange: handleFormSwitchChange,
    formState,
    formValidation
  };
};

export default useFormState;
