import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { TYPE_PART_CONTROL } from '../../../utility/propTypes';
import useControl from '../../../utility/hooks/useControl';
import { Switch } from '../../Atoms';
import ControlOptionCaption from '../ControlOptionCaption';

const MINIMUM_OPTION_COUNT = 2;

const TOGGLE = {
  CHECKED: 1,
  UNCHECKED: 0
};

const Toggle = props => {
  const { control, onOptionSelect } = props;
  const { options, selectedOption, optionStyle } = useControl(props);
  const { list = [], name, value } = control;

  const checked = list[TOGGLE.CHECKED] && list[TOGGLE.CHECKED].name === value;

  const toggleHandler = useCallback(
    (controlName, newChecked) => {
      const selectedValue = list[newChecked ? TOGGLE.CHECKED : TOGGLE.UNCHECKED];

      if (!selectedValue.locked) {
        onOptionSelect(control, selectedValue);
      }
    },
    [control, list, onOptionSelect]
  );

  if (list.length < MINIMUM_OPTION_COUNT) return null;

  const { displayName } = selectedOption || list[TOGGLE.UNCHECKED];
  const { option: checkedOption } = options[TOGGLE.CHECKED];

  return (
    <Switch
      onChange={toggleHandler}
      name={name}
      type="toggle"
      checked={checked}
      inactive={checkedOption?.inactive}
      disabled={checkedOption?.locked || checkedOption?.fixed}
      mix={optionStyle({ type: control.type })}
    >
      <ControlOptionCaption type={control.type} option={checkedOption} displayName={displayName} />
    </Switch>
  );
};

Toggle.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  onOptionSelect: PropTypes.func.isRequired
};

export default Toggle;
