import * as uiActions from '../ui/uiActions';
import * as cameraActions from './cameraActions';

const initialState = {
  isMoved: false,
  name: '',
  initialViewName: ''
};

const cameraReducer = (state = initialState, { type, payload = {} }) => {
  switch (type) {
    case cameraActions.CAMERA_SET_VIEW: {
      const { name } = payload;

      return { ...state, name };
    }

    case cameraActions.CAMERA_SET_MOVED: {
      const { isMoved } = payload;

      return { ...state, isMoved };
    }
    case cameraActions.CAMERA_RESET_VIEW: {
      return { ...state, isMoved: false, name: state.initialViewName };
    }
    case uiActions.SET_TAB_AND_CONTROL_GROUP: {
      const { viewName, isNewControl } = payload;

      if (viewName === undefined) {
        return state;
      }

      const result = { ...state, name: viewName, initialViewName: viewName };

      if (isNewControl || viewName !== state.name) {
        result.isMoved = false;
      }

      return result;
    }
    default:
      return state;
  }
};

export default cameraReducer;
