import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import inputStyle from '../Field/inputStyle';
import { useTranslate } from '../../../utility/hooks';

const FieldFeedback = ({ type, message, icon }) => {
  const translate = useTranslate();

  return (
    <p className={inputStyle('feedback', { type })}>
      {icon ? <Icon scale={0.9} type={icon} /> : null} {translate(message) || null}
    </p>
  );
};

FieldFeedback.propTypes = {
  type: PropTypes.oneOf(['warning', 'error', 'info']),
  message: PropTypes.string,
  icon: PropTypes.string
};

FieldFeedback.defaultProps = {
  type: 'warning',
  message: '',
  icon: 'warning'
};

export default FieldFeedback;
