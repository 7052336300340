export const CAMERA_SET_VIEW = 'CAMERA_SET_VIEW';
export const CAMERA_SET_MOVED = 'CAMERA_SET_MOVED';

export const CAMERA_RESET_VIEW = 'CAMERA_RESET_VIEW';

export const setCameraView = (name = '') => ({
  type: CAMERA_SET_VIEW,
  payload: { name }
});

export const setCameraMoved = (isMoved = true) => ({
  type: CAMERA_SET_MOVED,
  payload: { isMoved }
});

export const cameraResetView = () => ({
  type: CAMERA_RESET_VIEW
});
