import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelectTabWithControl } from '../../../../utility/hooks';

const TAB_KEY_CODE = 9;

const ControlWrapper = ({ children, className, controlName }) => {
  const selectTabWithControl = useSelectTabWithControl();

  const handleClick = useCallback(
    e => {
      const { name } = e.currentTarget.dataset;

      selectTabWithControl(undefined, name);
    },
    [selectTabWithControl]
  );

  const onKeyUp = useCallback(
    e => {
      if (e.keyCode !== TAB_KEY_CODE) return;

      const { name } = e.currentTarget.dataset;

      selectTabWithControl(undefined, name);
    },
    [selectTabWithControl]
  );

  return (
    <section
      className={className}
      onClick={handleClick}
      onKeyUp={onKeyUp}
      data-name={controlName}
      tabIndex={0}
      role="button"
    >
      {children}
    </section>
  );
};

ControlWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired
};

export default ControlWrapper;
