import * as authActions from './authActions';

const initialState = {
  user: null,
  emailSent: false,
  token: {
    valid: false,
    checked: false
  },
  passwordChanged: false
};

const authReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case authActions.LOGIN__SUCCESS:
    case authActions.REGISTRATION__SUCCESS:
    case authActions.GET_USER_INFO__SUCCESS:
      return { ...state, user: payload.user };
    case authActions.FORGOT_PASSWORD__SUCCESS:
      return { ...state, emailSent: true };
    case authActions.UPDATE_CONSENT:
      return { ...state, localConsent: action.consent };
    case authActions.VERIFY_TOKEN__SUCCESS:
      return { ...state, token: { valid: true, checked: true } };
    case authActions.VERIFY_TOKEN__FAILURE:
      return { ...state, token: { valid: false, checked: true } };
    case authActions.RESET_PASSWORD__SUCCESS:
      return { ...state, passwordChanged: true };
    case authActions.LOGOUT__SUCCESS:
      return { ...initialState };
    default:
      return state;
  }
};

export default authReducer;
