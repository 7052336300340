import { createSelector } from 'reselect';

export const selectUser = state => state.auth.user;
export const selectLocalConsent = state => state.auth.localConsent;
export const selectEmailSent = state => state.auth.emailSent;
export const selectPasswordChanged = state => state.auth.passwordChanged;
export const selectTokenValid = state => state.auth.token.valid;
export const selectTokenChecked = state => state.auth.token.checked;

export const getLoggedIn = createSelector([selectUser], user => Boolean(user && user.uid));
export const getIsJWTAuth = createSelector([selectUser], user => user && user.jwtAuth);
export const getPartnerUserId = createSelector([selectUser], user => (user && user.jwtAuth ? user.userId : null));

export const getUserUid = createSelector([selectUser], user => (user && user.uid ? user.uid : null));
export const getUserEmail = createSelector([selectUser], user => (user ? user.email : null));
