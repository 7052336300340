const NOTIFICATION_MESSAGES = {
  // success
  LOGIN_SUCCESSFUL: 'Login successful',
  FACEBOOK_LOGIN_SUCCESSFUL: 'Facebook login successful',
  GOOGLE_LOGIN_SUCCESSFUL: 'Google login successful',
  LOGOUT_SUCCESSFUL: 'Logout successful',
  REGISTRATION_SUCCESSFUL: 'Registration successful',

  EMAIL_SENT_SUCCESSFUL: 'Email was sent',

  DESIGN_DELETED_SUCCESSFUL: 'Your saved design has been deleted successfully',

  URL_COPIED_TO_CLIPBOARD: 'URL copied to clipboard',

  // error
  LOGIN_FAILED: 'Login failed. Please check your email and password',
  INVALID_USER_TOKEN: 'Authentication error. Please refresh the page',
  PASSWORD_RECOVERY_EMAIL_FAILED: 'Could not send password recovery email',

  FAILED_GET_MODEL: 'Failed to get the model, please check the address',
  FAILED_GET_CHILD_INSTANCES: 'Failed to get child models, please check the address',

  CONTACT_MESSAGE_SUCCESSFUL: 'Contact message sent successfully',
  CONTACT_MESSAGE_FAILED: 'Contact message sending failed',

  PDF_FAILED: 'PDF download failed',
  EXPORT_MODEL_FAILED: 'Failed to export the model, please try again'
};

export default NOTIFICATION_MESSAGES;
