import React from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import './ButtonGroup.scss';

const buttonGroup = b.with('button-group');

const ButtonGroup = ({ children, mix, direction }) => (
  <div className={`${buttonGroup({ direction })} ${mix}`}>{children}</div>
);

ButtonGroup.propTypes = {
  children: PropTypes.node.isRequired,
  mix: PropTypes.string,
  direction: PropTypes.string
};

ButtonGroup.defaultProps = {
  mix: '',
  direction: 'horizontal'
};
export default ButtonGroup;
