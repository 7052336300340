import React from 'react';
import PropTypes from 'prop-types';
import { useControlOption } from '../../../../utility/hooks';
import { TYPE_PART_CONTROL, TYPE_CONTROL_OPTION } from '../../../../utility/propTypes';
import { Switch } from '../../../Atoms';
import ControlOptionCaption from '../../ControlOptionCaption';
import { CONTROL_TYPES } from '../../../../utility/controlDefinitions';

const DefaultOption = props => {
  const { option, control, isSelected, children, noCaption } = props;
  const { selectHandler, optionStyle } = useControlOption(props);

  return (
    <Switch
      onChange={selectHandler}
      name={control.name}
      type="radio"
      checked={isSelected}
      value={option.name}
      disabled={option.locked || option.fixed}
      inactive={option.inactive}
      divider="^"
      hideIcon={control.type !== CONTROL_TYPES.RADIO}
      mix={optionStyle({ type: control.type, checked: isSelected, inactive: option.inactive })}
    >
      {children}
      {noCaption ? null : <ControlOptionCaption type={control.type} option={option} />}
    </Switch>
  );
};

DefaultOption.defaultProps = {
  isSelected: false,
  children: null,
  noCaption: false
};

DefaultOption.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  option: TYPE_CONTROL_OPTION.isRequired,
  isSelected: PropTypes.bool,
  children: PropTypes.node,
  noCaption: PropTypes.bool
};

export default DefaultOption;
