import { useMemo } from 'react';
import { controlStyle, optionStyle } from '../../components/ControlsPanel/Control/style';
import { CONTROL_TAGS, CONTROL_TYPES } from '../controlDefinitions';

const useControl = ({ control, onOptionSelect, onOpenExtraInfo, activeTags }) => {
  const { list = [], type, value, tags = {} } = control;

  const controlHasTags = (tags.list || []).length !== 0;
  const isAllTagSelected = activeTags[0] === CONTROL_TAGS.ALL;

  /*
  Map all needed props for options
  These are fed to useControlOption hook as well
*/
  const options = useMemo(
    () =>
      list.reduce((result, option) => {
        const { tags: optionTags = [] } = option;
        const isSelected = option.name === value;

        const isOptionFiltered =
          !controlHasTags ||
          isAllTagSelected ||
          activeTags.some(tag => optionTags.includes(tag)) ||
          (isSelected && control.type === CONTROL_TYPES.DROPDOWN);

        if (isOptionFiltered) {
          result.push({
            key: option.name,
            control,
            type,
            option,
            onOptionSelect,
            isSelected,
            onOpenExtraInfo
          });
        }

        return result;
      }, []),
    [control, onOpenExtraInfo, onOptionSelect, activeTags, list, controlHasTags, isAllTagSelected, type, value]
  );

  const selectedIndex = options.findIndex(({ isSelected }) => isSelected);
  const selectedOption = options[selectedIndex] && options[selectedIndex].option;

  return {
    options,
    selectedIndex,
    selectedOption,
    controlStyle,
    optionStyle
  };
};

export default useControl;
