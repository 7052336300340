import React, { useRef, useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import b from 'b_';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { useEscListener, useTranslate } from '../../utility/hooks';
import ExtraInfo from '../ExtraInfo';
import { getExtraInfoCollectionsMap } from '../../modules/model/nodesSelectors';
import { Button } from '../Atoms';
import Icon from '../Icon';

import './ExtraInfoDialog.scss';

const extraInfoDialogStyle = b.with('extra-info-dialog');

const useSameTargetOnClick = clickHandler => {
  const targetRef = useRef();

  const mouseDownHandler = useCallback(e => {
    targetRef.current = e.currentTarget;
  }, []);

  const mouseUpHandler = useCallback(
    e => {
      if (targetRef.current === e.currentTarget) clickHandler(e);
    },
    [clickHandler]
  );

  return { mouseDownHandler, mouseUpHandler };
};

const ExtraInfoDialog = ({ onClose, collectionName, canvasContainerRef }) => {
  const [activeFullscreenImage, setActiveFullscreenImage] = useState(false);
  const contentRef = useRef();
  const translate = useTranslate();
  const collections = useSelector(getExtraInfoCollectionsMap);
  const closeHandler = useCallback(() => onClose(), [onClose]); // Remove arguments from callback
  const handleToggleFullscreenImage = useCallback(value => {
    setActiveFullscreenImage(value);
  }, []);

  const EscHandler = useCallback(() => {
    if (activeFullscreenImage) {
      return;
    }

    closeHandler();
  }, [activeFullscreenImage, closeHandler]);

  const { mouseDownHandler, mouseUpHandler } = useSameTargetOnClick(e => {
    if (!contentRef.current || e.target !== e.currentTarget) {
      return;
    }

    closeHandler();
  });

  useEscListener(EscHandler);

  if (!canvasContainerRef?.current?.parentElement) {
    return null;
  }

  return ReactDOM.createPortal(
    <CSSTransition
      in={Boolean(collections[collectionName])}
      timeout={200}
      classNames={extraInfoDialogStyle()}
      unmountOnExit
      appear
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div className={extraInfoDialogStyle()} onMouseDown={mouseDownHandler} onMouseUp={mouseUpHandler}>
        <div className={extraInfoDialogStyle('content')} ref={contentRef}>
          <Button onClick={closeHandler} circular color="text-borderless" mix={extraInfoDialogStyle('close-icon')}>
            <Icon type="close" scale={1.4} />
          </Button>
          <ExtraInfo
            collectionName={collectionName}
            style={extraInfoDialogStyle}
            onToggleFullscreenImage={handleToggleFullscreenImage}
          >
            <div className={extraInfoDialogStyle('actions')}>
              <Button
                onClick={closeHandler}
                label={translate('Close')}
                mix={extraInfoDialogStyle('close-btn')}
                rounded
                color="main-outline"
              />
            </div>
          </ExtraInfo>
        </div>
      </div>
    </CSSTransition>,
    canvasContainerRef?.current?.parentElement
  );
};

ExtraInfoDialog.defaultProps = {
  collectionName: '',
  onSubmit: () => {}
};

ExtraInfoDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  collectionName: PropTypes.string,
  canvasContainerRef: PropTypes.shape({ current: PropTypes.shape({ parentElement: PropTypes.shape({}) }) }).isRequired
};

export default ExtraInfoDialog;
