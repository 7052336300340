import NOTIFICATION_MESSAGES from '../../utility/notificationMessages';

export const LOGIN = 'LOGIN';
export const LOGIN__REQUEST = 'LOGIN__REQUEST';
export const LOGIN__SUCCESS = 'LOGIN__SUCCESS';
export const LOGIN__FAILURE = 'LOGIN__FAILURE';

export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK';
export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';

export const LOGOUT__CONFIRM = 'LOGOUT__CONFIRM';
export const LOGOUT__SUCCESS = 'LOGOUT__SUCCESS';
export const LOGOUT__FAILURE = 'LOGOUT__FAILURE';

export const REGISTRATION = 'REGISTRATION';
export const REGISTRATION__REQUEST = 'REGISTRATION__REQUEST';
export const REGISTRATION__SUCCESS = 'REGISTRATION__SUCCESS';
export const REGISTRATION__FAILURE = 'REGISTRATION__FAILURE';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD__SUCCESS = 'FORGOT_PASSWORD__SUCCESS';
export const FORGOT_PASSWORD__FAILURE = 'FORGOT_PASSWORD__FAILURE';

export const VERIFY_TOKEN = 'VERIFY_TOKEN';
export const VERIFY_TOKEN__SUCCESS = 'VERIFY_TOKEN__SUCCESS';
export const VERIFY_TOKEN__FAILURE = 'VERIFY_TOKEN__FAILURE';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD__SUCCESS = 'RESET_PASSWORD__SUCCESS';
export const RESET_PASSWORD__FAILURE = 'RESET_PASSWORD__FAILURE';

export const UPDATE_CONSENT = 'UPDATE_CONSENT';

export const GET_USER_INFO = 'GET_USER_INFO';
export const GET_USER_INFO__REQUEST = 'GET_USER_INFO__REQUEST';
export const GET_USER_INFO__SUCCESS = 'GET_USER_INFO__SUCCESS';
export const GET_USER_INFO__FAILURE = 'GET_USER_INFO__FAILURE';

export const login = (username, password) => ({
  type: LOGIN,
  payload: {
    username,
    password
  }
});

export const loginFacebook = info => ({
  type: LOGIN_FACEBOOK,
  payload: {
    info
  }
});

export const loginGoogle = info => ({
  type: LOGIN_GOOGLE,
  payload: {
    info
  }
});

export const loginRequest = () => ({
  type: LOGIN__REQUEST
});

export const loginSuccess = (user, message, provider) => ({
  type: LOGIN__SUCCESS,
  payload: {
    user,
    provider
  },
  meta: {
    notification: true,
    message
  }
});

export const loginFailure = (error, provider) => ({
  type: LOGIN__FAILURE,
  payload: {
    provider
  },
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.LOGIN_FAILED
  },
  error
});

export const logoutConfirm = () => ({ type: LOGOUT__CONFIRM });

export const logoutSuccess = () => ({
  type: LOGOUT__SUCCESS,
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.LOGOUT_SUCCESSFUL
  }
});

export const logoutFailure = error => ({
  type: LOGOUT__FAILURE,
  meta: {
    notification: true
  },
  error
});

export const registration = (username, password) => ({
  type: REGISTRATION,
  payload: {
    username,
    password
  }
});

export const registrationRequest = () => ({
  type: REGISTRATION__REQUEST
});

export const registrationSuccess = user => ({
  type: REGISTRATION__SUCCESS,
  payload: {
    user
  },
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.REGISTRATION_SUCCESSFUL
  }
});

export const registrationFailure = error => ({
  type: REGISTRATION__FAILURE,
  meta: {
    notification: true
  },
  error
});

export const forgotPassword = username => ({
  type: FORGOT_PASSWORD,
  payload: {
    username
  }
});

export const forgotPasswordSuccess = () => ({
  type: FORGOT_PASSWORD__SUCCESS
});

export const forgotPasswordFailure = error => ({
  type: FORGOT_PASSWORD__FAILURE,
  meta: {
    notification: true,
    message: NOTIFICATION_MESSAGES.PASSWORD_RECOVERY_EMAIL_FAILED
  },
  error
});

export const verifyToken = token => ({
  type: VERIFY_TOKEN,
  payload: {
    token
  }
});

export const verifyTokenSuccess = () => ({
  type: VERIFY_TOKEN__SUCCESS
});

export const verifyTokenFailure = error => ({
  type: VERIFY_TOKEN__FAILURE,
  meta: {
    notification: true
  },
  error
});

export const resetPassword = (password, token) => ({
  type: RESET_PASSWORD,
  payload: {
    password,
    token
  }
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD__SUCCESS
});

export const resetPasswordFailure = error => ({
  type: RESET_PASSWORD__FAILURE,
  meta: {
    notification: true
  },
  error
});

export const updateConsent = consent => ({
  type: UPDATE_CONSENT,
  consent
});

export function getUserInfo(userToken) {
  return {
    type: GET_USER_INFO,
    payload: {
      userToken
    }
  };
}

export function getUserInfoRequest() {
  return {
    type: GET_USER_INFO__REQUEST
  };
}

export function getUserInfoSuccess(user) {
  return {
    type: GET_USER_INFO__SUCCESS,
    payload: {
      user
    }
  };
}

export function getUserInfoFailure() {
  return {
    type: GET_USER_INFO__FAILURE
  };
}
