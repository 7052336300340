import { all } from 'redux-saga/effects';
import { authSaga } from './auth';
import { dialogSaga } from './dialog';
import { instanceSaga } from './instance';
import { notificationsSaga } from './notifications';
import { geometriesSaga } from './geometries';
import { partsSaga } from './parts';
import { plotsSaga } from './plots';
import { seedSaga } from './seed';
import { selectedOptionsSaga } from './selectedOptions';
import { texturesSaga } from './textures';
import { uiSaga } from './ui';
import { userInstancesSaga } from './userInstances';
import { statisticsSaga } from './statistics';
import { modelSaga } from './model';
import initialStateSaga from './initialState';

export default function* rootSaga() {
  yield all([
    authSaga(),
    dialogSaga(),
    instanceSaga(),
    notificationsSaga(),
    geometriesSaga(),
    partsSaga(),
    plotsSaga(),
    seedSaga(),
    selectedOptionsSaga(),
    userInstancesSaga(),
    texturesSaga(),
    uiSaga(),
    statisticsSaga(),
    initialStateSaga(),
    modelSaga()
  ]);
}
