import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Slider.scss';

const slider = b.with('slider');

const Slider = ({ name, onChange, min, max, value, step }) => {
  const handleChange = useCallback(
    e => {
      onChange(Number(e.currentTarget.value), e.currentTarget.id);
    },
    [onChange]
  );

  useEffect(() => {
    if (value === undefined) {
      onChange(min, name);
    }
  }, [min, name, onChange, value]);

  useEffect(() => {
    if (value < min) {
      onChange(min, name);
    }

    if (value > max) {
      onChange(max, name);
    }
  }, [max, min, name, onChange, value]);

  return (
    <div className={slider()}>
      <input
        className={slider('range')}
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={handleChange}
        id={name}
      />
    </div>
  );
};

Slider.defaultProps = {
  step: 1,
  min: 1,
  max: 100,
  value: undefined,
  name: ''
};

Slider.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number
};

export default Slider;
