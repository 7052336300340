import { create } from 'ackee-tracker';
import { ACKEE_DOMAIN_ID, ACKEE_SERVER } from '../config';

class AckeeTracker {
  constructor(server, domainId) {
    this.server = server;
    this.domainId = domainId;
    this.detailed = false;

    this.create();
  }

  create() {
    this.instance = create(this.server, {
      detailed: this.detailed
    });
  }

  record() {
    return this.instance.record(this.domainId);
  }

  stopDetailed() {
    if (this.detailed === false) {
      return;
    }

    this.detailed = false;
    this.create();
  }

  startDetailed() {
    if (this.detailed === true) {
      return;
    }

    this.detailed = true;
    this.create();
  }
}

const fake = {
  record: () => ({ stop: () => null }),
  updateRecord: () => ({ stop: () => null }),
  action: () => null,
  updateAction: () => null,
  stopDetailed: () => {},
  startDetailed: () => {}
};

const ackeeTracker = ACKEE_SERVER && ACKEE_DOMAIN_ID ? new AckeeTracker(ACKEE_SERVER, ACKEE_DOMAIN_ID) : fake;

export default ackeeTracker;
