import React from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useLinkGenerators, useTranslate, useUiModes } from '../../utility/hooks';
import { Button } from '../Atoms';
import Icon from '../Icon';

import './MinimalUiButtons.scss';

const minimalUiButtonsStyle = b.with('minimal-ui-buttons');

const MinimalUiButtons = ({ onSetFullscreen }) => {
  const { minimalUiSettings } = useUiModes();
  const translate = useTranslate();
  const { isAdditionalButtonVisible, isMainFullscreen } = minimalUiSettings;
  const { currentLink } = useLinkGenerators();

  return (
    <>
      {isAdditionalButtonVisible ? (
        <Button
          circular
          color="text"
          mix={minimalUiButtonsStyle('icon-button')}
          href={currentLink}
          type={isMainFullscreen ? 'external-link' : 'button'}
          onClick={onSetFullscreen}
          aria-label={translate(isMainFullscreen ? 'Open in a new tab' : 'Open in fullscreen')}
          data-balloon-pos="left"
        >
          <Icon type={isMainFullscreen ? 'open-in-new' : 'fullscreen'} />
        </Button>
      ) : null}
      <Button
        rounded
        bold
        size="lg"
        mix={minimalUiButtonsStyle('main-button')}
        href={currentLink}
        type={isMainFullscreen ? 'button' : 'external-link'}
        onClick={onSetFullscreen}
        aria-label={translate(!isMainFullscreen ? 'Open in a new tab' : 'Open in fullscreen')}
        data-balloon-pos="up"
      >
        <span className={minimalUiButtonsStyle('main-caption')}>{translate('Start')}</span>
        <Icon type={isMainFullscreen ? 'fullscreen' : 'open-in-new'} scale={0.9} />
      </Button>
    </>
  );
};

MinimalUiButtons.propTypes = {
  onSetFullscreen: PropTypes.func.isRequired
};

export default MinimalUiButtons;
