import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { TYPE_PART_CONTROL } from '../../../utility/propTypes';
import useControl from '../../../utility/hooks/useControl';
import { Slider as SliderAtom } from '../../Atoms';
import ControlOptionCaption from '../ControlOptionCaption';

const Slider = props => {
  const { control, onOptionSelect } = props;
  const { options, selectedOption, selectedIndex } = useControl(props);
  const { name } = control;
  const filteredOptions = useMemo(() => options.filter(({ option }) => !option.locked), [options]);
  const onChange = useCallback(
    value => {
      const { option } = filteredOptions[value] || {};

      if (!option || option.locked) {
        return;
      }

      onOptionSelect(control, option);
    },
    [control, filteredOptions, onOptionSelect]
  );

  if (filteredOptions.length <= 1) return null;

  return (
    <>
      <ControlOptionCaption type={control.type} option={selectedOption} />
      <SliderAtom onChange={onChange} value={selectedIndex} max={filteredOptions.length - 1} min={0} name={name} />
    </>
  );
};

Slider.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  onOptionSelect: PropTypes.func.isRequired
};

export default Slider;
