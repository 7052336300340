import React from 'react';
import PropTypes from 'prop-types';
import { useActions, useLinkGenerators, useTranslate } from '../../utility/hooks';
import { Button } from '../Atoms';
import { useTabLink } from '../TabLink';

const Actions = ({ group, mix, size, block, bold, rounded }) => {
  const translate = useTranslate();
  const makeTabLink = useTabLink();
  const { actions, handleClickOnAction } = useActions(group);
  const { getCustomLink } = useLinkGenerators();

  return actions.map(action => (
    <Button
      key={action.name}
      label={translate(action.displayName)}
      type={action.buttonType}
      onClick={handleClickOnAction}
      to={makeTabLink(action.tab)}
      href={getCustomLink(action.link)}
      color={action.color}
      data-action={action.name}
      mix={mix}
      size={size}
      block={block}
      bold={bold}
      rounded={rounded}
    />
  ));
};

Actions.defaultProps = {
  mix: '',
  block: true,
  bold: true,
  rounded: true,
  size: 'lg'
};

Actions.propTypes = {
  group: PropTypes.oneOf(['aboutTab', 'reviewTab', 'summaryTab', 'overviewPanel', 'shareDialog']).isRequired,
  mix: PropTypes.string,
  block: PropTypes.bool,
  bold: PropTypes.bool,
  rounded: PropTypes.bool,
  size: PropTypes.string
};

export default Actions;
