import * as settingsSelectors from './settingsSelectors';
import * as actionsSelectors from './actionsSelectors';
import * as contactFormSelectors from './contactFormSelectors';
import * as footerSelectors from './footerSelectors';
import * as optionStatusSelectors from './optionStatusSelectors';
import * as sharingSelectors from './sharingSelectors';
import * as interfaceSelectors from './interfaceSelectors';
import * as modelSettingsSelectors from './modelSettingsSelectors';
import settingsReducer from './settingsReducer';

export {
  settingsSelectors,
  actionsSelectors,
  contactFormSelectors,
  footerSelectors,
  optionStatusSelectors,
  sharingSelectors,
  interfaceSelectors,
  modelSettingsSelectors
};

export default settingsReducer;
