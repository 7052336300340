import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { MenuSection, formStyle } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { dialogActions } from '../../../modules/dialog';
import { DIALOGS } from '../../../utility/dialogs';
import MenuItemDescription from '../../Menu/MenuItemDescription';
import { Field, Button } from '../../Atoms';
import { authActions } from '../../../modules/auth';
import { validateEmail } from '../../../utility/formValidators';

const ForgotPasswordMenu = () => {
  const translate = useTranslate();

  const [email, setEmail] = useState('');
  const handleEmailUpdate = useCallback(e => {
    setEmail(e.currentTarget.value);
  }, []);

  const emailFeedback = validateEmail(email);

  const dispatch = useDispatch();

  const handlePasswordReset = useCallback(
    e => {
      e.preventDefault();
      dispatch(authActions.forgotPassword(email));
    },
    [dispatch, email]
  );

  const handleLogin = useCallback(() => {
    dispatch(dialogActions.showDialog(DIALOGS.LOGIN));
  }, [dispatch]);

  return (
    <MenuContent>
      <MenuSection type="forgot-form" heading={translate('Reset password')}>
        <MenuItemDescription>
          {translate("Can't remember your password?")}
          <br />
          {translate('Submit your email to receive instructions how to reset it.')}
        </MenuItemDescription>
        <form onSubmit={handlePasswordReset} className={formStyle({ type: 'reset-password' })}>
          <Field
            name="email"
            id="forgot-password-email"
            label="Your email"
            type="email"
            onChange={handleEmailUpdate}
            value={email}
            required
            feedback={emailFeedback}
          />
          <Button type="submit" block disabled={Boolean(emailFeedback)}>
            {translate('Submit')}
          </Button>
        </form>
      </MenuSection>
      <MenuSection type="forgot-login" description={translate('Remembered your password?')}>
        <Button color="text-borderless" block onClick={handleLogin} bold>
          {translate('Log in')}
        </Button>
      </MenuSection>
    </MenuContent>
  );
};

export default ForgotPasswordMenu;
