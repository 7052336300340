import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { getExtraInfoCollectionsMap } from '../../modules/model/nodesSelectors';
import { modelSettingsSelectors } from '../../modules/settings';
import { useTranslate } from '../../utility/hooks';
import { Gallery } from '../Atoms';
import Icon from '../Icon';
import MarkdownContent from '../MarkdownContent';

const ExtraInfo = ({ collectionName, style, children, onToggleFullscreenImage }) => {
  const [panelIndex, setPanelIndex] = useState(0);
  const collections = useSelector(getExtraInfoCollectionsMap);
  const generateImageURL = useSelector(modelSettingsSelectors.selectImageUrlGenerator);
  const collection = collections[collectionName] || {};
  const translate = useTranslate();
  const { infoPanels = [], externalLink = {} } = collection;

  const currentPanel = infoPanels[panelIndex];

  const { url, label } = externalLink;

  useEffect(() => {
    if (infoPanels.length <= panelIndex) {
      setPanelIndex(0);
    }
  }, [infoPanels.length, panelIndex]);

  const images = useMemo(
    () =>
      infoPanels
        .filter(({ image }) => Boolean(image?.fileName))
        .map(({ image }) => ({ original: generateImageURL(image.fileName), isPanorama: image.isPanorama })),
    [generateImageURL, infoPanels]
  );

  const textContent = useMemo(
    () => (currentPanel ? currentPanel.textHtml || (currentPanel.text || '').split('\n').join('<br/>') : ''),
    [currentPanel]
  );

  if (!currentPanel) return null;

  return (
    <section className={style('body')}>
      <h3 className={style('header')}>{translate(currentPanel.caption)}</h3>
      {images.length ? (
        <div className={style('gallery')}>
          <Gallery
            images={images}
            imagesMix={style('images')}
            showFullscreenButton
            onToggleFullscreen={onToggleFullscreenImage}
            onChangeActiveIndex={setPanelIndex}
          />
        </div>
      ) : null}
      <div className={style('info')}>
        <MarkdownContent html={translate(textContent)} />
      </div>
      <div className={style('links')}>
        {url ? (
          <a href={url} target="_blank" rel="noopener noreferrer" className={style('external-link')}>
            {translate(label) || url} <Icon type="open-in-new" scale={0.9} />
          </a>
        ) : null}
      </div>
      {children}
    </section>
  );
};

ExtraInfo.defaultProps = {
  collectionName: '',
  style: () => {},
  children: null
};

ExtraInfo.propTypes = {
  collectionName: PropTypes.string,
  style: PropTypes.func,
  children: PropTypes.node,
  onToggleFullscreenImage: PropTypes.func.isRequired
};

export default ExtraInfo;
