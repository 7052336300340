import { createSelector } from 'reselect';
import LOADER_STATES from './loaderStates';

const getLoaders = state => state.loader;

export const getProgressActiveStatus = createSelector(
  [getLoaders],
  loaders =>
    loaders.geometries !== LOADER_STATES.LOADED ||
    loaders.parts !== LOADER_STATES.LOADED ||
    loaders.textures !== LOADER_STATES.LOADED ||
    loaders.childInstances !== LOADER_STATES.LOADED ||
    !loaders.scene
);

export const getIsPartsLoaded = createSelector([getLoaders], ({ parts }) => parts === LOADER_STATES.LOADED);
const getIsInitialStateLoading = createSelector(
  [getLoaders],
  ({ initialState, childInstances }) =>
    initialState === LOADER_STATES.LOADING || childInstances === LOADER_STATES.LOADING
);

export const getIsSplashScreenActive = createSelector(
  [getIsPartsLoaded, getIsInitialStateLoading],
  (isPartsLoaded, isInitialStateLoading) => !isPartsLoaded || isInitialStateLoading
);

export const selectIsInstanceSaving = createSelector(
  [getLoaders],
  ({ instanceUpdating }) => instanceUpdating === LOADER_STATES.LOADING
);

export const selectIsInstanceSending = createSelector(
  [getLoaders],
  ({ instanceSending }) => instanceSending === LOADER_STATES.LOADING
);

export const selectIsUserInstanceDeleting = createSelector(
  [getLoaders],
  ({ userInstanceDeleting }) => userInstanceDeleting === LOADER_STATES.LOADING
);

export const selectIsRequestingAuthorization = createSelector(
  [getLoaders],
  ({ authRequest }) => authRequest === LOADER_STATES.LOADING
);
