import { PerspectiveCamera, WebGLRenderer, sRGBEncoding, Vector3, Matrix4, OrthographicCamera } from 'three';
import getClippingPlane from '../Viewer/Renderer/getClippingPlane';
import getThreeSetup from '../Viewer/getThreeSetup';
import {
  CAMERA_IMPERIAL,
  CAMERA_METRIC,
  DEFAULT_CAMERA_IMPERIAL,
  DEFAULT_CAMERA_METRIC,
  MODEL_UNITS
} from '../../utility/viewerSettings';
import { ORTHO_CAMERAS } from '../../utility/viewTypes';
import getFrustum from '../../utility/getFrustum';
import meshStore from '../../modules/geometries/MeshStore';

const { scene, camera, sceneTransform } = getThreeSetup();

const SnapshotRenderer = {
  width: 1200,
  height: 630,
  renderer: undefined,
  snapCamera: undefined
};

const MAX_WIDTH = 2048;

(function initRenderer() {
  if (SnapshotRenderer.renderer && SnapshotRenderer.snapCamera) {
    return;
  }

  const renderer = new WebGLRenderer({
    precision: 'highp',
    antialias: true,
    alpha: true, // aplha has to be true if antialias is true or it can produce rendering artefacts on white background on some browsers
    preserveDrawingBuffer: true // for grabing a snapshot
  });

  renderer.outputEncoding = sRGBEncoding;

  renderer.setSize(SnapshotRenderer.width, SnapshotRenderer.height);

  renderer.setClearColor(0xf9f9f9);

  renderer.shadowMap.enabled = true;

  const snapCamera = new PerspectiveCamera();
  const orthoCamera = new OrthographicCamera();

  SnapshotRenderer.renderer = renderer;
  SnapshotRenderer.snapCamera = snapCamera;
  SnapshotRenderer.orthoCamera = orthoCamera;
})();

function setSize(renderer, useCustomViewResolution, snapshotWidth, snapshotHeight) {
  let { width, height } = SnapshotRenderer;

  if (useCustomViewResolution && snapshotWidth && snapshotHeight) {
    const ratio = snapshotWidth > MAX_WIDTH ? snapshotWidth / MAX_WIDTH : 1;

    width = snapshotWidth / ratio;
    height = snapshotHeight / ratio;
  }

  renderer.setSize(width, height);

  return { width, height };
}

const renderSnapshot = (cameraView, modelUnits, useCustomViewResolution) => {
  if (scene === undefined || camera === undefined) {
    return Promise.resolve();
  }

  const isMetricUnits = modelUnits === MODEL_UNITS.METRIC;

  const {
    clippingPlane,
    cameraPosition,
    cameraTarget,
    isRelative,
    parentMatrix,
    name,
    type,
    snapshotWidth,
    snapshotHeight
  } = cameraView;

  const { renderer, snapCamera: perspectiveCamera, orthoCamera } = SnapshotRenderer;

  const snapshotCamera = ORTHO_CAMERAS[type] ? orthoCamera : perspectiveCamera;

  if (renderer === undefined || snapshotCamera === undefined) {
    return Promise.resolve();
  }

  const { width, height } = setSize(renderer, useCustomViewResolution, snapshotWidth, snapshotHeight);

  snapshotCamera.copy(camera);

  const position = new Vector3().copy(
    cameraPosition || (isMetricUnits ? DEFAULT_CAMERA_METRIC.cameraPosition : DEFAULT_CAMERA_IMPERIAL.cameraPosition)
  );
  const target = new Vector3().copy(
    cameraTarget || (isMetricUnits ? DEFAULT_CAMERA_METRIC.cameraTarget : DEFAULT_CAMERA_IMPERIAL.cameraTarget)
  );

  if (isRelative) {
    const inverseScene = new Matrix4().copy(sceneTransform).invert();
    const pureParent = new Matrix4().multiplyMatrices(parentMatrix, inverseScene).premultiply(sceneTransform);

    target.applyMatrix4(pureParent);
    position.applyMatrix4(pureParent);
  }

  snapshotCamera.position.copy(position);
  snapshotCamera.lookAt(target.x, target.y, target.z);
  const settings = isMetricUnits ? CAMERA_METRIC : CAMERA_IMPERIAL;

  if (snapshotCamera.isOrthographicCamera) {
    const frustum = getFrustum(position, target, settings.fov, width / height);

    Object.assign(snapshotCamera, frustum);
  }
  snapshotCamera.aspect = width / height;

  snapshotCamera.fov = settings.fov;
  snapshotCamera.near = settings.near;
  snapshotCamera.far = settings.far;

  snapshotCamera.updateProjectionMatrix();

  const snapshotCanvas = renderer.domElement;

  // get clipping plane for snapshot
  const clipPlane = clippingPlane?.enabled ? getClippingPlane(cameraView) : null;

  renderer.clippingPlanes = clipPlane ? [clipPlane] : [];

  meshStore.resetInteraction();

  // hide excluded elements
  const excludeHistory = [];

  scene.traverse(item => {
    if (item.userData.excludeFromSnapshots) {
      excludeHistory.push({ item, previousVisible: item.visible });
      // eslint-disable-next-line no-param-reassign
      item.visible = false;
    }
  });

  renderer.render(scene, snapshotCamera);

  excludeHistory.forEach(({ item, previousVisible }) => {
    // eslint-disable-next-line no-param-reassign
    item.visible = previousVisible;
  });

  // show excluded elements

  if (!snapshotCanvas) {
    return Promise.resolve();
  }

  return new Promise(resolve => {
    snapshotCanvas.toBlob(blob => resolve({ blob, name }));
  }).catch(error => {
    console.error('Not possible to render snapshot', cameraView); // eslint-disable-line no-console

    return { error };
  });
};

export default renderSnapshot;
