import React from 'react';
import PropTypes from 'prop-types';
import menuStyle from './style';
import MenuHeading from './MenuHeading';
import MenuItemDescription from './MenuItemDescription';

const MenuSection = ({ children, type, heading, description }) => {
  return (
    <div className={menuStyle('section', { type })}>
      {heading ? <MenuHeading>{heading}</MenuHeading> : null}
      {description ? <MenuItemDescription type="centered">{description}</MenuItemDescription> : null}
      {children}
    </div>
  );
};

MenuSection.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
  heading: PropTypes.node,
  description: PropTypes.node
};

MenuSection.defaultProps = {
  heading: null,
  description: null,
  children: null
};

export default MenuSection;
