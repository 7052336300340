import { createSelector } from 'reselect';
import { Matrix4 } from 'three';
import * as uiSelectors from '../ui/uiSelectors';
import * as texturesSelectors from '../textures/texturesSelectors';
import * as modelSettingsSelectors from '../settings/modelSettingsSelectors';
import { INDEX_TAB, REVIEW_TAB, SUMMARY_TAB } from '../ui/tabNames';
import { selectedOptionsSelectors } from '../selectedOptions';
import { TRANSFORM_SUFFIX } from '../../utility/controlDefinitions';
import * as partsSelectors from '../parts/partsSelectors';
import { getControls } from './nodesSelectors';
import * as priceSelectors from './priceSelectors';
// Control related selectors.

export const selectChildTabControls = createSelector(
  [priceSelectors.selectChildTabContent],
  (content = {}) => content.controls || []
);
export const selectChildTabName = createSelector(
  [priceSelectors.selectChildTabContent],
  (content = {}) => content.childTabName
);
export const selectChildTabParentControl = createSelector(
  [priceSelectors.selectChildTabContent],
  (content = {}) => content.parentControlName
);
export const selectChildTabParentControlTreeName = createSelector(
  [priceSelectors.selectChildTabContent],
  (content = {}) => content.parentControlTreeName
);
export const selectRootParentControlName = createSelector(
  [priceSelectors.selectChildTabContent],
  (content = {}) => content.rootParentControlName
);

export const getSelectedControlGroup = createSelector(
  [uiSelectors.selectSelectedControlGroupName, priceSelectors.selectVisibleControls, selectChildTabControls],
  (selectedControlGroupName, visibleControls, childTabControls = []) => {
    return (
      visibleControls.find(({ name }) => name === selectedControlGroupName) ||
      childTabControls.find(({ name }) => name === selectedControlGroupName) ||
      visibleControls[0]
    );
  }
);

export const getSelectedControlGroupName = createSelector(
  [getSelectedControlGroup],
  controlGroup => controlGroup?.name
);

// used by Viewer
export const getSelectedMaterialOptions = createSelector([getControls], controls => {
  // All controls already have their value updated by selectedOptions - so we check list of options by control value.
  return controls.reduce((apply, control) => {
    const { list, value } = control;

    if (list && value !== undefined) {
      const selectedOption = list.find(option => option.name === value);

      if (selectedOption && Array.isArray(selectedOption.material)) {
        // for each change definition we add to apply object.
        selectedOption.material.forEach(({ original, apply: current, isLocal }) => {
          // local changes are applied later
          if (original && apply && !isLocal) {
            apply[original] = current; // eslint-disable-line no-param-reassign
          }
        });
      }
    }

    return apply;
  }, {});
});

export const selectOptionImageSelector = createSelector(
  [
    texturesSelectors.selectTexturesList,
    partsSelectors.selectMaterialsMap,
    modelSettingsSelectors.selectImageUrlGenerator
  ],
  (texturesList, materialsMap, generateImageURL) => option => {
    if (option?.image?.fileName) {
      const { fileName } = option.image;

      return { url: generateImageURL(fileName) };
    }

    if (Array.isArray(option?.material) && option.material.length > 0) {
      const loaded = texturesList.find(tex => tex.materialName === option.material[0].apply);

      if (loaded) {
        const { textureName } = loaded;

        return { url: generateImageURL(textureName, true) };
      }

      const color = materialsMap[option.material[0].apply]?.ColorDiffuse;

      return color ? { color } : '';
    }

    return '';
  }
);

export const getControlNavigationTree = createSelector(
  [
    priceSelectors.selectPricedControlsMap,
    uiSelectors.getTabs,
    getSelectedControlGroupName,
    selectRootParentControlName,
    uiSelectors.selectCurrentTabName
  ],
  // eslint-disable-next-line
  (controlsMap, tabs, selectedControlGroupName, rootParentControlName, currentTabName) => {
    const currentControlName = rootParentControlName || selectedControlGroupName;
    const result = {};
    let tabIndex = 1;

    let completed = true;

    if (tabs[0]?.name === INDEX_TAB) {
      result[INDEX_TAB] = { name: tabs[0].name, displayName: tabs[0].displayName, controls: tabs[0].controls };
    }

    tabs.forEach(tab => {
      const controls = tab.isEmptyTab ? [{ isEmpty: true, name: `${tab.name}_emptyControl` }] : tab.controls;

      controls.forEach(({ name, isEmpty = false }) => {
        if ((controlsMap[name] && !controlsMap[name].disabled) || isEmpty) {
          if (!result[tab.name]) {
            result[tab.name] = {
              name: tab.name,
              displayName: tab.displayName,
              controls: [],
              completed,
              isEmpty,
              isControlTab: true,
              isVisibleInTabNavigationList: true,
              index: tabIndex
            };

            tabIndex += 1;
          }

          const active = (name === currentControlName || isEmpty) && tab.name === currentTabName;

          result[tab.name].controls.push({
            name,
            displayName: isEmpty ? 'No controls' : controlsMap[name].displayName,
            active,
            completed,
            isEmpty,
            tabIndex
          });

          if (active) {
            result[tab.name].active = active;

            completed = false;
          }
        }
      });
    });

    if (tabs.find(tab => tab.name === REVIEW_TAB)) {
      result[REVIEW_TAB] = {
        name: REVIEW_TAB,
        displayName: 'Review',
        isVisibleInTabNavigationList: true,
        controls: [{ name: 'review', displayName: 'Review' }]
      };
    }

    const lastIndex = tabs.length - 1;

    if (tabs[lastIndex]?.name === SUMMARY_TAB) {
      result[SUMMARY_TAB] = {
        name: tabs[lastIndex].name,
        displayName: tabs[lastIndex].displayName,
        controls: tabs[lastIndex].controls
      };
    }

    return Object.values(result);
  }
);

export const getControlNavigationList = createSelector([getControlNavigationTree], controlNavigationTree => {
  const controlList = [];
  let activeControl;
  let activeIndex;
  let currentIndex = 0;

  const tabList = [REVIEW_TAB, SUMMARY_TAB];

  controlNavigationTree.forEach((tab, index) => {
    const isLastTab = !controlNavigationTree[index + 1];
    const isLastControlTab =
      (isLastTab || tabList.includes(controlNavigationTree[index + 1]?.name)) && !tabList.includes(tab.name);

    tab.controls.forEach(control => {
      controlList.push({
        ...control,
        tabName: tab.name,
        tabDisplayName: tab.displayName,
        nextTab: controlNavigationTree[index + 1]?.name,
        prevTab: controlNavigationTree[index - 1]?.name,
        isLastTab,
        isLastControlTab
      });

      currentIndex = controlList.length - 1;

      if (control.active) {
        activeIndex = currentIndex;

        activeControl = controlList[activeIndex];
      }
    });

    if (isLastControlTab && controlList[currentIndex]) {
      controlList[currentIndex].isLastControl = true;
    }
  });

  return { controlList, activeControl, activeIndex };
});

export const getChildControlNavigationContent = createSelector(
  [selectChildTabControls, getSelectedControlGroupName],
  (controls, selectedControlGroupName) => {
    const list = [];
    let currentIndex;

    controls.forEach(control => {
      const active = control.name === selectedControlGroupName;

      if (active) {
        currentIndex = list.length;
      }

      list.push({ name: control.name, active });
    });

    return {
      list,
      rightControl: list[currentIndex + 1]?.name,
      leftControl: list[currentIndex - 1]?.name
    };
  }
);

export const getFirstControlTab = createSelector(
  [getControlNavigationList],
  ({ controlList }) => controlList[0]?.tabName
);

export const getLastControlTab = createSelector(
  [getControlNavigationList],
  ({ controlList }) => controlList.find(control => control.isLastControlTab)?.tabName
);

export const selectAreInteractiveControlsEnabled = createSelector([getSelectedControlGroup], control => {
  return control?.interactive;
});

export const selectInteractiveTargetControls = createSelector(
  [getSelectedControlGroup, priceSelectors.getPricedControls],
  ({ treeName, interactionGroups } = {}, controls) => {
    return controls.filter(control => {
      return interactionGroups.some(
        ({ name: groupName }) =>
          control.treeName !== treeName &&
          control.interactionGroups.some(({ name: targetGroupName }) => groupName === targetGroupName)
      );
    });
  }
);

export const selectAreTransformControlsEnabled = createSelector([getSelectedControlGroup], control => {
  return control?.transform?.enabled;
});

export const selectTransformControlOptions = createSelector([getSelectedControlGroup], ({ transform = {} }) => {
  const { translationStep = 100, tX = {}, tY = {}, tZ = {}, rotationStep = 90, rX = {}, rY = {}, rZ = {} } = transform;
  const { enabled: txEnabled = false } = tX;
  const { enabled: tyEnabled = false } = tY;
  const { enabled: tzEnabled = false } = tZ;
  const { enabled: rxEnabled = false } = rX;
  const { enabled: ryEnabled = false } = rY;
  const { enabled: rzEnabled = false } = rZ;

  return {
    txEnabled,
    tyEnabled,
    tzEnabled,
    translationStep,
    rxEnabled,
    ryEnabled,
    rzEnabled,
    rotationStep
  };
});

export const getSelectedControlGroupTransformValue = createSelector(
  [getSelectedControlGroup, selectedOptionsSelectors.selectSelectedOptions],
  (selectedControl, selectedOptions) => {
    const { treeName } = selectedControl;
    const transformControlParameter = `${treeName}${TRANSFORM_SUFFIX}`;
    const value = selectedOptions[transformControlParameter];

    const result = new Matrix4();

    if (value) {
      try {
        const values = JSON.parse(value);

        if (values.length === 16) {
          result.fromArray(values);
        }
      } catch {
        // continue anyway
      }
    }

    return result;
  }
);
