import React, { useCallback, useEffect, useMemo, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import b from 'b_';
import copyToClipboard from 'copy-to-clipboard';
import { MenuSection } from '../../Menu';
import { useLinkGenerators, useSendToEmail, useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Field, Button } from '../../Atoms';
import { notificationsActions } from '../../../modules/notifications';
import Icon from '../../Icon';
import { FACEBOOK_APP_ID } from '../../../config';
import { instanceActions } from '../../../modules/instance';
import { sharingSelectors } from '../../../modules/settings';
import useMenuClose from '../../Menu/useMenuClose';
import { uiSelectors } from '../../../modules/ui';
import IsMobileContext from '../../IsMobileContext';
import { loaderSelectors } from '../../../modules/loader';
import Progress from '../../Progress';
import Actions from '../../Actions';
import NOTIFICATION_MESSAGES from '../../../utility/notificationMessages';

import './ShareMenu.scss';

const shareMenuStyle = b.with('share-menu');

const ShareLoader = () => {
  const translate = useTranslate();

  return (
    <MenuContent>
      <MenuSection heading={translate('Saving the design...')} type="share-loader-header" />
      <div className={shareMenuStyle('progress')}>
        <Progress />
      </div>
    </MenuContent>
  );
};

const ShareMenu = () => {
  const translate = useTranslate();
  const isMobile = useContext(IsMobileContext);

  const { twitter: twitterMessage, title, description } = useSelector(sharingSelectors.selectSharingSettings);
  const isVisible = useSelector(uiSelectors.getIsShareImageReady);
  const isSending = useSelector(loaderSelectors.selectIsInstanceSending);
  const { handleSendToEmail, isSendToEmailHidden } = useSendToEmail();
  const { shareLink: linkToShare } = useLinkGenerators();

  const encodedLinkToShare = useMemo(() => encodeURIComponent(linkToShare), [linkToShare]);
  const encodedTwitterMessage = useMemo(() => encodeURIComponent(twitterMessage), [twitterMessage]);
  const encodedShareTitle = useMemo(() => encodeURIComponent(title), [title]);
  const encodedShareDescription = useMemo(() => encodeURIComponent(description), [description]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(instanceActions.saveSnapshot());
  }, [dispatch]);

  const handleCopyToClipBoard = useCallback(() => {
    copyToClipboard(linkToShare);

    dispatch(notificationsActions.showSuccessNotification(translate(NOTIFICATION_MESSAGES.URL_COPIED_TO_CLIPBOARD)));
  }, [dispatch, translate, linkToShare]);

  const handleWebShare = useCallback(() => {
    if (!navigator.share) {
      return;
    }

    navigator.share({ title, text: description, url: linkToShare });
  }, [linkToShare, description, title]);

  const handleClose = useMenuClose();

  if (!isVisible) {
    return <ShareLoader />;
  }

  const heading = (
    <>
      <span className={shareMenuStyle('heading-check')}>
        <Icon scale={1.4} type="check" />
      </span>
      {translate('Your design was saved')}
    </>
  );

  return (
    <MenuContent>
      <MenuSection heading={heading} type="share-url">
        <Field
          label={translate('Direct URL to your design')}
          value={linkToShare}
          bold
          readOnly
          id="share-instance-url"
          name="share-instance-url"
        />
        <Button block color="main-outline" bold onClick={handleCopyToClipBoard} data-heap="copy-url">
          {translate('Copy URL')}
        </Button>
        {isSendToEmailHidden ? null : (
          <Button
            block
            color="main-outline"
            bold
            onClick={handleSendToEmail}
            mix={shareMenuStyle('send-to-email')}
            disabled={isSending}
            data-heap="send-to-my-email"
          >
            {translate(isSending ? 'Sending...' : 'Send to my email')}
          </Button>
        )}
      </MenuSection>
      <MenuSection description={translate('Share')} type="share-icons">
        <div className={shareMenuStyle()}>
          <Button
            type="external-link"
            href={`https://www.facebook.com/dialog/share?app_id=${FACEBOOK_APP_ID}&display=popup&href=${encodedLinkToShare}&redirect_uri=${encodedLinkToShare}`}
            color="icon"
            mix={shareMenuStyle('icon', { type: 'facebook' })}
            aria-label={translate('Share via Facebook')}
            data-balloon-pos="down"
          >
            <Icon scale={1.6} type="facebook" />
          </Button>
          <Button
            type="external-link"
            href={`https://www.facebook.com/dialog/send?app_id=${FACEBOOK_APP_ID}&link=${encodedLinkToShare}&redirect_uri=${encodedLinkToShare}`}
            color="icon"
            mix={shareMenuStyle('icon', { type: 'messenger' })}
            aria-label={translate('Share via Messenger')}
            data-balloon-pos="down"
          >
            <Icon scale={1.6} type="messenger" />
          </Button>
          {isMobile.phone || (isMobile.tablet && !isMobile.apple.tablet) ? (
            <Button
              type="external-link"
              href={`https://wa.me/?text=${encodedLinkToShare}`}
              color="icon"
              mix={shareMenuStyle('icon', { type: 'whatsapp' })}
              aria-label={translate('Share via WhatsApp')}
              data-balloon-pos="down"
            >
              <Icon scale={1.4} type="whatsapp" />
            </Button>
          ) : null}
          <Button
            type="external-link"
            href={`https://twitter.com/intent/tweet?text=${encodedTwitterMessage}&url=${encodedLinkToShare}`}
            color="icon"
            mix={shareMenuStyle('icon', { type: 'twitter' })}
            aria-label={translate('Share via Twitter')}
            data-balloon-pos="down"
          >
            <Icon scale={1.6} type="twitter" />
          </Button>
          <Button
            type="external-link"
            href={`mailto:?subject=${encodedShareTitle}&body=${encodedShareDescription}%0D%0A%0D%0A${encodedLinkToShare}`}
            color="icon"
            mix={shareMenuStyle('icon', { type: 'mail' })}
            aria-label={translate('Share via email')}
            data-balloon-pos="down"
          >
            <Icon scale={1.4} type="mail" />
          </Button>
          {navigator && navigator.share ? (
            <Button
              mix={shareMenuStyle('icon', { type: 'web-share' })}
              color="icon"
              onClick={handleWebShare}
              aria-label={translate('Share (more options)')}
              data-balloon-pos="down"
            >
              <Icon scale={1.4} type="web-share" />
            </Button>
          ) : null}
        </div>
      </MenuSection>
      <MenuSection type="share-actions">
        <Actions group="shareDialog" rounded={false} size="md" />
        <Button color="accent-outline" bold block onClick={handleClose}>
          {translate('Keep editing')}
        </Button>
      </MenuSection>
    </MenuContent>
  );
};

export default ShareMenu;
