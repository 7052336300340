import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { modelSelectors, controlSelectors } from '../../../modules/model';
import { texturesSelectors } from '../../../modules/textures';
import meshStore from '../../../modules/geometries/MeshStore';

// for global material overrides
const useMaterials = () => {
  const materialsMap = useSelector(modelSelectors.selectMaterials);
  const spriteMaterials = useSelector(modelSelectors.selectSpriteMaterials);
  const selectedMaterialOptions = useSelector(controlSelectors.getSelectedMaterialOptions);
  const texturesDone = useSelector(texturesSelectors.getIsTexturesReceived);

  useEffect(() => {
    if (texturesDone) meshStore.updateMaterialOverrides(selectedMaterialOptions);
  }, [selectedMaterialOptions, texturesDone]);
  useEffect(() => {
    if (texturesDone) meshStore.updateMaterialReferences(materialsMap, spriteMaterials);
  }, [materialsMap, spriteMaterials, texturesDone]);
};

export default useMaterials;
