import * as partsActions from '../parts/partsActions';
import * as geometriesActions from '../geometries/geometriesActions';
import * as texturesActions from '../textures/texturesActions';
import * as instanceActions from '../instance/instanceActions';
import * as userInstancesActions from '../userInstances/userInstancesActions';
import { authActions } from '../auth';
import { initialStateActions } from '../initialState';
import { SCENE_DID_LOAD } from './loaderActions';
import LOADER_STATES from './loaderStates';

const initialState = {
  parts: LOADER_STATES.INITIAL,
  geometries: LOADER_STATES.INITIAL,
  textures: LOADER_STATES.INITIAL,
  initialState: LOADER_STATES.INITIAL,
  instanceUpdating: LOADER_STATES.INITIAL,
  instanceSending: LOADER_STATES.INITIAL,
  userInstanceDeleting: LOADER_STATES.INITIAL,
  scene: false,
  authRequest: LOADER_STATES.INITIAL,
  childInstances: LOADER_STATES.INITIAL
};

const loaderReducer = (state = initialState, { type }) => {
  switch (type) {
    case partsActions.FETCH_PARTS:
    case partsActions.FETCH_PARTS__REQUEST:
      return { ...state, parts: LOADER_STATES.LOADING };
    case geometriesActions.FETCH_GEOMETRIES__REQUEST:
      return { ...state, geometries: LOADER_STATES.LOADING };
    case texturesActions.FETCH_TEXTURES__REQUEST:
      return { ...state, textures: LOADER_STATES.LOADING };
    case partsActions.FETCH_PARTS__SUCCESS:
      return { ...state, parts: LOADER_STATES.LOADED, scene: false };
    case geometriesActions.FETCH_GEOMETRIES__SUCCESS:
      return { ...state, geometries: LOADER_STATES.LOADED, scene: false };
    case texturesActions.FETCH_TEXTURES__SUCCESS:
      return { ...state, textures: LOADER_STATES.LOADED, scene: false };
    case initialStateActions.FETCH_INITIAL_STATE:
      return { ...state, initialState: LOADER_STATES.LOADING };
    case initialStateActions.FETCH_INITIAL_STATE__SUCCESS:
      return { ...state, initialState: LOADER_STATES.LOADED, childInstances: LOADER_STATES.LOADING };
    case instanceActions.FETCH_CHILD_INSTANCES:
      return { ...state, childInstances: LOADER_STATES.LOADING };
    case instanceActions.FETCH_CHILD_INSTANCES__SUCCESS: {
      return { ...state, childInstances: LOADER_STATES.LOADED };
    }
    case SCENE_DID_LOAD:
      return { ...state, scene: true };
    case instanceActions.CREATE_LEAD:
    case instanceActions.UPDATE_LEAD:
    case instanceActions.CREATE_ORPHAN_LEAD:
    case userInstancesActions.CREATE_INSTANCE:
    case userInstancesActions.SAVE_INSTANCE:
      return { ...state, instanceUpdating: LOADER_STATES.LOADING };
    case instanceActions.CREATE_LEAD__SUCCESS:
    case instanceActions.CREATE_ORPHAN_LEAD__SUCCESS:
    case instanceActions.CREATE_ORPHAN_LEAD__FAILURE:
    case instanceActions.CREATE_LEAD__FAILURE:
    case userInstancesActions.CREATE_INSTANCE__FAILURE:
    case userInstancesActions.CREATE_INSTANCE__SUCCESS:
    case userInstancesActions.SAVE_INSTANCE__FAILURE:
    case userInstancesActions.SAVE_INSTANCE__SUCCESS:
      return { ...state, instanceUpdating: LOADER_STATES.LOADED };
    case instanceActions.SEND_INSTANCE_TO_EMAIL__REQUEST:
      return { ...state, instanceSending: LOADER_STATES.LOADING };
    case instanceActions.SEND_INSTANCE_TO_EMAIL__FAILURE:
    case instanceActions.SEND_INSTANCE_TO_EMAIL__SUCCESS:
      return { ...state, instanceSending: LOADER_STATES.LOADED };
    case userInstancesActions.DELETE_INSTANCE__CONFIRM:
      return { ...state, userInstanceDeleting: LOADER_STATES.LOADING };
    case userInstancesActions.DELETE_INSTANCE__FAILURE:
    case userInstancesActions.DELETE_INSTANCE__SUCCESS:
      return { ...state, userInstanceDeleting: LOADER_STATES.LOADED };
    case authActions.LOGIN__REQUEST:
    case authActions.REGISTRATION__REQUEST:
      return { ...state, authRequest: LOADER_STATES.LOADING };
    case authActions.LOGIN__SUCCESS:
    case authActions.LOGIN__FAILURE:
    case authActions.REGISTRATION__SUCCESS:
    case authActions.REGISTRATION__FAILURE:
      return { ...state, authRequest: LOADER_STATES.LOADED };
    default:
      return state;
  }
};

export default loaderReducer;
