import { all, call, put, select, takeLatest, take, race, delay } from 'redux-saga/effects';
import api from '../../api';
import { authActions, authSelectors } from '../auth';
import { getSummary } from '../ui/summarySelectors';
import * as instanceSelectors from '../instance/instanceSelectors';
import { selectedOptionsActions, selectedOptionsSelectors } from '../selectedOptions';
import * as seedSelectors from '../seed/seedSelectors';
import * as dialogActions from '../dialog/dialogActions';
import { DIALOGS } from '../../utility/dialogs';
import Heap from '../../utility/heap';
import { INITIAL_INSTANCE_ID } from '../../config';
import { projectSelectors } from '../project';
import PATH from '../../utility/paths';
import * as uiSelectors from '../ui/uiSelectors';
import * as uiActions from '../ui/uiActions';
import {
  fetchUserInstancesSuccess,
  fetchUserInstancesFailure,
  fetchUserInstancesRequest,
  DELETE_INSTANCE,
  deleteInstanceSuccess,
  deleteInstanceFailure,
  CREATE_INSTANCE,
  createInstanceSuccess,
  createInstanceFailure,
  SAVE_INSTANCE,
  saveInstance,
  saveInstanceSuccess,
  saveInstanceFailure,
  DELETE_INSTANCE__CONFIRM,
  SAVE_CHILD_INSTANCE,
  CREATE_CHILD_INSTANCE,
  saveChildInstanceFailure,
  saveChildInstanceSuccess,
  createChildInstanceFailure,
  createChildInstanceSuccess
} from './userInstancesActions';

function* fetchUserInstancesSaga() {
  const isJWTAuth = yield select(authSelectors.getIsJWTAuth);

  if (isJWTAuth) {
    return;
  }

  yield put(fetchUserInstancesRequest());

  const { data, error } = yield call(api.getUserInstances);

  if (error) {
    yield put(fetchUserInstancesFailure(error));
  } else {
    yield put(fetchUserInstancesSuccess(data));
  }
}

function* watchFetchUserInstancesSaga() {
  yield takeLatest([authActions.LOGIN__SUCCESS, authActions.GET_USER_INFO__SUCCESS], fetchUserInstancesSaga);
}

function* doDeleteInstanceSaga(action) {
  const { id, active, historyReplace } = action.payload;

  yield put(dialogActions.showDialog(DIALOGS.CONFIRM_DELETE_INSTANCE));
  const projectLink = yield select(projectSelectors.selectProjectLink);

  yield take(DELETE_INSTANCE__CONFIRM);

  const { data, error } = yield call(api.deleteInstance, id);

  if (error) {
    yield put(deleteInstanceFailure(error));
  } else {
    if (active) {
      historyReplace(PATH.PROJECT.replace(':projectId', projectLink));
    }

    yield put(deleteInstanceSuccess(data));

    Heap.track('DELETE_INSTANCE');

    yield put(dialogActions.showDialog(DIALOGS.PROFILE));
  }
}

function* raceDeleteInstanceDialog(action) {
  yield race({
    task: call(doDeleteInstanceSaga, action),
    cancel: take(dialogActions.DIALOG__HIDE)
  });
}

function* watchDeleteInstanceSaga() {
  yield takeLatest(DELETE_INSTANCE, raceDeleteInstanceDialog);
}

function* doCommonCreateInstanceSaga(action) {
  const { instanceName, formState, ignoreEmail } = action.payload;
  const isLoggedIn = yield select(authSelectors.getLoggedIn);
  const partnerUserId = yield select(authSelectors.getPartnerUserId);
  const savedState = yield select(selectedOptionsSelectors.selectSelectedOptions);
  const summary = yield select(getSummary);
  const consent = yield select(authSelectors.selectLocalConsent);
  const copiedFrom = yield select(instanceSelectors.selectInstanceId);
  const seedId = yield select(seedSelectors.selectSeedId);
  const mainSeedId = yield select(seedSelectors.selectSeedMainId);
  const lang = yield select(uiSelectors.selectSelectedLanguage);

  const body = {
    instanceName,
    savedState,
    summary,
    consent,
    copiedFrom: copiedFrom === INITIAL_INSTANCE_ID ? undefined : copiedFrom, // otherwise API will try to generate the seedId from '_initial'
    seedId,
    lang,
    ...formState
  };

  if (!isLoggedIn && partnerUserId) {
    body.partnerUserId = partnerUserId;
  }

  if (mainSeedId !== seedId) {
    body.parentSeedId = mainSeedId;
  }

  return yield call(isLoggedIn ? api.createInstance : api.createOrphanInstance, body, ignoreEmail);
}

function* doCreateInstanceSaga(action) {
  const { data, error } = yield call(doCommonCreateInstanceSaga, action);

  if (error) {
    yield put(createInstanceFailure(error));
  } else {
    yield put(createInstanceSuccess(data, action.payload.formState?.email));

    Heap.track('CREATE_INSTANCE', { instanceId: data._id, isOrphan: data.orphan });
  }
}

function* watchCreateInstanceSaga() {
  yield takeLatest(CREATE_INSTANCE, doCreateInstanceSaga);
}

function* doAutoSaveInstanceSaga() {
  const deprecatedSeed = yield select(seedSelectors.selectIsSeedDeprecated);

  if (deprecatedSeed) return;

  const isOwner = yield select(instanceSelectors.getIsOwner);

  if (!isOwner) return;

  yield delay(1500);

  const isMainSeed = yield select(seedSelectors.selectIsMainSeed); // it's possible to open child instance during delay

  if (!isMainSeed) return;

  yield put(saveInstance(true));
}

function* watchAutoSaveInstanceSaga() {
  yield takeLatest([selectedOptionsActions.SET_OPTION, uiActions.SELECT_LANGUAGE], doAutoSaveInstanceSaga);
}

function* doCommonSaveInstanceSaga(auto = false) {
  const savedState = yield select(selectedOptionsSelectors.selectSelectedOptions);
  const summary = yield select(getSummary);
  const consent = yield select(authSelectors.selectLocalConsent);
  const instance = yield select(instanceSelectors.selectInstance);
  const lang = yield select(uiSelectors.selectCurrentLanguage);

  const body = { savedState, summary, lang };

  if (auto) {
    body.consent = consent;
  }

  return yield call(api.saveInstance, instance._id, body);
}

function* doSaveInstanceSaga(action) {
  const { data, error } = yield call(doCommonSaveInstanceSaga, action.payload.auto);

  if (error) {
    yield put(saveInstanceFailure(error));
  } else {
    yield put(saveInstanceSuccess(data));
  }
}

function* watchSaveInstanceSaga() {
  yield takeLatest(SAVE_INSTANCE, doSaveInstanceSaga);
}

function* doSaveChildInstanceSaga() {
  const { data, error } = yield call(doCommonSaveInstanceSaga);

  if (error) {
    yield put(saveChildInstanceFailure(error));
  } else {
    yield put(saveChildInstanceSuccess(data));
  }
}
function* watchSaveChildInstanceSaga() {
  yield takeLatest(SAVE_CHILD_INSTANCE, doSaveChildInstanceSaga);
}

function* doCreateChildInstanceSaga(action) {
  const { data, error } = yield call(doCommonCreateInstanceSaga, action);

  if (error) {
    yield put(createChildInstanceFailure(error));
  } else {
    yield put(createChildInstanceSuccess(data, action.payload.formState?.email));

    Heap.track('CREATE_CHILD_INSTANCE', { instanceId: data._id, isOrphan: data.orphan });
  }
}
function* watchCreateChildInstanceSaga() {
  yield takeLatest(CREATE_CHILD_INSTANCE, doCreateChildInstanceSaga);
}

export default function* moduleSaga() {
  yield all([
    watchFetchUserInstancesSaga(),
    watchDeleteInstanceSaga(),
    watchCreateInstanceSaga(),
    watchAutoSaveInstanceSaga(),
    watchSaveInstanceSaga(),
    watchSaveChildInstanceSaga(),
    watchCreateChildInstanceSaga()
  ]);
}
