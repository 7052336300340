/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslate, useFieldFeedback } from '../../../utility/hooks';
import FieldFeedback from '../FieldFeedback';
import Select from './Select';
import inputStyle from './inputStyle';

import './Field.scss';

const Components = {
  default: 'input',
  textarea: 'textarea',
  select: Select
};

const Field = ({
  type,
  id,
  label,
  required,
  bold,
  name,
  value,
  mix,
  description,
  feedback,
  onFocus,
  onBlur,
  ...rest
}) => {
  const translate = useTranslate();

  const Component = Components[type] || Components.default;

  const itemId = id || `form-input-${name}`;

  const { isFeedbackShowing, handleBlur, handleFocus } = useFieldFeedback(feedback, onFocus, onBlur);

  return (
    <div className={`${inputStyle('group')} ${mix}`}>
      <label className={inputStyle('label', { type })} htmlFor={itemId}>
        {label} {required ? <span className={inputStyle('indicator')}>({translate('required')})</span> : null}
      </label>
      <Component
        id={itemId}
        name={name}
        className={inputStyle('input', { type, bold })}
        type={type}
        required={required}
        value={value}
        onFocus={handleFocus}
        onBlur={handleBlur}
        {...rest}
      />
      {isFeedbackShowing ? <FieldFeedback message={feedback} /> : null}
      <p className={inputStyle('description')}>{description}</p>
    </div>
  );
};

Field.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  bold: PropTypes.bool,
  mix: PropTypes.string,
  name: PropTypes.string.isRequired,
  description: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]),
  feedback: PropTypes.node,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

Field.defaultProps = {
  label: '',
  type: 'text',
  mix: '',
  bold: false,
  required: false,
  id: '',
  value: '',
  description: '',
  feedback: null,
  onFocus: undefined,
  onBlur: undefined
};

export default Field;
