export const IFRAME_PATH_PREFIX = '/#';

export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

function getOrigin(ignoreParent) {
  const parent = window.parentOrigin || '';

  if (!ignoreParent && parent) {
    return parent + IFRAME_PATH_PREFIX;
  }

  return window.location.origin;
}

export function getLinkToConfigurator(path, ignoreParent) {
  return getOrigin(ignoreParent) + path;
}
