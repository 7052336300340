import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import api, { SERVER_ROUTE } from '../../api';
import { partsActions, partsSelectors } from '../parts';
import getThreeSetup from '../../components/Viewer/getThreeSetup';
import { modelSettingsSelectors } from '../settings';
import { fetchTextures } from '../textures/texturesActions';
import { seedSelectors } from '../seed';
import { selectProjectId } from '../project/projectSelectors';
import { storeExternalParts } from '../parts/partsActions';
import { setGeometries, setGLTFGeometries } from './geometryStore';
import { fetchGeometriesFailure, fetchGeometriesRequest, fetchGeometriesSuccess } from './geometriesActions';

const loader = new GLTFLoader();

const loadModel = (url, seedId, projectId) => {
  return new Promise((resolve, reject) => {
    loader.load(SERVER_ROUTE.MODELS(url, projectId), asset => resolve({ asset, seedId }), undefined, reject);
  });
};

const { model } = getThreeSetup();

/** Downloads geometries based on partIds and seed.assets.url setting */
function* doFetchGeometriesSaga() {
  yield put(fetchGeometriesRequest());

  // gltf assets

  const assetsUrls = yield select(seedSelectors.selectSeedAssetsUrls);
  const projectId = yield select(selectProjectId);

  // part assets
  const parts = yield select(partsSelectors.selectPartIdsForGeometry);
  const partsConfigs = yield select(partsSelectors.selectPartsConfigs);

  const { maxInstanceCount, forceFetchingAssets } = yield select(modelSettingsSelectors.selectModelSettings);

  const [result, assetsResult] = yield all([
    // parts
    all(parts.map(({ id, updatedAt }) => call(api.assets.getPart, id, forceFetchingAssets ? updatedAt : undefined))),

    // models
    all(assetsUrls.map(({ seedId, url }) => call(loadModel, url, seedId, projectId)))
  ]);

  // gltf
  yield all(
    assetsResult.map(({ asset: currentModel, seedId }) => {
      const { parts: gParts } = setGLTFGeometries(currentModel, model, maxInstanceCount, seedId);

      return put(storeExternalParts(gParts));
    })
  );

  // parts

  const geometries = result
    .map(({ data }) =>
      data
        ? {
            ...data,
            maxInstanceCount: partsConfigs[data._id]?.maxInstanceCount,
            excludeFromSnapshots: partsConfigs[data._id]?.excludeFromSnapshots
          }
        : undefined
    )
    .filter(Boolean);

  if (result.length === geometries.length) {
    setGeometries(geometries, model, maxInstanceCount);

    const partIds = geometries.map(({ _id }) => _id);

    yield put(fetchTextures());

    yield put(fetchGeometriesSuccess(partIds));
  } else {
    yield put(fetchGeometriesFailure());
  }
}

function* watchFetchGeometriesSaga() {
  yield takeEvery(partsActions.FETCH_PARTS__SUCCESS, doFetchGeometriesSaga);
}

export default function* moduleSaga() {
  yield all([watchFetchGeometriesSaga()]);
}
