import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';
import { useSelector } from 'react-redux';
import { useSwitch, useTranslate } from '../../../utility/hooks';
import { Button } from '../../Atoms';
import { commonSelectors } from '../../../modules/model';
import HomeButton from '../NavigationButtons/HomeButton';
import TabNavigationContent from './TabNavigationContent';

import './TabNavigation.scss';

const tabNavigationStyle = b.with('tab-navigation');

const TabNavigation = ({ childNavigation }) => {
  const { toggle, isOpen, close } = useSwitch();
  const translate = useTranslate();
  const activeTab = useSelector(commonSelectors.getActiveTab);
  const title = activeTab ? `${activeTab.index}. ${translate(activeTab.displayName)}` : '';

  const [localTitle, setLocalTitle] = useState(title);

  const isChildModeEnabled = useSelector(commonSelectors.getIsChildModeEnabled);
  const disabled = isChildModeEnabled && !childNavigation;

  useEffect(() => {
    if (disabled) return;

    setLocalTitle(title);
  }, [disabled, title]);

  return (
    <>
      <div className={tabNavigationStyle('button', { disabled, child: childNavigation })}>
        {childNavigation ? null : <HomeButton />}
        <Button
          mix={tabNavigationStyle('current', { mode: childNavigation ? 'child' : 'default' })}
          onClick={toggle}
          aria-label={translate('Categories')}
          color="text"
          size="md"
          data-balloon-pos="down"
          label={localTitle}
          disabled={disabled}
        />
      </div>
      <TabNavigationContent onClose={close} isOpen={isOpen} style={tabNavigationStyle} />
    </>
  );
};

TabNavigation.defaultProps = {
  childNavigation: false
};

TabNavigation.propTypes = {
  childNavigation: PropTypes.bool
};

export default TabNavigation;
