import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import b from 'b_';

import './Switch.scss';

const switchStyle = b.with('switch');

const TYPE_CHECKBOX = 'checkbox';
const TYPE_TOGGLE = 'toggle';
const TYPE_RADIO = 'radio';

const Switch = ({
  type,
  onChange,
  name,
  value,
  caption,
  children,
  checked,
  disabled,
  inactive,
  hideIcon,
  title,
  divider,
  mix,
  onBlur,
  onFocus,
  groupLabel
}) => {
  const isRadioType = type === TYPE_RADIO;
  const inputType = isRadioType ? type : TYPE_CHECKBOX;
  const id = name + divider + (value || '');

  const handleChange = useCallback(
    e => {
      onChange(e.currentTarget.name, isRadioType ? e.currentTarget.value : e.currentTarget.checked);
    },
    [isRadioType, onChange]
  );

  const handleClickOnLabel = useCallback(e => {
    e.stopPropagation();
  }, []);

  return (
    <>
      {groupLabel ? <span className={switchStyle('group-label')}>{groupLabel}</span> : null}
      <div className={`${switchStyle({ disabled, type, inactive, checked })} ${mix}`}>
        <input
          type={inputType}
          onChange={handleChange}
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */}
        <label
          className={switchStyle('label')}
          htmlFor={id}
          title={title}
          onFocus={onFocus}
          onBlur={onBlur}
          tabIndex={-1}
          onClick={handleClickOnLabel}
        >
          <span className={switchStyle('icon', { type, checked, disabled, inactive, hidden: hideIcon })} />
          {caption ? <span className={switchStyle('caption')}>{caption}</span> : children}
        </label>
      </div>
    </>
  );
};

Switch.defaultProps = {
  value: undefined,
  caption: '',
  children: null,
  checked: false,
  disabled: false,
  inactive: false,
  hideIcon: false,
  title: '',
  divider: '',
  mix: '',
  onBlur: undefined,
  onFocus: undefined,
  groupLabel: ''
};

Switch.propTypes = {
  type: PropTypes.oneOf([TYPE_CHECKBOX, TYPE_TOGGLE, TYPE_RADIO]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  caption: PropTypes.string,
  children: PropTypes.node,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  inactive: PropTypes.bool,
  hideIcon: PropTypes.bool,
  title: PropTypes.string,
  divider: PropTypes.string,
  mix: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  groupLabel: PropTypes.string
};

export default Switch;
