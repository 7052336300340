import React from 'react';
import useControl from '../../../utility/hooks/useControl';
import { TYPE_PART_CONTROL } from '../../../utility/propTypes';
import OptionImage from '../OptionImage';
import DefaultOption from '../DefaultControl/DefaultOption';
import ControlOptionCaption from '../ControlOptionCaption';

const ImageControl = props => {
  const { control } = props;
  const { options, controlStyle, selectedOption } = useControl(props);

  return (
    <>
      <ControlOptionCaption type={control.type} option={selectedOption} />
      <ul className={controlStyle('options', { type: control.type })}>
        {options.map(optionProps => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <DefaultOption {...optionProps} noCaption>
            <OptionImage option={optionProps.option} />
          </DefaultOption>
        ))}
      </ul>
    </>
  );
};

ImageControl.propTypes = {
  control: TYPE_PART_CONTROL.isRequired
};

export default ImageControl;
