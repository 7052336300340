import { combineReducers } from 'redux';
import plots from './plots';
import project from './project';
import auth from './auth';
import instance from './instance';
import selectedOptions from './selectedOptions';
import configuratorType from './configuratorType';
import parts from './parts';
import seed from './seed';
import theme from './theme';
import settings from './settings';
import geometries from './geometries';
import textures from './textures';
import notifications from './notifications';
import dialog from './dialog';
import camera from './camera';
import ui from './ui';
import loader from './loader';
import cookies from './cookies';

export default combineReducers({
  auth,
  camera,
  configuratorType,
  cookies,
  dialog,
  geometries,
  instance,
  loader,
  notifications,
  parts,
  plots,
  project,
  seed,
  selectedOptions,
  settings,
  textures,
  theme,
  ui
});
