import React from 'react';
import PropTypes from 'prop-types';
import menuStyle from './style';

const MenuHeading = ({ children }) => <h3 className={menuStyle('heading')}>{children}</h3>;

MenuHeading.propTypes = {
  children: PropTypes.node.isRequired
};

export default MenuHeading;
