import { Float32BufferAttribute, BufferGeometry } from 'three';

const makeBufferGeometry = (faces, vertices, normals, vertexUVs, vertexUVs2) => {
  const bufferGeometry = new BufferGeometry();

  bufferGeometry.setIndex(faces);

  bufferGeometry.setAttribute('position', new Float32BufferAttribute(vertices, 3, false));
  bufferGeometry.setAttribute('normal', new Float32BufferAttribute(normals, 3, false));
  bufferGeometry.setAttribute('uv', new Float32BufferAttribute(vertexUVs, 2, false));
  bufferGeometry.setAttribute('uv2', new Float32BufferAttribute(vertexUVs2, 2, false));

  return bufferGeometry;
};

export default makeBufferGeometry;
