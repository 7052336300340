import React, { useCallback } from 'react';
import b from 'b_';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Icon from '../../Icon';
import { controlSelectors } from '../../../modules/model';
import { useTabLink } from '../../TabLink';
import { Button } from '../../Atoms';
import { useActions, useLinkGenerators, useSelectTabWithControl, useTranslate } from '../../../utility/hooks';
import { seedSelectors } from '../../../modules/seed';

import './NavigationButtons.scss';

const navigationButtonsStyle = b.with('navigation-buttons');

const MainNavigationButtons = ({ isMobileNavigation, isChildTabOpened }) => {
  const translate = useTranslate();
  const selectTabWithControl = useSelectTabWithControl();
  const {
    controlList,
    activeControl: currentControl,
    activeIndex: currentIndex
  } = useSelector(controlSelectors.getControlNavigationList);
  const isMainSeed = useSelector(seedSelectors.selectIsMainSeed);
  const {
    actions: [finishAction],
    handleClickOnAction
  } = useActions('finishButton');
  const { getCustomLink } = useLinkGenerators();

  const makeTabLink = useTabLink();
  const buttonType = isMobileNavigation ? 'button' : 'link';

  const selectControlGroup = useCallback(
    e => {
      const iterator = Number(e.currentTarget.dataset.iterator);

      const nextControl = controlList[currentIndex + iterator];

      if (nextControl) {
        selectTabWithControl(nextControl.tabName, nextControl.name);
      } else {
        selectTabWithControl(iterator === 1 ? currentControl?.nextTab : currentControl?.prevTab);
      }
    },
    [controlList, currentControl, currentIndex, selectTabWithControl]
  );
  const onClick = useCallback(
    e => {
      if (isMobileNavigation) {
        selectControlGroup(e);
      }
    },
    [isMobileNavigation, selectControlGroup]
  );

  const isFinishTab =
    isMainSeed &&
    ((isMobileNavigation && currentControl?.isLastControl) ||
      (!isMobileNavigation && currentControl?.isLastControlTab));

  const isBackButtonDisabled = (currentIndex === 0 || !isMobileNavigation) && !currentControl?.prevTab;
  const isNextButtonDisabled = currentControl?.isLastTab && (!isMobileNavigation || currentControl?.isLastControl);

  const isNavigationHidden =
    (isBackButtonDisabled && isNextButtonDisabled) || (!isMobileNavigation && isChildTabOpened);

  return isNavigationHidden ? null : (
    <div className={navigationButtonsStyle()}>
      <Button
        type={buttonType}
        data-iterator={-1}
        onClick={onClick}
        to={makeTabLink(currentControl?.prevTab)}
        rounded
        bold
        size="lg"
        mix={navigationButtonsStyle('button', { back: true })}
        disabled={isBackButtonDisabled}
        color="main-outline"
      >
        <span className={navigationButtonsStyle('icon')}>
          <Icon type="chevron-left" scale={1.4} />
        </span>
        <span className={navigationButtonsStyle('label', { back: true })}>{translate('Back')}</span>
      </Button>
      {isFinishTab && finishAction ? (
        <Button
          type={finishAction.buttonType}
          onClick={handleClickOnAction}
          to={makeTabLink(finishAction.tab)}
          href={getCustomLink(finishAction.link)}
          data-action={finishAction.name}
          rounded
          bold
          size="lg"
          mix={navigationButtonsStyle('button', { next: true, last: true })}
        >
          <span className={navigationButtonsStyle('label', { next: false })}>
            {translate(finishAction.displayName)}
          </span>
          <span className={navigationButtonsStyle('icon')}>
            <Icon type="check-bold" />
          </span>
        </Button>
      ) : (
        <Button
          type={buttonType}
          data-iterator={1}
          onClick={onClick}
          to={makeTabLink(currentControl?.nextTab)}
          rounded
          bold
          size="lg"
          mix={navigationButtonsStyle('button', { next: true, last: isFinishTab })}
          disabled={isNextButtonDisabled}
        >
          <span className={navigationButtonsStyle('label', { next: !isFinishTab })}>
            {translate(isFinishTab ? 'Finish' : 'Next')}
          </span>
          <span className={navigationButtonsStyle('icon')}>
            {isFinishTab ? <Icon type="check-bold" /> : <Icon type="chevron-right" scale={1.4} />}
          </span>
        </Button>
      )}
    </div>
  );
};

MainNavigationButtons.propTypes = {
  isMobileNavigation: PropTypes.bool.isRequired,
  isChildTabOpened: PropTypes.bool.isRequired
};

const ChildNavigationButtons = () => {
  const translate = useTranslate();
  const selectTabWithControl = useSelectTabWithControl();
  const { leftControl, rightControl } = useSelector(controlSelectors.getChildControlNavigationContent);

  const onClick = useCallback(
    e => {
      const iterator = Number(e.currentTarget.dataset.iterator);

      const nextControl = iterator === 1 ? rightControl : leftControl;

      selectTabWithControl(undefined, nextControl);
    },
    [leftControl, rightControl, selectTabWithControl]
  );

  return !leftControl && !rightControl ? null : (
    <div className={navigationButtonsStyle()}>
      <Button
        data-iterator={-1}
        onClick={onClick}
        rounded
        bold
        size="lg"
        mix={navigationButtonsStyle('button', { back: true })}
        disabled={!leftControl}
        color="main-outline"
      >
        <span className={navigationButtonsStyle('icon')}>
          <Icon type="chevron-left" scale={1.4} />
        </span>
        <span className={navigationButtonsStyle('label', { back: true })}>{translate('Back')}</span>
      </Button>
      <Button
        data-iterator={1}
        onClick={onClick}
        rounded
        bold
        size="lg"
        mix={navigationButtonsStyle('button', { next: true })}
        disabled={!rightControl}
      >
        <span className={navigationButtonsStyle('label', { next: true })}>{translate('Next')}</span>
        <span className={navigationButtonsStyle('icon')}>
          <Icon type="chevron-right" scale={1.4} />
        </span>
      </Button>
    </div>
  );
};

const NavigationButtons = ({ isChildTabOpened, isMobileNavigation }) =>
  isChildTabOpened && isMobileNavigation ? (
    <ChildNavigationButtons />
  ) : (
    <MainNavigationButtons isMobileNavigation={isMobileNavigation} isChildTabOpened={isChildTabOpened} />
  );

NavigationButtons.propTypes = {
  isChildTabOpened: PropTypes.bool.isRequired,
  isMobileNavigation: PropTypes.bool.isRequired
};

export default NavigationButtons;
