/** Flattens controls keys by current given key, giving both parent and child control name-treeNames
 *
 * Example: {["0,0,1,"]: {c: "d"}, ["0,0,"]: {a:"b"}} flattened by ["0,"] should be {c: "d", a:"b"}
 */
const flattenControlKeys = (keyMap = [], key = '') =>
  keyMap.reduce((keyResult, [current, currentKeys]) => {
    if (current.indexOf(key) === 0 || key.indexOf(current) === 0) {
      Object.assign(keyResult, currentKeys);
    }

    return keyResult;
  }, {});

// once unit tests are fixed, this function should have a unit test

export default flattenControlKeys;
