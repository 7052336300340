import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import b from 'b_';

import './Button.scss';

const buttonStyle = b.with('button-v2');

const getTypeModifiers = (size, circular, rounded, color, bold, block, regular) => {
  if (circular) {
    return { size: `${size}-circular`, color };
  }

  if (rounded) {
    return { size: `${size}-rounded`, color, bold, block, regular };
  }

  return { size, color, bold, block, regular };
};

const TYPE_BUTTON = 'button';
const TYPE_SUBMIT = 'submit';
const TYPE_LINK = 'link';
const TYPE_EXTERNAL_LINK = 'external-link';

const getResultClassName = (className, mix) => (mix ? `${className} ${mix}` : className);

const Button = React.forwardRef(
  (
    {
      label,
      children,
      onClick,
      size,
      color,
      type,
      rounded,
      block,
      bold,
      circular,
      disabled,
      mix,
      to,
      href,
      regular,
      ...rest
    },
    ref
  ) => {
    const className = getResultClassName(
      buttonStyle(getTypeModifiers(size, circular, rounded, color, bold, block, regular)),
      mix
    );
    const content = circular ? children : label || children;

    if (type === TYPE_LINK) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Link className={className} onClick={onClick} to={to} disabled={disabled} {...rest}>
          {content}
        </Link>
      );
    }

    if (type === TYPE_EXTERNAL_LINK) {
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <a className={className} target="_blank" rel="noopener noreferrer" href={href} {...rest}>
          {content}
        </a>
      );
    }

    return (
      /* eslint-disable react/button-has-type,jsx-a11y/control-has-associated-label, react/jsx-props-no-spreading */
      <button className={className} onClick={onClick} disabled={disabled} type={type} {...rest} ref={ref}>
        {content}
      </button>
    );
  }
);

Button.defaultProps = {
  label: '',
  children: null,
  onClick: () => {},
  size: 'md',
  color: 'main',
  type: TYPE_BUTTON,
  rounded: false,
  block: false,
  bold: false,
  circular: false,
  disabled: false,
  regular: false,
  mix: '',
  to: '',
  href: ''
};

Button.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['lg', 'md', 'xs']),
  color: PropTypes.oneOf([
    'main',
    'main-outline',
    'main-inverse',
    'accent',
    'accent-outline',
    'secondary',
    'secondary-hovered',
    'secondary-borderless',
    'text',
    'text-borderless',
    'icon',
    'link'
  ]),
  type: PropTypes.oneOf([TYPE_BUTTON, TYPE_SUBMIT, TYPE_LINK, TYPE_EXTERNAL_LINK]),
  rounded: PropTypes.bool,
  block: PropTypes.bool,
  bold: PropTypes.bool,
  regular: PropTypes.bool, // needed to reset back to font-weight 400 if something else changes it
  circular: PropTypes.bool,
  disabled: PropTypes.bool,
  mix: PropTypes.string,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  href: PropTypes.string
};

export default Button;
