import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import { actionsSelectors } from '../../modules/settings';
import { dialogActions } from '../../modules/dialog';
import { instanceActions } from '../../modules/instance';
import Heap from '../heap';
import { seedSelectors } from '../../modules/seed';
import { uiActions } from '../../modules/ui';

const ACTION_DIALOG_TYPES = {
  AUTH: 'auth',
  LOGOUT: 'logout',
  PROFILE: 'profile',
  SAVE: 'save',
  SHARE: 'share',
  CONTACT: 'contact',
  PDF: 'pdf',
  OPEN_INSTANCE: 'open-instance',
  EXPORT_MODEL: 'export-model'
};

const DIALOG_ACTIONS = {
  [ACTION_DIALOG_TYPES.AUTH]: dialogActions.openAuthDialog,
  [ACTION_DIALOG_TYPES.LOGOUT]: dialogActions.openLogoutDialog,
  [ACTION_DIALOG_TYPES.PROFILE]: dialogActions.openProfileDialog,

  [ACTION_DIALOG_TYPES.SAVE]: dialogActions.openSaveDialog,
  [ACTION_DIALOG_TYPES.SHARE]: dialogActions.openShareDialog,
  [ACTION_DIALOG_TYPES.CONTACT]: dialogActions.openContactDialog,

  [ACTION_DIALOG_TYPES.PDF]: instanceActions.requestDownloadPdf,

  [ACTION_DIALOG_TYPES.OPEN_INSTANCE]: dialogActions.openInstanceDialog,
  [ACTION_DIALOG_TYPES.EXPORT_MODEL]: dialogActions.openExportModelDialog
};

const FORBIDDEN_DIALOGS_FOR_DEPRECATED_SEED = new Set([
  ACTION_DIALOG_TYPES.SAVE,
  ACTION_DIALOG_TYPES.SHARE,
  ACTION_DIALOG_TYPES.CONTACT,
  ACTION_DIALOG_TYPES.PDF
]);

const sendHeapEvent = (action, group) => {
  let target = action.dialog;

  if (action.type === actionsSelectors.ACTION_TYPES.OPEN_TAB) {
    target = action.tab;
  }

  if (action.type === actionsSelectors.ACTION_TYPES.OPEN_LINK) {
    target = action.link;
  }
  Heap.track('UNIVERSAL_ACTION', { action_type: action.type, action_place: group, action_target: target });
};

const useActions = group => {
  const dispatch = useDispatch();
  const mapActions = useSelector(actionsSelectors.selectMapActions);
  const actions = useSelector(actionsSelectors.getActions);
  const deprecatedSeed = useSelector(seedSelectors.selectIsSeedDeprecated);

  const handleClickOnAction = useCallback(
    e => {
      const { action: actionName } = e.currentTarget.dataset;
      const action = mapActions[actionName];

      if (action?.type === actionsSelectors.ACTION_TYPES.OPEN_DIALOG) {
        if (deprecatedSeed && FORBIDDEN_DIALOGS_FOR_DEPRECATED_SEED.has(action.dialog)) {
          dispatch(uiActions.toggleDeprecationNotification(true));

          return;
        }

        dispatch(DIALOG_ACTIONS[action.dialog](action.requireLogin));
      }

      if (action) {
        sendHeapEvent(action, group);
      }
    },
    [mapActions, deprecatedSeed, dispatch, group]
  );

  return {
    actions: actions[group] || [],
    handleClickOnAction,
    TYPES: actionsSelectors.ACTION_TYPES,
    DIALOGS: ACTION_DIALOG_TYPES
  };
};

export default useActions;
