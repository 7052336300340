import { createSelector } from 'reselect';
import numberformat from 'number-format.js';
import * as settingsSelectors from '../settings/settingsSelectors';
import * as interfaceSelectors from '../settings/interfaceSelectors';
import * as modelSettingsSelectors from '../settings/modelSettingsSelectors';
import * as instanceSelectors from '../instance/instanceSelectors';
import * as seedSelectors from '../seed/seedSelectors';
import { getPartNodesToRender, selectMainPartNodesToRender } from '../model/nodesSelectors';
import { selectedOptionsSelectors } from '../selectedOptions';
import videoUrlParser from '../../utility/videoUrlParser';
import { INDEX_TAB, REVIEW_TAB, SUMMARY_TAB } from './tabNames';
// selectors straigth from state are in a different file to be able to import them in nodesSelectors
import {
  selectSelectedLanguage,
  selectControlsPanelVisible,
  selectSelectedTabName,
  selectSelectedControlGroupName,
  selectViewerInteracted,
  selectIsMobileNavigation,
  selectIsMobileViewport,
  selectMenuVisible,
  selectNotFoundPageVisible,
  selectNewInstanceId,
  selectLocalSummaryGallery,
  selectRenderingSummaryGallery,
  selectSelectedParentControls,
  selectUtmParameters,
  selectUiMode,
  selectIsFullscreen,
  selectIsPartInteractionEnabled,
  selectIsDeprecationNotificationVisible,
  selectPreviousParentControl,
  selectErrorPageState
} from './uiStateSelectors';

export {
  selectSelectedLanguage,
  selectControlsPanelVisible,
  selectSelectedTabName,
  selectSelectedControlGroupName,
  selectViewerInteracted,
  selectIsMobileNavigation,
  selectIsMobileViewport,
  selectMenuVisible,
  selectNotFoundPageVisible,
  selectNewInstanceId,
  selectLocalSummaryGallery,
  selectRenderingSummaryGallery,
  selectSelectedParentControls,
  selectUtmParameters,
  selectUiMode,
  selectIsFullscreen,
  selectIsPartInteractionEnabled,
  selectIsDeprecationNotificationVisible,
  selectPreviousParentControl,
  selectErrorPageState
};

export const DEFAULT_PRICE_SCHEME = 'default';

const DEFAULT_PRICE_SETTINGS = {
  name: 'default',
  currencyFormat: '###,###.',
  currency: '€',
  priceRounding: 1,
  caveat: '',
  displayName: 'Price',
  description: '',
  disabled: false
};

/** Maps schemes names to object */
const selectValidPriceSchemes = createSelector([settingsSelectors.selectPriceSchemes], schemes =>
  schemes.reduce((result, scheme) => {
    // eslint-disable-next-line no-param-reassign
    result[scheme.name] = true;

    return result;
  }, {})
);

/** Find last price scheme that is set by getPartNodesToRender */
export const selectCurrentPriceSchemeName = createSelector(
  [getPartNodesToRender, selectValidPriceSchemes],
  (nodes, validPriceSchemes) =>
    nodes.reduce((result, node) => {
      const scheme = node?.Parameters?.$priceScheme;

      if (scheme && validPriceSchemes[scheme]) {
        return scheme;
      }

      return result;
    }, DEFAULT_PRICE_SCHEME)
);

export const selectCurrentPriceScheme = createSelector(
  [settingsSelectors.selectPriceSchemes, selectCurrentPriceSchemeName],
  (schemes = [], name) => {
    return schemes.find(scheme => name === scheme.name);
  }
);

export const selectCurrentLanguageWithDictionary = createSelector(
  [selectSelectedLanguage, instanceSelectors.selectInstanceLanguage, settingsSelectors.selectAvailableTranslations],
  (selectedLanguage = '', instanceLanguage = '', availableTranslations) => {
    const selectedLang = availableTranslations.find(
      ({ name }) => name.toLowerCase() === selectedLanguage.toLowerCase()
    );

    if (selectedLang) {
      return selectedLang;
    }

    const instanceLang = availableTranslations.find(
      ({ name }) => name.toLowerCase() === instanceLanguage.toLowerCase()
    );

    if (instanceLang) {
      return instanceLang;
    }

    if (availableTranslations.length > 0) {
      return availableTranslations[0];
    }

    return { name: '' };
  }
);

export const selectCurrentLanguage = createSelector([selectCurrentLanguageWithDictionary], ({ name }) => name);

export const selectTranslateFn = createSelector([selectCurrentLanguageWithDictionary], ({ dictionary }) => {
  if (!dictionary) {
    return word => word;
  }

  return word => dictionary[word] || word;
});

export const selectPriceSettingsByPriceScheme = createSelector(
  [
    settingsSelectors.selectUseMultiplePriceSchemes,
    settingsSelectors.selectDefaultPriceSettings,
    settingsSelectors.selectPriceSchemes,
    selectCurrentPriceSchemeName
  ],
  (useMultiplePriceSchemes, defaultPriceSettings, schemes, schemeName) => {
    // first decide which scheme to use

    let scheme = defaultPriceSettings;

    if (useMultiplePriceSchemes) {
      scheme = schemes.find(({ name }) => name === schemeName) || {};
    }

    // prepare variables to be used in formatter functions
    const currencyFormat = scheme.currencyFormat || DEFAULT_PRICE_SETTINGS.currencyFormat;
    const priceRounding = scheme.priceRounding || DEFAULT_PRICE_SETTINGS.priceRounding;

    // currency format is always defined - either in scheme or default values so no logic around
    // identity formatter
    const currencyFormatter = value => (!value || value === 0 ? '0' : numberformat(currencyFormat, value));

    const priceFormatter = price =>
      currencyFormatter(price ? Math.round(price / priceRounding) * priceRounding : price);

    return {
      caveat: scheme.caveat || DEFAULT_PRICE_SETTINGS.caveat,
      priceRounding,
      currency: scheme.currency || DEFAULT_PRICE_SETTINGS.currency,
      currencyFormat,
      currencyFormatter,
      priceFormatter,
      scheme,
      schemeName
    };
  }
);

export const selectIntro = createSelector(
  [settingsSelectors.selectSettingsIntro, selectCurrentLanguage],
  (intro = [], language) => {
    // find either correct language or the first one
    return intro.find(introElement => introElement.language === language) || intro[0] || {};
  }
);

export const selectSummaryPageSettings = createSelector(
  [settingsSelectors.selectSettings],
  settings => settings.summary || {}
);

export const selectIntroContent = createSelector([selectIntro], intro => intro.contentHtml);
export const selectIntroTitle = createSelector([selectIntro], intro => intro.title);

export const selectUiImages = createSelector(
  [interfaceSelectors.selectInterfaceSettings, modelSettingsSelectors.selectImageUrlGenerator],
  ({ headerIcon, favicon }, generateImageURL) => ({
    headerLogo: headerIcon ? generateImageURL(headerIcon) : undefined,
    favicon: favicon ? generateImageURL(favicon) : '/favicon.png'
  })
);

const makeImageURLs = (images = [], generateImageURL) =>
  images.map(({ fileName, isPanorama }) => ({ original: generateImageURL(fileName), isPanorama }));

export const selectIntroImages = createSelector(
  [selectIntro, modelSettingsSelectors.selectImageUrlGenerator],
  (intro = {}, generateImageURL) => makeImageURLs(intro.images, generateImageURL)
);

export const selectIntroVideo = createSelector([selectIntro], intro => videoUrlParser(intro.introVideo));

export const selectSummaryImages = createSelector(
  [selectSummaryPageSettings, modelSettingsSelectors.selectImageUrlGenerator],
  (settings = {}, generateImageURL) => makeImageURLs(settings.gallery, generateImageURL)
);

/* tab related selectors */

// utility function to sort tabs according to their tabIndex, used by selectVisibleTabs
const sortTabs = (a, b) => {
  const aIndex = a.tabIndex || 0; // 0 is because older tabs dont have tab indices
  const bIndex = b.tabIndex || 0;

  if (aIndex > bIndex) return 1;

  if (aIndex < bIndex) return -1;

  return 0;
};

// select tabs that are visible, sort them by tabIndex
// Also used in price selectors
export const selectVisibleTabs = createSelector([selectMainPartNodesToRender], parts =>
  parts
    .reduce((result, part) => {
      if (Array.isArray(part.Phases?.Object)) {
        part.Phases.Object.forEach(tab =>
          result.push({ ...tab, childControlsTree: part.Parameters.$childControlsTreeMap, key: part.key })
        );
      }

      return result;
    }, [])
    .sort(sortTabs)
);

// this is used by headers
// Injects about and summary tab

const DEFAULT_VIEW_NAME = 'default';

export const getTabs = createSelector(
  [selectVisibleTabs, interfaceSelectors.selectHiddenTabs],
  (visibleTabs, hiddenTabs) => {
    let reviewTab = { name: REVIEW_TAB, displayName: 'Review', key: 'review', controls: [] };

    const { modifiedVisibleTabs, views } = visibleTabs.reduce(
      (result, tab) => {
        const controls = tab.controls || [];

        result.modifiedVisibleTabs.push({
          name: tab.name,
          displayName: tab.displayName,
          icon: tab.icon,
          controls,
          view: tab.openView,
          hideViewPickerButtons: tab.hideViewPickerButtons,
          summaryDisabled: tab.summaryDisabled,
          isEmptyTab: controls.length === 0,
          childControlsTree: tab.childControlsTree,
          key: tab.key
        });

        if (tab.openView) {
          result.views.push(tab.openView);
        }

        return result;
      },
      { modifiedVisibleTabs: [], views: [] }
    );
    // find if review is injected
    const reviewIndex = modifiedVisibleTabs.findIndex(tab => tab.name === REVIEW_TAB);

    const reviewTabView = views.find(view => view === DEFAULT_VIEW_NAME) || views[0];

    if (reviewTabView) {
      reviewTab.view = reviewTabView;
    }

    if (reviewIndex !== -1) {
      reviewTab = { ...reviewTab, ...modifiedVisibleTabs[reviewIndex], isEmptyTab: false };
      modifiedVisibleTabs.splice(reviewIndex, 1);
    }

    if (!hiddenTabs[INDEX_TAB]) {
      modifiedVisibleTabs.unshift({ name: INDEX_TAB, displayName: 'About', controls: [], key: 'index' });
    }

    if (!hiddenTabs[REVIEW_TAB]) {
      modifiedVisibleTabs.push(reviewTab);
    }

    if (!hiddenTabs[SUMMARY_TAB]) {
      // summaryTab gets view from review as well so that the cameraview would not be '' if in summary
      modifiedVisibleTabs.push({
        name: SUMMARY_TAB,
        displayName: 'Summary',
        controls: [],
        view: reviewTabView,
        key: 'summary'
      });
    }

    return modifiedVisibleTabs.map(tab => (tab.view ? tab : { ...tab, view: reviewTabView }));
  }
);

export const selectCurrentTabIndex = createSelector([selectSelectedTabName, getTabs], (selectedTabName, tabs) => {
  const index = tabs.findIndex(visibleTab => visibleTab.name === selectedTabName);

  return index !== -1 ? index : 0;
});

export const selectCurrentTab = createSelector([selectCurrentTabIndex, getTabs], (index, tabs) => tabs[index] || {});

export const selectCurrentTabName = createSelector([selectCurrentTab], currentTab => currentTab.name);
export const selectFirstTabName = createSelector([getTabs], tabs => tabs[0]?.name);
export const selectCurrentTabDisplayName = createSelector([selectCurrentTab], currentTab => currentTab.displayName);
export const selectCurrentTabSummaryDisabled = createSelector([selectCurrentTab], currentTab =>
  Boolean(currentTab.summaryDisabled)
);
export const selectCurrentTabViewButtonsHidden = createSelector([selectCurrentTab], currentTab =>
  Boolean(currentTab.hideViewPickerButtons)
);
export const selectCurrentTabView = createSelector([selectCurrentTab], currentTab => currentTab.view || '');

export const getIsIndexTab = createSelector([selectCurrentTabName], selectedTab => selectedTab === INDEX_TAB);
export const getIsSummaryTab = createSelector([selectCurrentTabName], selectedTab => selectedTab === SUMMARY_TAB);
export const getIsReviewTab = createSelector([selectCurrentTabName], selectedTab => selectedTab === REVIEW_TAB);

export const getIsShareImageReady = createSelector([instanceSelectors.getInstanceSnapshot], snapshot =>
  Boolean(snapshot)
);

export const selectSummaryGallery = createSelector(
  [selectSummaryImages, selectLocalSummaryGallery],
  (summaryImages, localSummaryGallery) => [...localSummaryGallery.map(url => ({ original: url })), ...summaryImages]
);

export const selectIntroOrSummaryGallery = createSelector(
  [getIsSummaryTab, selectIntroImages, selectSummaryGallery, interfaceSelectors.selectIsGalleryHidden],
  (isSummaryTab, introImages, summaryImages, isGalleryHidden) => {
    if (isSummaryTab && summaryImages.length) {
      return summaryImages;
    }

    return isGalleryHidden ? false : introImages;
  }
);

export const getIsUnsavedIndicatorVisible = createSelector(
  [instanceSelectors.getIsOwner, seedSelectors.selectIsMainSeed, selectedOptionsSelectors.selectInstanceUnsaved],
  (isOwner, isMainSeed, unsaved) => (!isOwner || !isMainSeed) && unsaved
);

export const getIsSummaryGalleryLoaderActive = createSelector(
  [selectRenderingSummaryGallery, getIsSummaryTab],
  (isRendering, isSummaryTab) => isRendering && isSummaryTab
);
