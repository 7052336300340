import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TYPE_PART_CONTROL } from '../../../../utility/propTypes';
import { CONTROL_TYPES } from '../../../../utility/controlDefinitions';
import BulkMaterials from '../../BulkMaterials';
import Slider from '../../Slider';
import Dropdown from '../../Dropdown';
import Toggle from '../../Toggle';
import ImageControl from '../../ImageControl';
import ColumnControl from '../../ColumnControl';
import DefaultControl from '../../DefaultControl';
import EmptyControl from '../../EmptyControl';
import ControlTags from './ControlTags';

const CONTROLS_MAP = {
  [CONTROL_TYPES.MATERIALS_BULK]: BulkMaterials,
  [CONTROL_TYPES.SLIDER]: Slider,
  [CONTROL_TYPES.DROPDOWN]: Dropdown,
  [CONTROL_TYPES.TOGGLE]: Toggle,
  [CONTROL_TYPES.IMAGES]: ImageControl,
  [CONTROL_TYPES.MATERIALS]: ImageControl,
  [CONTROL_TYPES.COLUMN]: ColumnControl,
  [CONTROL_TYPES.COLUMN_TWO]: ColumnControl,
  [CONTROL_TYPES.COLUMN_THREE]: ColumnControl,
  [CONTROL_TYPES.RADIO]: DefaultControl,
  [CONTROL_TYPES.EMPTY]: EmptyControl
};

const ControlOptions = ({ control, onOptionSelect, onShowExtraInfo }) => {
  const { tags = {} } = control;
  const hasTags = (tags.list || []).length !== 0;
  const [activeTags, setActiveTags] = useState(hasTags ? undefined : []);

  const ControlComponent = CONTROLS_MAP[control.type] || CONTROLS_MAP[CONTROL_TYPES.RADIO];

  return (
    <>
      {hasTags ? <ControlTags control={control} onChangeActiveTags={setActiveTags} /> : null}
      {hasTags && !Array.isArray(activeTags) ? null : (
        <ControlComponent
          control={control}
          onOptionSelect={onOptionSelect}
          onOpenExtraInfo={onShowExtraInfo}
          activeTags={activeTags}
        />
      )}
    </>
  );
};

ControlOptions.propTypes = {
  control: TYPE_PART_CONTROL.isRequired,
  onOptionSelect: PropTypes.func.isRequired,
  onShowExtraInfo: PropTypes.func.isRequired
};

export default ControlOptions;
