import { Vector3, Vector2 } from 'three';
import calculateSunPosition from './calculateSunPosition';
import { SUN_SHADOW_METRIC, SUN_SHADOW_IMPERIAL } from './viewerSettings';

export const LIGHTS = {
  AMBIENT: 'ambient',
  DIRECTIONAL: 'directional',
  HEMI: 'hemi',
  POINT: 'point',
  RECT: 'rect',
  SPOT: 'spot'
};

export const LIGHTS_LIST = Object.values(LIGHTS);

const calculateReflectPosition = position => ({ x: -1 * position.x, y: -1 * position.y, z: 0.3 * position.z });

const getShadowCamera = type => (type === 'imperial' ? SUN_SHADOW_IMPERIAL : SUN_SHADOW_METRIC);

const reusablePositonVector = new Vector3();

export const getLightsForSun = (sunlight, modelUnits, cameraView = {}) => {
  if (!sunlight) return null;
  const {
    altitude,
    azimuth,
    color,
    intensity,
    castShadow,
    name,
    shadowResolution = 4096,
    shadowBoxMultiplier = 1
  } = sunlight;
  const { sunModel, shadowCamera } = getShadowCamera(modelUnits);
  const { distance } = sunModel;

  const position = calculateSunPosition(azimuth, altitude, distance * shadowBoxMultiplier);

  const lookAtVector = new Vector3();

  lookAtVector.copy(position);
  lookAtVector.negate();

  // for relative views the base position will be translated to parent part
  const { isRelative = false, parentMatrix } = cameraView;

  if (isRelative && parentMatrix) {
    reusablePositonVector.setFromMatrixPosition(parentMatrix);
    position.add(reusablePositonVector);
  }

  const sun = {
    name,
    type: 'directional',
    color,
    intensity,
    castShadow,
    depthBias: 0.01,
    shadowBias: -0.001,
    shadowMapSize: new Vector2(shadowResolution, shadowResolution),

    // near will stay where it is, multiplier is applied to radius instead, which is used to calculate all the rest
    // sun(near) - RADIUS - 0,0,0 - RADIUS (far)
    // left RADIUS 0,0,0 RADIUS (right)
    shadowCamera: {
      ...shadowCamera,
      left: -distance * shadowBoxMultiplier,
      right: distance * shadowBoxMultiplier,
      top: distance * shadowBoxMultiplier,
      bottom: -distance * shadowBoxMultiplier,
      far: distance * shadowBoxMultiplier * 2
    },
    position,
    lookAt: lookAtVector
  };

  const through = {
    name: `${name}-through`,
    type: 'directional',
    color,
    intensity: 0.2,
    depthBias: 0.01,
    position,
    lookAt: lookAtVector
  };

  const reflectPosition = new Vector3();

  reflectPosition.copy(calculateReflectPosition(position));
  const reflectLookAt = reflectPosition.clone().negate();
  const reflect = {
    name: `${name}-reflect`,
    type: 'directional',
    color,
    position: reflectPosition,
    intensity: intensity / 2,
    lookAt: reflectLookAt
  };

  return [sun, through, reflect];
};
