import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import RouterInterceptor from './RouterInterceptor';
import Helmet from './components/Helmet';
import Toasts from './components/Toasts/Toasts';
import Routes from './Routes';
import { useCheckInitialQueryParameters, useUtmParameters } from './utility/hooks';
import ackeeTracker from './utility/ackeeTracker';

const useAckeePageTracker = () => {
  const location = useLocation();
  const recordRef = useRef(null);

  useEffect(() => {
    recordRef.current?.stop();

    recordRef.current = ackeeTracker.record();
  }, [location]);
};

const AppBody = () => {
  const { checked: queryParametersChecked, parameters } = useCheckInitialQueryParameters();

  useUtmParameters(parameters);
  useAckeePageTracker();

  return queryParametersChecked ? (
    <>
      <RouterInterceptor />
      <Helmet />
      <Toasts />
      <Routes />
    </>
  ) : null;
};

export default AppBody;
