/** Supported control types */
export const CONTROL_TYPES = {
  RADIO: 'option',
  TOGGLE: 'toggle',
  SLIDER: 'slider',
  DROPDOWN: 'dropdown',
  COLUMN: 'column',
  COLUMN_TWO: 'twoColumn',
  COLUMN_THREE: 'threeColumn',
  MATERIALS: 'controlMaterials',
  MATERIALS_BULK: 'bulkMaterials',
  IMAGES: 'controlImages',
  VAR_SELECTED_CONTROL: 'varSelectedControl',
  VAR_SELECTED_TAB: 'varSelectedTab',
  VAR_SELECTED_VIEW: 'varSelectedView',
  EMPTY: 'empty'
};

export const CONTROL_TAGS = {
  ALL: 'All',
  OTHER: 'Other'
};

export const TRANSFORM_SUFFIX = '#transform';
export const INSTANCE_LOADER_SUFFIX = '#instance';

/** List of supported control types */
export const CONTROL_TYPES_LIST = Object.values(CONTROL_TYPES);
