import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MenuSection } from '../../Menu';
import { useTranslate, useFormState } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import { Field, Button } from '../../Atoms';
import { loaderSelectors } from '../../../modules/loader';
import menuStyle from '../../Menu/style';
import { instanceActions } from '../../../modules/instance';

const SendToEmailMenu = () => {
  const translate = useTranslate();
  const isSending = useSelector(loaderSelectors.selectIsInstanceSending);

  const { handleFormFieldChange, formState } = useFormState({ email: '' });

  const dispatch = useDispatch();

  const handleSend = useCallback(
    e => {
      e.preventDefault();

      if (formState.email) {
        dispatch(instanceActions.sendInstanceToEmail(formState.email));
      }
    },
    [dispatch, formState]
  );

  const sendDisabled = useMemo(() => isSending || !formState.email, [formState.email, isSending]);

  return (
    <MenuContent>
      <form onSubmit={handleSend}>
        <MenuSection type="save-form" heading={translate('Send to my email')}>
          <Field
            label={translate('Your email')}
            id="send-form-email"
            name="email"
            type="email"
            onChange={handleFormFieldChange}
            value={formState.email}
            required
            mix={menuStyle('field', { type: 'orphan-email' })}
            description={translate(
              'We don’t store this email, it will only be used to send you the link to your design'
            )}
          />
        </MenuSection>
        <MenuSection type="save-submit">
          <Button type="submit" color="accent" block disabled={sendDisabled}>
            {translate(isSending ? 'Sending...' : 'Send')}
          </Button>
        </MenuSection>
      </form>
    </MenuContent>
  );
};

export default SendToEmailMenu;
