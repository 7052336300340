import './utility/polyfills'; //  before anything else

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import configureStore from './configureStore';

import 'react-image-gallery/styles/scss/image-gallery.scss';

import './style/index.scss';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <CookiesProvider>
      <HelmetProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </HelmetProvider>
    </CookiesProvider>
  </Provider>,
  document.getElementById('root')
);
