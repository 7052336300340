import { all, put, takeEvery } from 'redux-saga/effects';
import * as notificationsActions from './notificationsActions';

function* doShowNotificationSaga({ meta = {}, error }) {
  if (error) {
    const message = meta.message || error.message || 'Unknown error';

    return yield put(notificationsActions.showErrorNotification(message));
  }

  return yield put(notificationsActions.showSuccessNotification(meta.message));
}

function* watchShowNotificationSaga() {
  yield takeEvery(action => action.meta && action.meta.notification, doShowNotificationSaga);
}

export default function* moduleSaga() {
  yield all([watchShowNotificationSaga()]);
}
