import { useCallback } from 'react';
import { optionStyle } from '../../components/ControlsPanel/Control/style';

const useControlOption = ({ control, option, onOptionSelect, onOpenExtraInfo }) => {
  const selectHandler = useCallback(() => {
    if (!option.locked) {
      onOptionSelect(control, option);

      if (control.bindExtraInfo && option.extraInfoCollection) {
        onOpenExtraInfo(option.extraInfoCollection, control, option);
      }
    }
  }, [control, onOpenExtraInfo, onOptionSelect, option]);

  return { selectHandler, optionStyle };
};

export default useControlOption;
