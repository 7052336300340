import { initialStateActions } from '../initialState';
import { convertArrayToMap } from '../../utility';
import * as seedActions from './seedActions';

const INITIAL_SEED = 'INITIAL';

export const getSeedInitialState = (seed = { _id: INITIAL_SEED }, childSeeds = []) => ({
  mainId: seed._id,
  currentId: seed._id,
  list: { [seed._id]: seed, ...convertArrayToMap(childSeeds) },
  history: []
});

const seedReducer = (state = getSeedInitialState(), { type, payload = {} }) => {
  switch (type) {
    case initialStateActions.FETCH_INITIAL_STATE__SUCCESS:
    case seedActions.MOCK_FETCH_SEED__SUCCESS: {
      const { seed, childSeeds } = payload;

      return { ...getSeedInitialState(seed, childSeeds), previousMainId: state.mainId };
    }
    case seedActions.SET_CURRENT_SEED: {
      const { lastTabName, lastControl, lastParentControls, seedId } = payload;

      return {
        ...state,
        currentId: seedId,
        history: [...state.history, { seedId: state.currentId, lastTabName, lastControl, lastParentControls }]
      };
    }
    case seedActions.CLOSE_CURRENT_SEED: {
      const newHistory = [...state.history];
      const lastSeed = newHistory.pop();

      return { ...state, currentId: lastSeed.seedId, history: newHistory };
    }
    default:
      return state;
  }
};

export default seedReducer;
