import React, { useContext, useEffect, useRef, useCallback } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Panzoom from '@panzoom/panzoom';
import { useDoubleTap } from 'use-double-tap/dist';
import Button from '../Button';
import Icon from '../../Icon';
import IsMobileContext from '../../IsMobileContext';
import { useEscListener, useEventListener } from '../../../utility/hooks';
import Panorama from '../Panorama';
import galleryStyle from './style';
import { IMAGE_LIST_PROP_TYPE } from './galleryPropTypes';
import GalleryNavigationControls from './GalleryNavigationControls';

const MIN_SCALE = 0.5;

const GalleryFullscreen = ({ images, activeIndex, isActive, onClose, onPrevSlide, onNextSlide }) => {
  const isMobile = useContext(IsMobileContext);

  const imgRef = useRef();
  const panzoom = useRef();
  const currentImage = images[activeIndex];

  const panZoomChange = useCallback(
    e => {
      if (e.detail.scale < MIN_SCALE) {
        onClose();
      }
    },
    [onClose]
  );

  const onWheel = useCallback(
    e => {
      if (!isActive) {
        return;
      }

      e.preventDefault();

      if (e.deltaY < 0) {
        panzoom.current.zoomIn();
      } else {
        panzoom.current.zoomOut();
      }
    },
    [isActive]
  );

  const panZoomEnd = useCallback(e => {
    if (e.detail.scale < MIN_SCALE) {
      panzoom.current.reset();
    }
  }, []);

  useEffect(() => {
    if (imgRef.current && !panzoom.current) {
      const options = { maxScale: 3, step: 0.2 };

      if (!isMobile.phone && !isMobile.tablet) {
        options.minScale = 1;
      }

      if (currentImage.isPanorama) {
        options.disablePan = true;
        options.disableZoom = true;
      }

      panzoom.current = Panzoom(imgRef.current, options);
    }

    return () => {
      panzoom.current = undefined;
    };
  }, [isMobile.phone, isMobile.tablet, currentImage]);

  useEffect(() => {
    if (!isActive && panzoom.current) {
      panzoom.current.reset();
    }
  }, [isActive]);

  useEventListener('wheel', onWheel, imgRef.current?.parentElement);
  useEventListener('panzoomchange', panZoomChange, imgRef.current);
  useEventListener('panzoomend', panZoomEnd, imgRef.current);

  useEscListener(onClose);

  const doubleTapProps = useDoubleTap(onClose);

  return ReactDOM.createPortal(
    <div className={galleryStyle('fullscreen', { active: isActive })}>
      <Button
        circular
        color="text"
        size="lg"
        onClick={onClose}
        mix={galleryStyle('button', { type: 'close-fullscreen' })}
      >
        <Icon type="close" scale={1.4} />
      </Button>
      {/* eslint-disable react/jsx-props-no-spreading */}
      {currentImage.isPanorama ? (
        <Panorama src={currentImage.original} fullscreen {...doubleTapProps} ref={imgRef} />
      ) : (
        <img
          src={currentImage.original}
          alt="img"
          className={galleryStyle('fullscreen-image')}
          ref={imgRef}
          {...doubleTapProps}
        />
      )}

      <div className={galleryStyle('zoom')}>
        <Button
          onClick={panzoom.current?.zoomIn}
          circular
          color="main-outline"
          mix={galleryStyle('zoom-button', { type: 'plus' })}
          disabled={currentImage.isPanorama}
        >
          <Icon type="plus-circle" scale={1.4} />
        </Button>
        <Button
          onClick={panzoom.current?.zoomOut}
          circular
          color="main-outline"
          mix={galleryStyle('zoom-button', { type: 'minus' })}
          disabled={currentImage.isPanorama}
        >
          <Icon type="minus-circle" scale={1.4} />
        </Button>
      </div>
      <GalleryNavigationControls
        onNextSlide={onNextSlide}
        onPrevSlide={onPrevSlide}
        activeIndex={activeIndex}
        images={images}
        type="fullscreen"
      />
    </div>,
    document.getElementById('root')
  );
};

GalleryFullscreen.propTypes = {
  images: IMAGE_LIST_PROP_TYPE.isRequired,
  activeIndex: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  onPrevSlide: PropTypes.func.isRequired,
  onNextSlide: PropTypes.func.isRequired
};

export default GalleryFullscreen;
