import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { MenuSection } from '../../Menu';
import { useTranslate } from '../../../utility/hooks';
import MenuContent from '../../Menu/MenuContent';
import SocialAuth from '../../Auth/SocialAuth';
import { dialogActions } from '../../../modules/dialog';
import { DIALOGS } from '../../../utility/dialogs';
import { Button } from '../../Atoms';
import EmailLogin from './EmailLogin';

const LoginMenu = ({ title, signUpTitle }) => {
  const translate = useTranslate();

  const dispatch = useDispatch();
  const handleOpenRegisterMenu = useCallback(() => {
    dispatch(dialogActions.showDialog(DIALOGS.REGISTER, { signUpTitle, loginTitle: title }));
  }, [dispatch, signUpTitle, title]);

  const handleResetPassword = useCallback(() => {
    dispatch(dialogActions.showDialog(DIALOGS.FORGOT_PASSWORD));
  }, [dispatch]);

  return (
    <MenuContent>
      <MenuSection type="login-header" heading={translate(title)} description={translate('Use social media accounts')}>
        <SocialAuth />
      </MenuSection>
      <MenuSection type="login-email" description={translate('Or use email and password')}>
        <EmailLogin />
      </MenuSection>
      <MenuSection type="login-register" description={translate("Don't have an account?")}>
        <Button color="text-borderless" block onClick={handleOpenRegisterMenu} bold>
          {translate('Register')}
        </Button>
      </MenuSection>
      <MenuSection type="login-reset" description={translate('Forgot your password?')}>
        <Button color="text-borderless" block onClick={handleResetPassword} bold>
          {translate('Reset password')}
        </Button>
      </MenuSection>
    </MenuContent>
  );
};

LoginMenu.propTypes = {
  title: PropTypes.string,
  signUpTitle: PropTypes.string
};

LoginMenu.defaultProps = {
  title: 'Log in',
  signUpTitle: undefined
};
export default LoginMenu;
