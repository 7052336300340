import { createSelector } from 'reselect';
import { convertArea } from '../../utility';
import { getPartAreas } from '../geometries/geometryStore';
import * as partsSelectors from '../parts/partsSelectors'; // direct import to avoid circular dependencies
import * as seedSelectors from '../seed/seedSelectors';
import * as settingsSelectors from '../settings/settingsSelectors';
import * as modelSettingsSelectors from '../settings/modelSettingsSelectors';
import * as geometriesSelectors from '../geometries/geometriesSelectors';
import * as selectedOptionsSelectors from '../selectedOptions/selectedOptionsSelectors';
import * as uiSelectors from '../ui/uiSelectors';
import { CONTROL_TYPES, CONTROL_TAGS } from '../../utility/controlDefinitions';
import flattenControlKeys from '../../utility/flattenControlKeys';
import { MODEL_UNITS } from '../../utility/viewerSettings';
import { getPartNodesToRender, getControls } from './nodesSelectors';

/** Returns price variables as an object {[code]: priceCode,} */
const getPriceVariables = createSelector(
  [settingsSelectors.selectVariablePricesEnabled, seedSelectors.selectSeed, uiSelectors.selectCurrentPriceSchemeName],
  (useVariablePrices, seed, priceScheme) => {
    if (useVariablePrices && Array.isArray(seed.prices)) {
      return seed.prices.reduce((result, code) => {
        const { scheme = uiSelectors.DEFAULT_PRICE_SCHEME } = code;

        if (scheme === priceScheme) {
          // eslint-disable-next-line no-param-reassign
          result[code.code] = code;
        }

        return result;
      }, {});
    }

    return {};
  }
);

// option.price or option.priceCode
const getOptionPrice = (option, priceCodes) => {
  let price = option.price || null;

  if (priceCodes && option.priceCode) {
    const code = priceCodes[option.priceCode];

    price = code ? code.price : price;
  }

  return parseFloat(price) || null;
};

const getOptionPart = (partsMap, control, option) => {
  const parameter = control.parameter || control.name;
  const { name: optionName } = option;

  return partsMap[parameter]?.list[optionName];
};

/*
  Function to reduce material prices from option materials
  Only the first material counts
*/
const getOptionMaterialPrice = (materials = [], priceCodes = {}) =>
  materials.reduce((result, material) => {
    // only first found price counts
    if (result) return result;

    // ignore local materials for now
    // @todo - needs  to be implemented eventually
    if (material.isLocal) return result;

    if (material.priceCode_psq) {
      const priceCode = priceCodes[material.priceCode_psq];

      if (priceCode && priceCode.price !== undefined) {
        return priceCode.price;
      }
    }

    return material.price_psq || result;
  }, null);

const getOptionMaterialData = (partsToRender, option, priceCodes, modelUnits) => {
  if (option && Array.isArray(option.material) && option.material.length > 0) {
    // material price per sq unit
    const materialPrice = getOptionMaterialPrice(option.material, priceCodes); // null

    const optionPriceCode = priceCodes[option.priceCode_psq];
    const optionAreaPrice =
      optionPriceCode && optionPriceCode.price !== undefined ? optionPriceCode.price : option.price_psq;
    // materialPsq is more important than option, calculate this only if material price wasnt found
    // finalPrice is price per sq unit still
    const finalPrice = materialPrice !== null ? materialPrice : optionAreaPrice;

    if (finalPrice !== undefined) {
      // sum all areas corresponding to layer or material in rendered parts and multiply by defined price.
      // take into account all rendered parts
      const area = partsToRender.reduce((totalArea, part) => {
        const partAreas = getPartAreas(part._id);

        const localArea = option.material.reduce((result, material) => {
          // do not take local materials into account since this creates new issues
          // @TODO - it needs to be implemented eventually though
          if (material.isLocal) return result;

          const materialArea = partAreas.byLayers[material.layer] || partAreas.byMaterials[material.original] || 0;

          return result + materialArea;
        }, 0);

        return totalArea + localArea;
      }, 0);

      const convertedArea = convertArea({ area, modelUnits });

      return {
        materialPrice: finalPrice * convertedArea.area,
        materialTotalArea: convertedArea.roundedArea
      };
    }
    /*
     option.material is array of {
      original: "", -- this is the reference for getting area by material
      apply: "",
      price_psq: 0,
      priceCode_psq: "",
      layer: ""  -- for getting area by layer not material
    }
*/
  }

  return {
    materialPrice: null,
    materialTotalArea: null
  };
};

// recursive function to loop part positions
const loopPositions = (part, partsMap, arrayToFill) => {
  if (part.Position) {
    part.Position.forEach(({ Reference }) => {
      if (partsMap[Reference]?.count === 1) {
        arrayToFill.push(partsMap[Reference].default); // add to static children table
        loopPositions(partsMap[Reference].default, partsMap, arrayToFill); // maybe they also have such children
      }
    });
  }
};

const getNodeStaticTree = (part, partsMap) => {
  const nodes = [part];

  loopPositions(part, partsMap, nodes); // start with option's part and fill children's array

  return nodes;
};

// function to add together prices for nodes
const calculatePricePerUnit = (nodes = [], priceCodes) =>
  nodes.reduce((result, node) => {
    const priceCode = node?.Info?.Price?.ppuPart?.priceCode;

    if (priceCode && priceCodes) {
      const code = priceCodes[priceCode];

      if (code) {
        return result + Number(code.price);
      }
    }
    const price = node?.Info?.Price?.ppuPart?.price;

    if (price) return result + Number(price);

    return result;
  }, null);

// function to add together ppsqm prices for nodes
const calculatePricePerSquareUnit = (nodes = [], ppsqmLayers = [], priceCodes = {}, modelUnits = MODEL_UNITS.METRIC) =>
  nodes.reduce((result, node) => {
    const partAreas = getPartAreas(node._id);

    const prices = ppsqmLayers.reduce((layerPrices, layer) => {
      const area = partAreas.byLayers[layer.layer] || null;

      if (area) {
        const layerPriceCode = priceCodes[layer.priceCode_psq];
        const pricePerArea = layerPriceCode && layerPriceCode.price !== undefined ? layerPriceCode.price : layer.ppsqm;

        // take units into account
        const convertedArea = convertArea({ area, modelUnits });

        // then we multiply it with price

        return pricePerArea !== null ? layerPrices + convertedArea.area * pricePerArea : layerPrices;
      }

      return layerPrices;
    }, null);

    if (prices) {
      return result + prices;
    }

    return result;
  }, null);

const getOptionMultiplierByKey = (multipliers, parameter, key) => {
  // count all parts that follow current control
  if (!multipliers[parameter]) return 1;

  let counter = 0;

  Object.keys(multipliers[parameter]).forEach(itemKey => {
    // if is same as key or starts with key, add to counter;
    if (itemKey === key || itemKey.indexOf(key) === 0) {
      counter += 1;
    }
  });

  return counter;
};

const getOptionPartPrice = (option, control, priceCodes, partsMap, ppsqmLayers, modelUnits, optionMultipliers) => {
  // propagates down to all static children
  // price per unit + price per sqm

  const optionPart = getOptionPart(partsMap, control, option);

  if (!optionPart) {
    return null;
  }

  // will not loop through, just multiply later

  const nodes = getNodeStaticTree(optionPart, partsMap);

  const ppu = calculatePricePerUnit(nodes, priceCodes);

  const ppsqm = calculatePricePerSquareUnit(nodes, ppsqmLayers, priceCodes, modelUnits);

  const multiplier = getOptionMultiplierByKey(optionMultipliers, control.parameter || control.name, control.key);

  // avoid null + null = 0;
  if (ppsqm !== null || ppu !== null) {
    return ppsqm !== null ? (ppu + ppsqm) * multiplier : ppu * multiplier;
  }

  // if all else fails, return null
  return null;
};

/** Used in getControls in order to calculate option prices depending on price, pricecode, materials etc. */

const getOptionData = (
  control,
  option,
  ppsqmLayers,
  priceCodes,
  partsToRender,
  partsMap,
  modelUnits,
  priceRounding,
  optionMultipliers
) => {
  /*
    Algorithm is as following:
    1. Get either:
      1) options defined price
      2) part's price
        * which consists of part's per unit price + part's area price
        * for all static children that are added.
    2. Add material's price, for ALL parts rendered in scene
  */

  let basePrice = getOptionPrice(option, priceCodes); // returns null or float

  if (basePrice === null || basePrice === undefined) {
    // many parts with same treeId might be loaded with one option
    basePrice = getOptionPartPrice(option, control, priceCodes, partsMap, ppsqmLayers, modelUnits, optionMultipliers); // returns null or float
  }

  // this is only for materialOptions
  const { materialPrice, materialTotalArea: totalArea } = getOptionMaterialData(
    partsToRender,
    option,
    priceCodes,
    modelUnits
  ); // returns null or float

  // avoid situation of null + null = 0, also round
  const result = basePrice !== null ? basePrice + materialPrice : materialPrice;
  const totalPrice = result !== null ? Math.round(result / priceRounding) * priceRounding : null;

  return {
    totalArea,
    totalPrice
  };
};

const getChosenOptionByKey = (option, currentKey) => {
  // try to find first entry which fits the currentKey

  if (!option) return '';

  if (option[currentKey]) return option[currentKey];

  // reset array
  for (let i = currentKey.length - 1; i >= 0; i -= 1) {
    // push each chunk to keys array to be checked later.
    if (currentKey[i] === ',') {
      // currentKey is 0,1,2,3,
      // each segment is smaller, 0,1,2 0,1 to be checked
      const tempKey = currentKey.substring(0, i);

      if (option[tempKey]) {
        return option[tempKey];
      }
    }
  }

  return '';
};

const traversePartTree = (node, counter, selectedOptions, partsMap, parentKey) => {
  const { Position = [] } = node;

  Position.forEach(({ Reference }, index) => {
    const currentKey = `${parentKey + index},`;

    // if selectedOptions has a parameter with this name, lets memorize it.
    if (selectedOptions[Reference] !== undefined) {
      // eslint-disable-next-line no-param-reassign
      counter[Reference] = counter[Reference] || {};

      // eslint-disable-next-line no-param-reassign
      counter[Reference][currentKey] = 1;
    }

    if (partsMap[Reference]) {
      const option = selectedOptions[Reference];
      // get parent or same parameter.

      const chosenOption = getChosenOptionByKey(option, currentKey);

      const child =
        partsMap[Reference].count === 1 ? partsMap[Reference].default : partsMap[Reference].list[chosenOption];

      if (child) {
        traversePartTree(child, counter, selectedOptions, partsMap, currentKey);
      }
    }
  });
};

/** Returns how many parts will this controls' options turn on {parameter: multiplier}
 *
 * Each control could know individually how many parts with its parameter it switches on.
 * Each control should have it's own selectedOptions to base it on.
 *
 */

const selectOptionMultipliers = createSelector(
  [getControls, partsSelectors.selectPartsReferenceMap, partsSelectors.selectRootPart],
  (controls, partsMap, rootPart) => {
    const selectedOptions = controls.reduce((result, control) => {
      const { key } = control;
      const parameter = control.parameter || control.name;

      // eslint-disable-next-line no-param-reassign
      result[parameter] = result[parameter] || {};

      // eslint-disable-next-line no-param-reassign
      result[parameter][key] = control.value;

      return result;
    }, {});

    const rootNode = partsMap[rootPart.ReferenceName]?.default;
    const counter = {};

    if (rootNode) {
      traversePartTree(rootNode, counter, selectedOptions, partsMap, '0,');
    }

    return counter;
  }
);

function getControlType(type, options) {
  if (type === CONTROL_TYPES.EMPTY || type === CONTROL_TYPES.TOGGLE || type === CONTROL_TYPES.SLIDER) return type;

  if (type === CONTROL_TYPES.RADIO && options.length <= 2) return type;

  if (type === CONTROL_TYPES.COLUMN && options.length <= 1) return type;

  if (type === CONTROL_TYPES.COLUMN_TWO && options.length <= 2) return type;

  if (type === CONTROL_TYPES.COLUMN_THREE && options.length <= 3) return type;

  return CONTROL_TYPES.DROPDOWN;
}

const getPricedControls = createSelector(
  [
    getControls, // source controls to map prices onto
    getPartNodesToRender, // for material change prices takes into account all geometry in model
    partsSelectors.selectPartsReferenceMap, // for precalculating options' area-based prices,
    uiSelectors.selectPriceSettingsByPriceScheme,
    uiSelectors.selectIsMobileNavigation,
    modelSettingsSelectors.selectModelSettings,
    getPriceVariables,
    selectOptionMultipliers,
    geometriesSelectors.selectGeometriesStatus // to update on geometries loaded,
  ],
  (
    controls = [],
    partsToRender = [],
    partsMap = {},
    { priceRounding },
    isMobileNavigation,
    { ppsqmLayers, modelUnits },
    priceCodes = {},
    optionMultipliers
  ) => {
    // we need both all parts and parts to render - all parts (and partsmap) are for possible option prices and areas, parts to render are for selecting controls
    const newControls = [];

    // here we go inside each control option and calculate the price
    if (controls) {
      controls.forEach(control => {
        // to avoid mutating state
        // list is for regular controls, option for material controls
        const { list = [] } = control;

        const newList = list.map(option => {
          const { totalArea, totalPrice } = getOptionData(
            control,
            option,
            ppsqmLayers,
            priceCodes,
            partsToRender,
            partsMap,
            modelUnits,
            priceRounding,
            optionMultipliers
          );

          const { tags = [] } = option;

          if (tags.length === 0) {
            tags.push(CONTROL_TAGS.OTHER);
          }

          return { ...option, totalArea, totalPrice, tags };
        });

        const newControl = { ...control, list: newList };

        if (isMobileNavigation) {
          newControl.initialType = newControl.type;
          newControl.type = getControlType(newControl.type, newList);
        }

        newControls.push(newControl);
      });
    }

    return newControls;
  }
);

// used by Summary
const getSelectedOptionsFull = createSelector(
  [getPricedControls, selectedOptionsSelectors.selectSelectedOptions],
  (controls, selectedOptions) => {
    if (!Array.isArray(controls)) return [];

    if (typeof selectedOptions !== 'object') return [];

    const allSelectedOptions = [];

    controls.forEach(control => {
      const { list = [], value } = control;

      const selectedOption = selectedOptions[control.treeName] || value;
      const selectedOptionFull = list.find(option => option.name === selectedOption);

      if (selectedOptionFull) {
        allSelectedOptions.push({ control, ...selectedOptionFull });
      }
    });

    return allSelectedOptions;
  }
);

const selectRemovedOptionSections = createSelector(
  [getSelectedOptionsFull, uiSelectors.selectVisibleTabs],
  (selectedOptions, tabs) => {
    const controls = [];

    const invalidOptions = selectedOptions
      .filter(option => option.forceReselect && (option.disabled || option.locked || option.inactive))
      .map(option => ({ option: { ...option, control: undefined }, control: option.control }));

    invalidOptions.forEach(optionRef => {
      const { control } = optionRef;
      const controlTab = tabs.find(
        tab => Array.isArray(tab.controls) && tab.controls.findIndex(c => c.name === control.name) !== -1
      );

      if (controlTab) {
        controls.push({ ...optionRef, tabName: controlTab.name });
      }
    });

    return controls;
  }
);

const selectPricedControlsMap = createSelector([getPricedControls], controls => {
  const controlsMap = {};

  controls.forEach(control => {
    controlsMap[control.name] = control;
  });

  return controlsMap;
});

const selectPricedControlsTreeMap = createSelector([getPricedControls], controls => {
  const controlsMap = {};

  controls.forEach(control => {
    controlsMap[control.treeName] = control;
  });

  return controlsMap;
});

export const selectPricedControlsKeyMap = createSelector([getPricedControls], controls => {
  const map = {};

  controls.forEach(control => {
    const { key, name, treeName } = control;

    map[key] = map[key] || {};
    map[key][name] = treeName;
  });

  return Object.entries(map);
});

function getVisibleControls(controlsMap, controlsTreeMap, list = [], key, keyMap) {
  const result = [];

  const keys = flattenControlKeys(keyMap, key);

  list.forEach(({ name, local }) => {
    if (local) {
      const treeId = keys[name];

      if (treeId && controlsTreeMap[treeId] && !controlsMap[name].disabled) {
        result.push(controlsTreeMap[treeId]);
      }
    } else if (controlsMap[name] && !controlsMap[name].disabled) {
      result.push(controlsMap[name]);
    }
  });

  return result;
}

// if control is local, find correct control treeId from parameters and control is from treeMap
// else find by name.

const selectVisibleControls = createSelector(
  [selectPricedControlsMap, selectPricedControlsTreeMap, uiSelectors.selectCurrentTab, selectPricedControlsKeyMap],
  (pricedControlsMap, pricedControlsTreeMap, currentTab, keyMap) =>
    getVisibleControls(pricedControlsMap, pricedControlsTreeMap, currentTab.controls, currentTab.key, keyMap)
);

const selectChildTabContent = createSelector(
  [
    selectPricedControlsMap,
    selectPricedControlsTreeMap,
    uiSelectors.selectSelectedParentControls,
    selectPricedControlsKeyMap
  ],
  (pricedControlsMap, pricedControlsTreeMap, controls = [], keyMap) => {
    if (controls.length === 0) {
      return undefined;
    }

    const currentControl = pricedControlsTreeMap[controls[controls.length - 1]];

    if (!currentControl || !currentControl.childControls) {
      return undefined;
    }

    return {
      parentControlName: currentControl.name,
      parentControlTreeName: currentControl.treeName,
      childTabName: currentControl.childTabName,
      controls: getVisibleControls(
        pricedControlsMap,
        pricedControlsTreeMap,
        currentControl.childControls,
        currentControl.key,
        keyMap
      ),
      rootParentControlName: pricedControlsTreeMap[controls[0]]?.name || ''
    };
  }
);

export {
  getPricedControls,
  getPriceVariables,
  getSelectedOptionsFull,
  selectPricedControlsMap,
  selectPricedControlsTreeMap,
  selectVisibleControls,
  selectRemovedOptionSections,
  selectChildTabContent
};
