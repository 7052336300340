import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslate, useFormState } from '../../../utility/hooks';
import { authActions } from '../../../modules/auth';
import { formStyle } from '../../Menu';
import { Field, Button } from '../../Atoms';
import { validatePassword2, validatePassword } from '../../../utility/formValidators';

const PasswordForm = ({ token }) => {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { formState, handleFormFieldChange } = useFormState();

  const handleResetPassword = useCallback(
    e => {
      e.preventDefault();
      dispatch(authActions.resetPassword(formState.password, token));
    },
    [dispatch, formState.password, token]
  );

  const passwordFeedback = validatePassword(formState.password);
  const password2Feedback = validatePassword2(formState.password2, formState);

  const submitDisabled = Boolean(passwordFeedback || password2Feedback);

  return (
    <form className={formStyle({ type: 'reset-password' })} onSubmit={handleResetPassword}>
      <Field
        name="password"
        onChange={handleFormFieldChange}
        label={translate('Enter new password')}
        type="password"
        id="reset-password"
        autoComplete="new-password"
        feedback={passwordFeedback}
        value={formState.password}
      />
      <Field
        name="password2"
        onChange={handleFormFieldChange}
        label={translate('Repeat new password')}
        type="password"
        id="reset-password2"
        autoComplete="new-password"
        feedback={password2Feedback}
        value={formState.password2}
      />
      <Button type="submit" block disabled={submitDisabled}>
        {translate('Submit')}
      </Button>
    </form>
  );
};

PasswordForm.propTypes = {
  token: PropTypes.string.isRequired
};

export default PasswordForm;
