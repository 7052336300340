import React, { useCallback, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useEscListener } from '../../utility/hooks';
import { DIALOGS } from '../../utility/dialogs';
import menuStyle from './style';
import useMenuClose from './useMenuClose';
import MenuHeader from './MenuHeader';
import MenuFooter from './MenuFooter';

import './Menu.scss';

const PERSISTENT_DIALOGS = [
  DIALOGS.AUTH,
  DIALOGS.SAVE,
  DIALOGS.CONTACT,
  DIALOGS.LOADING,
  DIALOGS.LOGIN,
  DIALOGS.REGISTER,
  DIALOGS.RESET_PASSWORD,
  DIALOGS.FORGOT_PASSWORD,
  DIALOGS.SEND_TO_EMAIL
];

const Menu = ({ children, header, type }) => {
  const bodyRef = useRef();

  const handleClose = useMenuClose();

  useEscListener(handleClose);

  useEffect(() => {
    if (bodyRef.current) {
      bodyRef.current.scrollTop = 0;
    }
  }, []);

  const handleClickOnMenu = useCallback(
    e => {
      if (bodyRef.current.contains(e.target)) {
        return;
      }

      if (PERSISTENT_DIALOGS.includes(type)) {
        return;
      }

      handleClose();
    },
    [handleClose, type]
  );

  return (
    /* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
    <div className={menuStyle()} onClick={handleClickOnMenu} data-heap={type}>
      <div className={menuStyle('body')} ref={bodyRef}>
        <MenuHeader>{header}</MenuHeader>
        {children}
        <MenuFooter />
      </div>
    </div>
  );
};

Menu.defaultProps = {
  type: '',
  header: null
};

Menu.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  header: PropTypes.node
};

export default Menu;
