import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import b from 'b_';
import { useFullScreenHandle } from 'react-full-screen';
import { uiActions, uiSelectors } from '../../modules/ui';
import ControlsPanelWrapper from '../ControlsPanelWrapper';
import Viewer from '../Viewer';
import OverviewPanel from '../OverviewPanel/OverviewPanel';
import ReviewButtons from '../ReviewButtons';
import InfoPage from '../InfoPage';
import { loaderSelectors } from '../../modules/loader';
import MinimalUiButtons from '../MinimalUiButtons';
import ExitFullscreenButton from '../ExitFullscreenButton';

import './Content.scss';

const contentStyle = b.with('content');

const useSummaryGalleryRender = () => {
  const dispatch = useDispatch();
  const isSceneLoading = useSelector(loaderSelectors.getProgressActiveStatus);
  const isSummaryTab = useSelector(uiSelectors.getIsSummaryTab);

  useEffect(() => {
    if (!isSceneLoading) {
      dispatch(uiActions.renderSummaryGallery(isSummaryTab));
    }
  }, [dispatch, isSceneLoading, isSummaryTab]);
};

const useFullscreen = () => {
  const dispatch = useDispatch();
  const handle = useFullScreenHandle();

  useEffect(() => {
    handle.node.current = document.getElementById('root');
  }, [handle]);

  useEffect(() => {
    dispatch(uiActions.setFullscreen(handle.active));
  }, [dispatch, handle.active]);

  return handle;
};

const Content = ({ isMinimalUi }) => {
  const isIndexTab = useSelector(uiSelectors.getIsIndexTab);
  const isReviewTab = useSelector(uiSelectors.getIsReviewTab);
  const isSummaryTab = useSelector(uiSelectors.getIsSummaryTab);
  const isLoading = useSelector(loaderSelectors.getIsSplashScreenActive);
  const type = isIndexTab || isSummaryTab ? 'scrollable' : 'configurator';
  const handle = useFullscreen();

  const canvasContainerRef = useRef();

  useSummaryGalleryRender();

  if (isMinimalUi) {
    return (
      <div className={contentStyle({ type })}>
        <Viewer ref={canvasContainerRef} />
        <MinimalUiButtons onSetFullscreen={handle.enter} />
      </div>
    );
  }

  return (
    <div className={contentStyle({ type })}>
      {isIndexTab || isSummaryTab ? (
        <>
          <InfoPage />
          <ExitFullscreenButton onClick={handle.exit} type="index" />
        </>
      ) : (
        <>
          {isReviewTab ? (
            <ReviewButtons onExitFullscreen={handle.exit} />
          ) : (
            <ControlsPanelWrapper canvasContainerRef={canvasContainerRef} />
          )}
          <Viewer ref={canvasContainerRef} onExitFullscreen={handle.exit} />
          {isLoading ? null : <OverviewPanel viewerRef={canvasContainerRef} />}
        </>
      )}
    </div>
  );
};

Content.propTypes = {
  isMinimalUi: PropTypes.bool.isRequired
};

export default Content;
