import React from 'react';
import b from 'b_';
import { useSelector } from 'react-redux';
import { Button } from '../../../Atoms';
import Icon from '../../../Icon';
import { useTabLink } from '../../../TabLink';
import { uiSelectors } from '../../../../modules/ui';
import { useActions, useLinkGenerators, useTranslate } from '../../../../utility/hooks';
import { commonSelectors } from '../../../../modules/model';

import './HomeButton.scss';

const homeButtonStyle = b.with('home-button');

const HomeButton = () => {
  const translate = useTranslate();
  const currentTabName = useSelector(uiSelectors.selectSelectedTabName);
  const isChildModeEnabled = useSelector(commonSelectors.getIsChildModeEnabled);
  const makeTabLink = useTabLink();
  const { getCustomLink } = useLinkGenerators();
  const {
    actions: [action],
    handleClickOnAction
  } = useActions('homeButton');

  if (!action) return null;

  return (
    <Button
      mix={homeButtonStyle({ disabled: isChildModeEnabled })}
      type={action.buttonType}
      onClick={handleClickOnAction}
      to={makeTabLink(action.tab)}
      href={getCustomLink(action.link)}
      data-action={action.name}
      aria-label={translate(action.displayName)}
      data-balloon-pos="down"
      disabled={currentTabName === action.tab}
      color="text"
      size="md"
    >
      <Icon type="home-outline" />
      <div className={homeButtonStyle('divider')} />
    </Button>
  );
};

export default HomeButton;
