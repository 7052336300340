import React from 'react';
import ServicePage from './components/ServicePage/ServicePage';
import { Button } from './components/Atoms';

class ErrorBoundary extends React.Component {
  state = {
    error: null,
    errorInfo: null
  };

  static getDerivedStateFromError = error => {
    console.error(error); // eslint-disable-line no-console

    return { hasError: true };
  };

  button = (<Button rounded bold color="main-outline" label="Reload page" onClick={() => window.location.reload()} />);

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { error, errorInfo } = this.state;

    if (error && errorInfo) {
      return (
        <ServicePage
          title="Configurator is broken"
          subtitle="Something went wrong"
          message="Try to reload page or contact the owner of this configurator"
          button={this.button}
        />
      );
    }

    return this.props.children; // eslint-disable-line react/prop-types
  }
}

export default ErrorBoundary;
