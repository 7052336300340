/* eslint-disable max-classes-per-file */
import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { Matrix4 } from 'three';
import { createPortal } from 'react-dom';
import b from 'b_';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { Button } from '../../../Atoms';
import getThreeSetup from '../../getThreeSetup';
import './SceneButton.scss';

const sceneButton = b.with('scene-button');

/* Use React portal to put the DOM nodes to correct place
and use React's mounting-unmounting mechanism.
 */
class DumbCSS2DObject extends CSS2DObject {
  constructor(...args) {
    super(...args);

    // eslint-disable-next-line no-underscore-dangle
    const callback = this._listeners?.removed[0];

    if (callback) this.removeEventListener('removed', callback);
  }
}

const { cssScene, cssRenderer } = getThreeSetup();

class SceneButton extends Component {
  constructor(props) {
    super(props);

    this.button = createRef();
  }

  componentDidMount() {
    this.object2D = new DumbCSS2DObject(this.button.current);

    this.object2D.matrix.copy(this.props.matrix);
    this.object2D.matrixAutoUpdate = false;

    cssScene.add(this.object2D);
  }

  componentDidUpdate() {
    this.object2D.matrix.copy(this.props.matrix);
  }

  componentWillUnmount() {
    cssScene.remove(this.object2D);
  }

  render() {
    const { circular, color, type, ...buttonProps } = this.props;

    return createPortal(
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Button ref={this.button} circular={circular} color={color} {...buttonProps} mix={sceneButton({ type })} />,
      cssRenderer.domElement
    );
  }
}
SceneButton.propTypes = {
  matrix: PropTypes.instanceOf(Matrix4).isRequired,
  type: PropTypes.string,
  circular: PropTypes.bool,
  color: PropTypes.string
};

SceneButton.defaultProps = {
  circular: true,
  color: 'main-outline',
  type: ''
};

export default SceneButton;
